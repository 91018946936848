import { useQuery } from '@tanstack/react-query';

import { TAssetVehicle } from '@/hooks/types';
import { TAssetVehicleResponse } from '@/services/types';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchAssetVehicles } from '@/services/rio';

const mapResponse = (assetVehiclesResponse: TAssetVehicleResponse): TAssetVehicle[] => {
  const { items } = assetVehiclesResponse;
  const transformedItems: TAssetVehicle[] = [];

  items.forEach((item) => {
    const {
      account_id: accountId,
      identification_type: identificationType,
      _embedded: {
        tags: { items: tags },
      },
      ...rest
    } = item;

    const transformedItem = {
      ...rest,
      accountId,
      identificationType,
      tags: tags.map((tag) => ({ id: tag.id })),
      type: rest.type as TVehicleTypeEnum,
    };

    transformedItems.push(transformedItem);
  });

  return transformedItems;
};

/** This hook will return all vehicles that belongs to the fleet */
const useAssetVehiclesQuery = () =>
  useQuery({
    queryKey: [QueryIdentifierEnum.ASSET_VEHICLES],
    queryFn: async () => {
      const assetVehiclesResponse = await fetchAssetVehicles();

      return mapResponse(assetVehiclesResponse);
    },
    gcTime: Infinity,
  });

export default useAssetVehiclesQuery;
