import { TMapUpdateRegion, TRegionBundles, TRegionSelect } from './types';

import { CountryEnum } from '@/enums/CountryEnum';
import { RegionEnum } from '@/enums/RegionEnum';

const mapUpdateRegions: TMapUpdateRegion[] = [
  {
    phraseKey: 'mannow:common.mmu.single.e1',
    codes: [CountryEnum.SPAIN],
    region: RegionEnum.E1,
  },
  {
    phraseKey: 'mannow:common.mmu.single.e2',
    codes: [CountryEnum.FRANCE],
    region: RegionEnum.E2,
  },
  {
    phraseKey: 'mannow:common.mmu.single.e3',
    codes: [CountryEnum.GREAT_BRITAIN, CountryEnum.IRELAND],
    region: RegionEnum.E3,
  },
  {
    phraseKey: 'mannow:common.mmu.single.e4',
    codes: [CountryEnum.GERMANY],
    region: RegionEnum.E4,
  },
  {
    phraseKey: 'mannow:common.mmu.single.e5',
    codes: [CountryEnum.NORWAY, CountryEnum.SWEDEN, CountryEnum.FINLAND, CountryEnum.DENMARK, CountryEnum.ICELAND],
    region: RegionEnum.E5,
  },
  {
    phraseKey: 'mannow:common.mmu.single.e6',
    codes: [
      CountryEnum.AUSTRIA,
      CountryEnum.SWITZERLAND,
      CountryEnum.SLOVENIA,
      CountryEnum.HUNGARY,
      CountryEnum.CROATIA,
    ],
    region: RegionEnum.E6,
  },
  {
    phraseKey: 'mannow:common.mmu.single.e7',
    codes: [CountryEnum.ESTONIA, CountryEnum.LATVIA, CountryEnum.LITHUANIA, CountryEnum.RUSSIA],
    region: RegionEnum.E7,
  },
  {
    phraseKey: 'mannow:common.mmu.single.e8',
    codes: [CountryEnum.POLAND, CountryEnum.CZECH_REPUBLIC, CountryEnum.SLOVAKIA],
    region: RegionEnum.E8,
  },
  {
    phraseKey: 'mannow:common.mmu.single.e9',
    codes: [CountryEnum.CYPRUS],
    region: RegionEnum.E9,
  },
  {
    phraseKey: 'mannow:common.mmu.single.ea',
    codes: [
      CountryEnum.BOSNIA_HERZEGOVINA,
      CountryEnum.SERBIA,
      CountryEnum.MONTENEGRO,
      CountryEnum.ALBANIA,
      CountryEnum.MACEDONIA,
      CountryEnum.BULGARIA,
      CountryEnum.GREECE,
    ],
    region: RegionEnum.EA,
  },
  {
    phraseKey: 'mannow:common.mmu.single.eb',
    codes: [CountryEnum.ITALY],
    region: RegionEnum.EB,
  },
  {
    phraseKey: 'mannow:common.mmu.single.ec',
    codes: [CountryEnum.PORTUGAL],
    region: RegionEnum.EC,
  },
  {
    phraseKey: 'mannow:common.mmu.single.ed',
    codes: [CountryEnum.BELGIUM, CountryEnum.NETHERLANDS, CountryEnum.LUXEMBOURG],
    region: RegionEnum.ED,
  },
  {
    phraseKey: 'mannow:common.mmu.single.ee',
    codes: [CountryEnum.UKRAINE, CountryEnum.BELARUS, CountryEnum.MOLDOVA, CountryEnum.ROMANIA],
    region: RegionEnum.EE,
  },
  {
    phraseKey: 'mannow:common.mmu.single.ef',
    codes: [CountryEnum.GEORGIA, CountryEnum.ARMENIA, CountryEnum.AZERBAIJAN],
    region: RegionEnum.EF,
  },
];

const codesPerRegionBundle: TRegionBundles = {
  NORTHERN_EUROPE: {
    phraseKey: 'mannow:common.mmu.bundle.northerneurope',
    codes: [RegionEnum.E5, RegionEnum.E7],
  },
  CENTRAL_EUROPE: {
    phraseKey: 'mannow:common.mmu.bundle.centraleurope',
    codes: [RegionEnum.E4, RegionEnum.E6, RegionEnum.EB, RegionEnum.ED],
  },
  WESTERN_EUROPE: {
    phraseKey: 'mannow:common.mmu.bundle.westerneurope',
    codes: [RegionEnum.E1, RegionEnum.E2, RegionEnum.E3, RegionEnum.EC],
  },
  EASTERN_EUROPE: {
    phraseKey: 'mannow:common.mmu.bundle.easterneurope',
    codes: [RegionEnum.E8, RegionEnum.E9, RegionEnum.EA, RegionEnum.EE, RegionEnum.EF],
  },
};

const regionBundleSelectFilter: TRegionSelect = {
  FULL_PACKAGE: {
    phraseKey: 'mannow.myCurrentMaps.fullEurope',
    codes: [] as string[],
  },
  DIVIDER: {
    phraseKey: 'mannow.booking.bundles.title',
    codes: [] as string[],
    header: true,
  },
  ...codesPerRegionBundle,
};

export { mapUpdateRegions, codesPerRegionBundle, regionBundleSelectFilter };
