import { TRelease } from '@/features/types';

import { dateToString } from '@/helpers/date';

import { type ReleaseNotesProps } from '@rio-cloud/rio-uikit/ReleaseNotes';

import BaseNews from './BaseNews';

export const getReleaseNotes = (releaseList: TRelease[]) => {
  const list: ReleaseNotesProps = { releaseNotes: {} };

  for (const { release, date, content } of releaseList) {
    list.releaseNotes[release] = {
      date: dateToString(date),
      content: [<BaseNews key={release} release={release} content={content} />],
    };
  }

  return list.releaseNotes;
};
