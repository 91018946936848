export const enum QueryIdentifierEnum {
  ASSET_ASSOCIATION = 'assetAssociation',
  ASSET_VEHICLES = 'assetVehicles',
  BILLING_DATA = 'billingData',
  ELIGIBLE_VEHICLES_PLANS = 'eligibleVehiclesPlans',
  FEATURES_DETAILS = 'featuresDetails',
  FEATURES_STATUS = 'featuresStatus',
  FEATURE_NOTIFICATION_STATUS = 'featureNotificationStatus',
  FEATURE_PLANS = 'featurePlans',
  FEATURE_VEHICLES = 'featureVehicles',
  INSTALLABLE_FEATURES = 'installableFeatures',
  MARKETPLACE_FEATURE_PRICES = 'marketplaceFeaturePrices',
  MARKETPLACE_FEATURE_PRICES_BY_FEATURE_CODE = 'marketplaceFeaturePricesByFeatureCode',
  ORDER = 'order',
  ORDERS = 'orders',
  PAYMENT_METHODS = 'paymentMethods',
  RECOMMENDED_FEATURE = 'recommendedFeature',
  REGION_LIST = 'regionList',
  REGION_LIST_BY_VEHICLE = 'regionListByVehicle',
  SERVICE_OUTAGE = 'serviceOutage',
  SHOPPING_CART = 'shoppingCart',
  SHOPPING_CART_COUNT = 'shoppingCartCount',
  SUBSCRIPTIONS = 'subscriptions',
  SUBSCRIPTION_PLANS_BY_FEATURE = 'subscriptionPlansByFeature',
  TAGS = 'tags',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  TERMS_AND_CONDITIONS_STATUS = 'termsAndConditionsStatus',
  VEHICLE = 'vehicle',
  VEHICLE_FEATURES = 'vehicleFeatures',
  VEHICLE_FEATURE_STATUS_COUNT = 'vehicleFeatureStatusCount',
  VEHICLE_LIST = 'vehicleList',
  VEHICLE_PLANS = 'vehiclePlans',
  VEHICLE_TAGS = 'vehicleTags',
}
