import { FormattedMessage } from 'react-intl';

import ForbiddenState from '@rio-cloud/rio-uikit/ForbiddenState';

const ForbiddenError = () => (
  <ForbiddenState
    headline={<FormattedMessage id="mannow.forbiddenError.headline" />}
    buttons={[
      {
        text: <FormattedMessage id="mannow.forbiddenError.buttonText" />,
        onClick: () => {
          window.open('https://www.digital.man/de/en/man-now-387072.html', '_blank');
        },
      },
    ]}
  />
);

export default ForbiddenError;
