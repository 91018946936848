import { useState } from 'react';

import useWindowResize from '@rio-cloud/rio-uikit/useWindowResize';

import theme from '@/theme';

const isSmallDevice = () => {
  if (typeof window !== 'undefined') {
    return window?.innerWidth < parseFloat(theme.breakpoints.small);
  }
};

const useIsSmallDevice = () => {
  const [isMobile, setIsMobile] = useState(isSmallDevice());

  useWindowResize(() => setIsMobile(isSmallDevice()));

  return isMobile;
};

export default useIsSmallDevice;
