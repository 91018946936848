import { FormattedMessage } from 'react-intl';

import ErrorState from '@rio-cloud/rio-uikit/ErrorState';

const GenericError = ({ message }: { message?: string }) => (
  <ErrorState
    headline={<FormattedMessage id="mannow.genericError.headline" />}
    message={<FormattedMessage id={message || 'mannow.genericError.message'} />}
    buttons={[
      {
        text: (
          <FormattedMessage
            id="mannow.phrase.goToPage"
            values={{ page: <FormattedMessage id="mannow.general.homepage" /> }}
          />
        ),
        onClick: () => {
          window.location.href = '/';
        },
      },
    ]}
  />
);

export default GenericError;
