import { useEffect, useState } from 'react';

import { TTranslationList, TTranslationMessages } from '@/hooks/types';

import { config } from '@/config';
import { DEFAULT_LOCALE } from '@/configuration/lang/lang';

import { useUserDataContext } from '@/providers/hooks';

import { sendNotification } from '@/helpers/notification';

const getTranslations = async (newLocale: string): Promise<TTranslationList> => {
  const response: TTranslationList = {
    messages: {},
    isError: false,
  };

  if (!newLocale) {
    return { ...response, isError: true };
  }

  try {
    const mannowTranslations = await import(`../../../configuration/lang/translations/${newLocale}.json`);
    const commonTranslations = await import(`../../../configuration/lang/translations/common/${newLocale}.json`);

    response.messages = {
      ...mannowTranslations.default,
      ...commonTranslations.default,
    };
  } catch (error) {
    response.isError = true;
    console.error(error);
  }

  return response;
};

/** This hook will return the current locale and translations */
const useTranslation = (onlyLocale = false) => {
  const [messages, setMessages] = useState<TTranslationMessages>({});

  const { locale: userLocale } = useUserDataContext();

  const locale = userLocale || DEFAULT_LOCALE;
  useEffect(() => {
    if (!onlyLocale) {
      const fetchTranslations = async () => {
        let data = await getTranslations(locale);

        if (data.isError) {
          data = await getTranslations(DEFAULT_LOCALE);

          if (config.serviceEnvironment === 'production') {
            sendNotification('mannow.defaultLanguageFallback.info', 'mannow.defaultLanguageFallback.title');
          }
        }

        setMessages(data.messages);
      };

      fetchTranslations();
    }
  }, [onlyLocale, locale]);

  return {
    locale,
    messages,
  };
};

export default useTranslation;
