import { TAsyncContentWrapperProps } from '@/components/types';

import GenericError from '@/components/errors/GenericError';

import Spinner from '@rio-cloud/rio-uikit/Spinner';

const AsyncContentWrapper = ({
  showNotFound = false,
  isLoading = false,
  children,
  loadingComponent = <Spinner show isFullSized />,
  notFoundComponent = <GenericError message="mannow.missingContent.message" />,
}: TAsyncContentWrapperProps) => {
  if (isLoading) {
    return loadingComponent;
  }

  if (showNotFound) {
    return notFoundComponent;
  }

  return <>{children}</>;
};

export default AsyncContentWrapper;
