import styled, { css } from 'styled-components';

export const Container = styled.div.attrs({
  className: 'panel container-fluid fluid-large',
})`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0;
  padding: 0;
`;

export const Heading = styled.h2.attrs({
  className: 'panel-heading',
})`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      && {
        margin-bottom: 0;
        margin-top: 0;
        padding: ${spaces.small} 0;
        text-align: center;
      }
    `;
  }}
`;

export const PdfContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  width: 100%;

  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
`;

export const Footer = styled.div.attrs({
  className: 'panel-footer',
})`
  bottom: 0;
  position: sticky;
`;

export const FooterInner = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: ${spaces.small} !important;
    `;
  }}
`;

export const ButtonLink = styled.a.attrs({
  className: 'btn btn-primary btn-outline',
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      margin-right: ${spaces.mediumSmall};
    `;
  }}
`;

export const ButtonGroup = styled.div`
  display: flex;
`;
