import { useQuery } from '@tanstack/react-query';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchShoppingCartCount } from '@/services/featureManager';

const useShoppingCartCountQuery = () =>
  useQuery({
    queryKey: [QueryIdentifierEnum.SHOPPING_CART_COUNT],
    queryFn: async () => {
      const { total_elements: totalElements } = await fetchShoppingCartCount();
      return totalElements;
    },
  });

export default useShoppingCartCountQuery;
