import styled, { css } from 'styled-components';

import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';

export const Wrapper = styled(ApplicationLayoutBodyBanner)`
  ${({ theme }) => {
    const { colors, fonts, spaces } = theme;

    return css`
      background: ${colors.brandInfo} !important;

      div {
        font-size: ${fonts.size.medium};
        padding: ${spaces.tiny};
      }
    `;
  }};
`;
