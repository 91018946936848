import styled, { css } from 'styled-components';

export const AccentText = styled.span`
  ${({ theme }) => {
    const { colors } = theme;

    return css`
      color: ${colors.brandPrimary};
    `;
  }}
`;
