import { PropsWithChildren, useState } from 'react';

import { parseJwt } from '@/configuration/tokenHandling/accessToken';

import { StorageEnum } from '@/enums/StorageEnum';

import { UserDataContext } from './contexts';

const UserDataProvider = ({ children }: PropsWithChildren) => {
  const currentToken = sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_TOKEN) || '';
  const currentLocale = sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_LOCALE) || '';
  const isSessionExpired = JSON.parse(sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_SESSION_EXPIRED) || 'false');
  const userHasAccess = JSON.parse(sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_HAS_ACCESS) || 'false');

  const decodedToken = parseJwt(currentToken);
  const userDetails = {
    id: decodedToken?.sub,
    name: decodedToken?.name,
    email: decodedToken?.email,
    username: decodedToken?.username,
  };

  const [token, setToken] = useState<string>(currentToken);
  const [locale, setLocale] = useState<string>(currentLocale);
  const [isSessionExpiredAcknowledged, setSessionExpiredAcknowledged] = useState<boolean>(false);
  const [hasAccess, setHasAccess] = useState<boolean>(userHasAccess);
  const [fleetId, setFleetId] = useState<string>(decodedToken?.account);

  return (
    <UserDataContext.Provider
      value={{
        token,
        setToken,
        locale,
        setLocale,
        isSessionExpired,
        isSessionExpiredAcknowledged,
        setSessionExpiredAcknowledged,
        hasAccess,
        setHasAccess,
        fleetId,
        setFleetId,
        userDetails,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataProvider;
