import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { updateTermsAndConditionStatus } from '@/services/userManager';

const useTermsAndConditionsStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateTermsAndConditionStatus,
    onSuccess: (data) => queryClient.setQueryData([QueryIdentifierEnum.TERMS_AND_CONDITIONS_STATUS], data),
  });
};

export default useTermsAndConditionsStatusMutation;
