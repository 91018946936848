import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(duration);

const DATE_FORMAT = 'DD/MM/YYYY';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const NUM_WEEKS_IN_MONTH = 4;
export const NUM_MONTHS_IN_YEAR = 12;

export const dateDiffInDays = (startDate: Date, endDate: Date) => {
  const start = dayjs(dayjs(startDate).format(DEFAULT_DATE_FORMAT));
  const end = dayjs(dayjs(endDate).format(DEFAULT_DATE_FORMAT));

  return end.diff(start, 'days');
};

export const dateToString = (date: Date, dateFormat = DATE_FORMAT) => dayjs(date).format(dateFormat);

export const segmentDuration = (endDate: Date) => {
  const startDate = dayjs().hour(0).minute(0).second(0);
  const finalDate = dayjs(endDate).hour(23).minute(59).second(59);

  const difference = finalDate.diff(startDate);
  const totalDuration = dayjs.duration(difference);

  return {
    years: totalDuration.years(),
    months: totalDuration.months(),
    weeks: totalDuration.weeks(),
    days: totalDuration.days(),
    overdue: totalDuration.milliseconds() <= 0,
  };
};
