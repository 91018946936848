import { TAutoRenewalFeatures, TConflictingShoppingCartFeatures, TFeatureDefinition, TFeatureSkus } from './types';

import { FeatureCodeEnum, FeaturePlanEnum, VariantCodeEnum } from '@/enums/FeatureCodeEnum';
import { PowertrainEnum } from '@/enums/PowertrainEnum';

import { getFeatureTag } from '@/helpers/featureTag';

const featureDefinitions: TFeatureDefinition[] = [
  {
    code: FeatureCodeEnum.MAP_UPDATE,
    isSubscription: true,
    powertrains: [PowertrainEnum.DIESEL, PowertrainEnum.ELECTRIC],
    tag: getFeatureTag(new Date('2020-09-30')),
    hasOptions: true,
  },
  {
    code: FeatureCodeEnum.MAN_ONLINE_TRAFFIC,
    isSubscription: true,
    planCodes: {
      MOT_FREE: FeaturePlanEnum.MOT_FREE,
      MOT_PAID: FeaturePlanEnum.MOT_PAID,
    },
    freePlanCode: FeaturePlanEnum.MOT_FREE,
    powertrains: [PowertrainEnum.DIESEL, PowertrainEnum.ELECTRIC],
    tag: getFeatureTag(new Date('2020-01-10')),
    hasOptions: null,
  },
  {
    code: FeatureCodeEnum.LANGUAGE_PACKAGE,
    isSubscription: false,
    isLevel2C: true,
    conflictingShoppingCartFeature: FeatureCodeEnum.LANGUAGE_RECOGNITION,
    powertrains: [PowertrainEnum.DIESEL, PowertrainEnum.ELECTRIC],
    tag: getFeatureTag(new Date('2022-04-12')),
    hasOptions: true,
  },
  {
    code: FeatureCodeEnum.TIP_MATIC,
    isSubscription: false,
    isLevel2C: true,
    variants: {
      TM_EFFICIENCY: VariantCodeEnum.TM_EFFICIENCY,
      TM_EFFICIENCY_PLUS: VariantCodeEnum.TM_EFFICIENCY_PLUS,
      TM_MANOEUVRE: VariantCodeEnum.TM_MANOEUVRE,
      TM_OFFROAD: VariantCodeEnum.TM_OFFROAD,
      TM_TANKER: VariantCodeEnum.TM_TANKER,
      TM_PERFORMANCE: VariantCodeEnum.TM_PERFORMANCE,
    },
    powertrains: [PowertrainEnum.DIESEL, PowertrainEnum.ELECTRIC],
    tag: getFeatureTag(new Date('2022-04-12')),
    hasOptions: true,
  },
  {
    code: FeatureCodeEnum.EFFICIENT_ROUTE,
    isSubscription: true,
    powertrains: [PowertrainEnum.DIESEL],
    tag: getFeatureTag(new Date('2022-08-20')),
    hasOptions: null,
  },
  {
    code: FeatureCodeEnum.ONLINE_NEWS,
    isSubscription: true,
    planCodes: {
      ONLINE_NEWS_FREE: FeaturePlanEnum.ONLINE_NEWS_FREE,
      ONLINE_NEWS_PRESET_FREE: FeaturePlanEnum.ONLINE_NEWS_PRESET_FREE,
    },
    freePlanCode: FeaturePlanEnum.ONLINE_NEWS_FREE,
    freeTrialPlanCode: FeaturePlanEnum.ONLINE_NEWS_PRESET_FREE,
    autoRenewalPlanCodes: [],
    powertrains: [PowertrainEnum.DIESEL],
    tag: getFeatureTag(new Date('2022-10-31')),
    hasOptions: false,
  },
  {
    code: FeatureCodeEnum.LANGUAGE_RECOGNITION,
    isSubscription: false,
    isLevel2C: true,
    isPriceFixed: true,
    planCodes: {
      LANGUAGE_RECOGNITION_PRE_PAID: FeaturePlanEnum.LANGUAGE_RECOGNITION_PRE_PAID,
      LANGUAGE_RECOGNITION_PAID: FeaturePlanEnum.LANGUAGE_RECOGNITION_PAID,
    },
    conflictingShoppingCartFeature: FeatureCodeEnum.LANGUAGE_PACKAGE,
    powertrains: [PowertrainEnum.DIESEL, PowertrainEnum.ELECTRIC],
    tag: getFeatureTag(new Date('2022-12-14')),
    hasOptions: false,
  },
  {
    code: FeatureCodeEnum.TIME_INFO,
    isSubscription: false,
    isLevel2C: true,
    isPriceFixed: true,
    conflictingShoppingCartFeature: FeatureCodeEnum.TIME_CONTROL,
    powertrains: [PowertrainEnum.DIESEL, PowertrainEnum.ELECTRIC],
    tag: getFeatureTag(new Date('2023-02-24')),
    hasOptions: false,
  },
  {
    code: FeatureCodeEnum.TIME_CONTROL,
    isSubscription: false,
    isLevel2C: true,
    isPriceFixed: true,
    conflictingShoppingCartFeature: FeatureCodeEnum.TIME_INFO,
    powertrains: [PowertrainEnum.DIESEL, PowertrainEnum.ELECTRIC],
    tag: getFeatureTag(new Date('2023-02-24')),
    hasOptions: false,
  },
  {
    code: FeatureCodeEnum.IDLE_SHUTDOWN,
    isSubscription: false,
    isLevel2C: true,
    isPriceFixed: true,
    powertrains: [PowertrainEnum.DIESEL],
    tag: getFeatureTag(new Date('2023-03-08')),
    hasOptions: true,
  },
  {
    code: FeatureCodeEnum.EFFICIENT_ROLL,
    isSubscription: false,
    isLevel2C: true,
    isPriceFixed: true,
    conflictingShoppingCartFeature: FeatureCodeEnum.EFFICIENT_CRUISE,
    powertrains: [PowertrainEnum.DIESEL],
    tag: getFeatureTag(new Date('2023-02-28')),
    hasOptions: false,
  },
  {
    code: FeatureCodeEnum.EFFICIENT_CRUISE,
    isSubscription: false,
    isLevel2C: true,
    isPriceFixed: false,
    planCodes: {
      EFFICIENT_CRUISE_WITH_EFFICIENT_ROLL: FeaturePlanEnum.EFFICIENT_CRUISE_WITH_EFFICIENT_ROLL,
      EFFICIENT_CRUISE_ONLY: FeaturePlanEnum.EFFICIENT_CRUISE_ONLY,
    },
    conflictingShoppingCartFeature: FeatureCodeEnum.EFFICIENT_ROLL,
    powertrains: [PowertrainEnum.DIESEL],
    tag: getFeatureTag(new Date('2023-05-31')),
    hasOptions: true,
  },
  {
    code: FeatureCodeEnum.KICKDOWN_DEACTIVATION,
    isSubscription: false,
    isLevel2C: true,
    isPriceFixed: true,
    powertrains: [PowertrainEnum.DIESEL],
    tag: getFeatureTag(new Date('2023-08-07')),
    hasOptions: false,
  },
  {
    code: FeatureCodeEnum.SECOND_PHONE,
    isSubscription: false,
    isLevel2C: true,
    isPriceFixed: true,
    powertrains: [PowertrainEnum.DIESEL],
    tag: getFeatureTag(new Date('2023-09-22')),
    hasOptions: false,
  },
];

// List with all feature codes - including variants
export const allFeatureCodes = [...Object.values(FeatureCodeEnum), ...Object.values(VariantCodeEnum)];

// List with all feature codes for Level2C features
export const l2cFeatures = Object.freeze([
  ...featureDefinitions.filter(({ isLevel2C }) => isLevel2C).map(({ code }) => code),
]);

// List with all feature codes for Subscription based features
export const subscriptionFeatures = Object.freeze(
  featureDefinitions.filter(({ isSubscription }) => isSubscription).map(({ code }) => code)
);

// List with all feature codes for One Time Payment features
export const oneTimePaymentFeatures = Object.freeze(
  featureDefinitions
    .filter(({ isSubscription }) => !isSubscription)
    .flatMap(({ code, variants }) => [code, ...Object.values(variants || {})])
);

// List of feature that has only one plan, so the price is fixed
export const featuresWithFixedPrice = Object.freeze(
  featureDefinitions.filter(({ isPriceFixed }) => isPriceFixed).map(({ code }) => code)
);

// If the feature belongs to a group, add it here
// example: TM_PERFORMANCE: 'TM'
export const featureGroups = Object.freeze({
  ...Object.keys(VariantCodeEnum).reduce(
    (previous, current) => ({
      ...previous,
      [current]: FeatureCodeEnum.TIP_MATIC as TFeatureCodeEnum,
    }),
    {} as Record<VariantCodeEnum, TFeatureCodeEnum>
  ),
});

// List of variants of a feature
// example: (TM) => [TM_OFFROAD, TM_PERFORMANCE, ...]
export const featureVariants: Partial<Record<FeatureCodeEnum, VariantCodeEnum[]>> = Object.freeze({
  [FeatureCodeEnum.TIP_MATIC]: Object.values(VariantCodeEnum),
});

// List with all free trial plan codes
export const freeTrialPlanCodes = Object.freeze(
  featureDefinitions
    .filter(({ freePlanCode, freeTrialPlanCode }) => freePlanCode || freeTrialPlanCode)
    .map(({ freePlanCode, freeTrialPlanCode }) => freeTrialPlanCode || freePlanCode)
);

// List with all free plan codes
export const freePlanCodes = Object.freeze(
  featureDefinitions
    .filter(({ freePlanCode }) => freePlanCode)
    .flatMap(({ freePlanCode, freeTrialPlanCode }) =>
      freeTrialPlanCode ? [freePlanCode, freeTrialPlanCode] : [freePlanCode]
    )
);

/* Features that conflict with other features in the shopping cart
 example: { TI: "TC" }
 the above means that TC cannot be added to the cart if TI is also there */
export const conflictingShoppingCartFeatures = Object.freeze(
  featureDefinitions
    .filter(({ conflictingShoppingCartFeature: blockAdditionToCart }) => blockAdditionToCart)
    .reduce(
      (previous, current) => ({ ...previous, [current.code]: current.conflictingShoppingCartFeature }),
      {} as TConflictingShoppingCartFeatures
    )
);

// List of Features No Auto Renewal
export const autoRenewalFeatures = Object.freeze(
  featureDefinitions
    .filter(({ isSubscription, autoRenewalPlanCodes }) => isSubscription && autoRenewalPlanCodes !== undefined)
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr.code]: { autoRenewalPlanCodes: curr.autoRenewalPlanCodes },
      }),
      {} as TAutoRenewalFeatures
    )
);

// List of Features Powertrains
export const featuresPowertrains = Object.freeze(
  featureDefinitions.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.code]: curr.powertrains,
    }),
    {} as Record<TFeatureCodeEnum, TPowertrainEnum[]>
  )
);

export const featuresTags = Object.freeze(
  featureDefinitions.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.code]: curr.tag,
    }),
    {} as Record<TFeatureCodeEnum, TTagEnum>
  )
);

// List of Features thar has options to be selected (or can have options, based on the vehicle)
export const featureWithOptions = Object.freeze(
  featureDefinitions.filter(({ hasOptions }) => hasOptions || hasOptions === null).map(({ code }) => code)
);

/**
 * These are features that based on the vehicle plans might show a different price for the same feature
 * e.g.: MOT can show 12 months free, 24 months free or 0.30€ per day
 */
export const featureWithDifferentPrices = Object.freeze([
  FeatureCodeEnum.MAN_ONLINE_TRAFFIC,
  FeatureCodeEnum.EFFICIENT_ROUTE,
  FeatureCodeEnum.ONLINE_NEWS,
  FeatureCodeEnum.EFFICIENT_CRUISE,
]);

export const FEATURE_SKUS: TFeatureSkus = {
  [FeatureCodeEnum.MAP_UPDATE]: 'man-sku00000052',
  [FeatureCodeEnum.MAN_ONLINE_TRAFFIC]: 'man-sku00000060',
  [FeatureCodeEnum.LANGUAGE_PACKAGE]: 'man-sku00000064',
  [FeatureCodeEnum.TIP_MATIC]: 'man-sku00000067',
  [VariantCodeEnum.TM_EFFICIENCY]: 'man-sku00000067',
  [VariantCodeEnum.TM_EFFICIENCY_PLUS]: 'man-sku00000067',
  [VariantCodeEnum.TM_MANOEUVRE]: 'man-sku00000067',
  [VariantCodeEnum.TM_OFFROAD]: 'man-sku00000067',
  [VariantCodeEnum.TM_PERFORMANCE]: 'man-sku00000067',
  [VariantCodeEnum.TM_TANKER]: 'man-sku00000067',
  [FeatureCodeEnum.EFFICIENT_ROUTE]: 'man-sku00000072',
  [FeatureCodeEnum.ONLINE_NEWS]: 'man-sku00000076',
  [FeatureCodeEnum.LANGUAGE_RECOGNITION]: 'man.sku00000077',
  [FeatureCodeEnum.TIME_INFO]: 'man-sku00000082',
  [FeatureCodeEnum.TIME_CONTROL]: 'man-sku00000083',
  [FeatureCodeEnum.IDLE_SHUTDOWN]: 'man-sku00000084',
  [FeatureCodeEnum.EFFICIENT_ROLL]: 'man-sku00000086',
  [FeatureCodeEnum.EFFICIENT_CRUISE]: 'man-sku00000087',
  [FeatureCodeEnum.KICKDOWN_DEACTIVATION]: 'man-sku00000091',
  [FeatureCodeEnum.SECOND_PHONE]: 'man-sku00000092',
};

export const REFERENCE_NUMBER_OF_DAYS = 31;

export const TIP_MATIC_VARIANTS_ORDER = Object.freeze([
  VariantCodeEnum.TM_EFFICIENCY,
  VariantCodeEnum.TM_EFFICIENCY_PLUS,
  VariantCodeEnum.TM_MANOEUVRE,
  VariantCodeEnum.TM_OFFROAD,
  VariantCodeEnum.TM_TANKER,
  VariantCodeEnum.TM_PERFORMANCE,
]);
