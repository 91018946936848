import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DialogTypeEnum } from '@/enums/DialogTypeEnum';

import useDialogNavigate from '@/hooks/utils/useDialogNavigate';

import { removeLastReleaseReadFromLS } from '@/helpers/dialogs';
import { GaEvents } from '@/helpers/googleAnalytics/events';

import { Badge } from '@/components/ui-kit/Badge';
import Icon from '@/components/ui-kit/Icon';
import WhatsNew from '@/features/dialogs/WhatsNew';

import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';

import { ActionBarItemWrapper } from './styled';

const ServiceInfoWidget = () => {
  const [totalUnread, setTotalUnread] = useState(0);

  const dialogNavigate = useDialogNavigate();

  const onOpenDialogHandler = () => {
    removeLastReleaseReadFromLS();

    dialogNavigate(DialogTypeEnum.WHATS_NEW);

    GaEvents.dialogInteractions('whatIsNew');
  };

  return (
    <>
      <ActionBarItem id="serviceInfo">
        <ActionBarItem.Icon>
          <Icon iconName="info-sign" />
          {totalUnread > 0 && <Badge $isWarning $isIndicator />}
        </ActionBarItem.Icon>
        <ActionBarItem.Popover title={<FormattedMessage id="mannow.moduleName" />}>
          <ActionBarItem.List>
            <ActionBarItemWrapper>
              <ActionBarItem.ListItem onClick={onOpenDialogHandler}>
                <FormattedMessage id="mannow.whatIsNew.title" tagName="div" />
                <Badge>{totalUnread}</Badge>
              </ActionBarItem.ListItem>
            </ActionBarItemWrapper>
          </ActionBarItem.List>
        </ActionBarItem.Popover>
      </ActionBarItem>

      <WhatsNew notifyTotalUnread={(total) => setTotalUnread(total)} />
    </>
  );
};

export default ServiceInfoWidget;
