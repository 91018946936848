import {
  TFeatureSubscriptionPlansResponse,
  TSubscriptionPlanMappingResponse,
  TSubscriptionPlanResponse,
  TSubscriptionResponse,
  TVehicleSubscriptionPlansResponse,
} from './types';

import { config } from '@/config';
import { fetchData } from '@/services/config/common';

export const fetchFeatureSubscriptionPlans = (
  featureCode: string,
  assetId: string
): Promise<TFeatureSubscriptionPlansResponse[]> =>
  fetchData(`${config.backend.MANNOW_SUBM}/subscription-manager/subscription-plans/${featureCode}/${assetId}`);

export const fetchSubscriptionPlans = (featureCodes: string[] = []): Promise<TSubscriptionPlanResponse[]> =>
  fetchData(
    `${config.backend.MANNOW_SUBM}/subscription-manager/subscription-plans?featureCodes=${featureCodes.join(',')}`
  );

export const fetchVehicleSubscriptionPlans = (
  featureCode: TFeatureAndVariantCodeEnum
): Promise<TVehicleSubscriptionPlansResponse[]> =>
  fetchData(`${config.backend.MANNOW_SUBM}/subscription-manager/subscription-plans/${featureCode}`);

export const fetchSubscriptions = (): Promise<TSubscriptionResponse[]> =>
  fetchData(`${config.backend.MANNOW_SUBM}/subscription-manager/subscriptions`);

export const fetchSubscriptionsPlanMapping = (planCode: string): Promise<TSubscriptionPlanMappingResponse> =>
  fetchData(`${config.backend.MANNOW_SUBM}/subscription-manager/subscription-plan-mapping/${planCode}`);
