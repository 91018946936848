import gql from 'graphql-tag';

import {
  TFeatureDetailsRequestVariables,
  TFeatureDetailsResponse,
  TTermsAndConditionsRequestVariables,
  TTermsAndConditionsResponse,
} from './types';

import { config } from '@/config';
import { fetchGraphqlData } from '@/services/config/common';

export const fetchFeatureDetails = (featureCodes: string[], locale: string) => {
  const query = gql`
    query FeatureQuery($featureCodes: [String]!, $locale: String!) {
      featuresByCodeAndLocale(featureCodes: $featureCodes, locale: $locale) {
        code
        salesCode
        locale
        title
        shortDescription
        description
        info1Description
        info1DescriptionLabel
        info2Description
        info2DescriptionLabel
        info3Description
        info3DescriptionLabel
        specification {
          code
          url
        }
        category {
          slug
        }
        type {
          slug
        }
        icon {
          image {
            url
          }
        }
        variants {
          code
          locale
          title
          shortDescription
          description
          info1DescriptionLabel
          info1Description
          info2DescriptionLabel
          info2Description
          info3DescriptionLabel
          info3Description
          info4DescriptionLabel
          info4Description
          icon {
            image {
              url
            }
          }
        }
      }

      serviceSpecificationsByCodeAndLocale(featureCodes: $featureCodes, locale: $locale) {
        code
        url
      }
    }
  `;

  const variables = {
    featureCodes: [...featureCodes],
    locale,
  };

  return fetchGraphqlData<TFeatureDetailsRequestVariables, TFeatureDetailsResponse>(
    config.backend.MANNOW_CMS || '',
    query,
    variables
  );
};

export const fetchTermsAndConditionsCMS = (locale: string, context: TTermsAndConditionsContextEnum) => {
  const query = gql`
    query TCsQuery($context: String!, $locale: String!) {
      termsAndConditionsByContextAndLocale(context: $context, locale: $locale) {
        context
        locale
        url
      }
    }
  `;

  const variables = {
    context,
    locale,
  };

  return fetchGraphqlData<TTermsAndConditionsRequestVariables, TTermsAndConditionsResponse>(
    config.backend.MANNOW_CMS || '',
    query,
    variables
  );
};
