import styled from 'styled-components';

const PageLoaderWrapper = styled.div.attrs({
  id: 'pageLoader',
})``;

const PageLoaderContainer = styled.div.attrs({
  className: 'pageLoaderCenter',
})``;

const PageLoaderAnimation = styled.div.attrs({
  className: 'pageLoaderAnimation',
})``;

const PageLoaderText = styled.div.attrs({
  className: 'pageLoaderText',
})``;

const PageLoaderLogo = styled.div.attrs({
  className: 'pageLoaderLogo',
})``;

const PageLoaderSVG = styled.svg.attrs({
  height: '50',
  width: '50',
  xmlns: 'http://www.w3.org/2000/svg',
})``;

const PageLoaderSVGGroup = styled.g.attrs({
  fill: 'none',
  fillRule: 'evenodd',
})``;

const PageLoaderSVGPath1 = styled.path.attrs({
  fill: 'var(--0)',
  d: 'M0 0h50v50H0z',
})``;

const PageLoaderSVGPath2 = styled.path.attrs({
  fill: 'var(--4)',
  d: 'M45.3 26.05c0 4.55-3.7 8.25-8.25 8.25s-8.25-3.7-8.25-8.25 3.7-8.25 8.25-8.25 8.25 3.7 8.25 8.25zm-23.6 7.8h5.05V18.2H21.7v15.65zm-8-15.65c2.7 0 4.9 2.2 4.9 4.9 0 1.5-.7 2.9-1.8 3.8-.3.3-.7.55-1.15.7l4.1 6.25H14.4l-4.25-6.25v6.25h-4.9V18.2h8.45zM0 50h50V0H0v50z',
})``;

const PageLoader = () => (
  <PageLoaderWrapper>
    <PageLoaderContainer>
      <PageLoaderAnimation>
        <span />
        <span />
        <span />
        <span />
        <PageLoaderLogo>
          <PageLoaderSVG>
            <PageLoaderSVGGroup>
              <PageLoaderSVGPath1 />
              <PageLoaderSVGPath2 />
            </PageLoaderSVGGroup>
          </PageLoaderSVG>
        </PageLoaderLogo>
      </PageLoaderAnimation>
      <PageLoaderText>loading</PageLoaderText>
    </PageLoaderContainer>
  </PageLoaderWrapper>
);

export default PageLoader;
