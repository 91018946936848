import { FormattedMessage } from 'react-intl';

import { TNewsProps } from '@/features/types';

import FormattedHTMLMessage from '@/components/FormattedHTMLMessage';
import HeaderImage from '@/features/dialogs/WhatsNew/HeaderImage';

const LanguageRecognitionNews = ({ articleRef }: TNewsProps) => (
  <>
    <HeaderImage fileName="language-recognition-news.png" />
    <article ref={articleRef}>
      <FormattedMessage id="mannow.whatIsNew.languageRecognition.title" tagName="h4" />

      <FormattedHTMLMessage id="mannow.whatIsNew.languageRecognition.body" />
    </article>
  </>
);

export default LanguageRecognitionNews;
