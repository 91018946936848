import { TAssetVehicle, TVehicleItem, TVehicleTypeCounters } from '@/hooks/types';

import useAssetVehiclesQuery from '@/hooks/queries/rio/useAssetVehiclesQuery';
import useVehicleListQuery from '@/hooks/queries/vehicleManager/useVehicleListQuery';
import useToggles from '@/hooks/utils/useToggles';

const calculateValues = (mannowVehicles: TVehicleItem[], rioVehicles: TAssetVehicle[]) => {
  const mergedVehicles = [] as TVehicle[];
  const vehicleTypesCounter = {} as TVehicleTypeCounters;

  mannowVehicles.forEach((v) => {
    const rioVehicle = rioVehicles.find((rv) => rv.id === v.assetId);

    if (rioVehicle != null) {
      const vehicle: TVehicle = { ...v, ...(({ id, ...rest }) => rest)(rioVehicle) };

      if (vehicle.type) {
        vehicleTypesCounter[vehicle.type] = vehicleTypesCounter[vehicle.type]
          ? vehicleTypesCounter[vehicle.type] + 1
          : 1;
      }

      mergedVehicles.push(vehicle);
    }
  });

  return { mergedVehicles, vehicleTypesCounter };
};

/** This hook will intersect the vehicle list from RIO with OUR vehicle list */
const useVehicleList = () => {
  const { isDemoFleetToggle } = useToggles();

  const { data: mannowVehicles = [], isPending: isLoadingVehicles } = useVehicleListQuery();
  const { data: rioVehicles = [], isPending: isLoadingRioVehicles } = useAssetVehiclesQuery();

  /** If it is DemoFleet we only want to show vehicles with installable/installed features */
  const filteredVehicles = isDemoFleetToggle
    ? mannowVehicles.filter((v) => v.totalInstallable || v.totalInstalled || v.totalPending)
    : mannowVehicles;

  const { mergedVehicles, vehicleTypesCounter } = calculateValues(filteredVehicles, rioVehicles);

  return {
    list: mergedVehicles,
    typeCounters: vehicleTypesCounter,
    isPending: isLoadingVehicles || isLoadingRioVehicles,
  };
};

export default useVehicleList;
