import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { DIALOG_OPENED_ON_CLICK, DIALOG_QUERY_PARAM, SELECTED_ASSET_QUERY_PARAM } from '@/features/dialogs/constants';
import { RECOMMENDATION_ORIGIN_QUERY_PARAM } from '@/features/myFleet/constants';

import { RecommendationOriginEnum } from '@/enums/RecommendationOriginEnum';

const useDialogNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return (
    dialogCode: TFeatureCodeEnum | TDialogTypeEnum,
    assetId?: string,
    recommendationOrigin?: RecommendationOriginEnum | null
  ) => {
    let urlParams = `?${DIALOG_QUERY_PARAM}=${dialogCode}`;

    if (assetId) {
      urlParams += `&${SELECTED_ASSET_QUERY_PARAM}=${assetId}`;
    }

    if (recommendationOrigin) {
      urlParams += `&${RECOMMENDATION_ORIGIN_QUERY_PARAM}=${recommendationOrigin}`;
    }

    if (location.search) {
      urlParams += `${location.search.replace('?', '&')}`;
    }

    // To avoid duplicate the query param
    if (searchParams.get(DIALOG_QUERY_PARAM) !== dialogCode) {
      // We are setting the DIALOG_OPENED_ON_CLICK state to make sure that the user triggered this event
      navigate(urlParams, { replace: true, state: { [DIALOG_OPENED_ON_CLICK]: true } });
    }
  };
};

export default useDialogNavigate;
