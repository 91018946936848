import { StorageEnum } from '@/enums/StorageEnum';

// #region What's New Dialog
/** Will return a list of read releases saved in the LocalStorage */
const getReleasesReadOnLS = (): string[] => {
  const value = window.localStorage.getItem(StorageEnum.STORAGE_KEY_USER_WHATS_NEW_RELEASES);

  // Value is set as null in the e2e tests so we can hide the dialog
  return value === 'null' ? [] : JSON.parse(value || '[]');
};

/** Will return whether a specified releases was marked as read */
export const isReleaseReadOnLS = (release: string) => {
  const releasesReadLocaleStorage = getReleasesReadOnLS();

  return releasesReadLocaleStorage.includes(release);
};

/** Will return a list of unread releases based on a list with all releases */
export const getAllUnreadReleasesFromLS = (releases: string[]) => {
  const releasesReadLocaleStorage = getReleasesReadOnLS();

  return releases.filter((release) => !releasesReadLocaleStorage.includes(release));
};

export const setReleaseAsReadOnLS = (release: string) => {
  if (release) {
    const releasesReadLocaleStorage = getReleasesReadOnLS();

    if (!releasesReadLocaleStorage.includes(release)) {
      releasesReadLocaleStorage.push(release);
    }

    window.localStorage.setItem(
      StorageEnum.STORAGE_KEY_USER_WHATS_NEW_RELEASES,
      JSON.stringify(releasesReadLocaleStorage.sort())
    );
  }
};

export const removeLastReleaseReadFromLS = () => {
  const releasesReadLocaleStorage = getReleasesReadOnLS();

  releasesReadLocaleStorage.pop();

  window.localStorage.setItem(
    StorageEnum.STORAGE_KEY_USER_WHATS_NEW_RELEASES,
    JSON.stringify(releasesReadLocaleStorage)
  );
};
// #endregion
