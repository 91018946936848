import styled, { css } from 'styled-components';

import Button from '@/components/ui-kit/Button';
import BaseIcon from '@/components/ui-kit/Icon';

export const Content = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      padding: ${spaces.mediumSmall};
    `;
  }};
`;

export const Warning = styled.div`
  ${({ theme }) => {
    const { colors, spaces } = theme;

    return css`
      display: flex;
      align-items: center;
      color: ${colors.brandWarning};
      margin: ${spaces.extraSmall} auto ${spaces.mediumSmall} auto;
    `;
  }};
`;

export const WarningText = styled.h4`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      margin: 0 0 0 ${spaces.small};
    `;
  }};
`;

export const Icon = styled(BaseIcon)`
  ${({ theme }) => {
    const { fonts } = theme;

    return css`
      flex: 1 1;
      font-size: ${fonts.size.h2};
    `;
  }}
`;

export const Footer = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      margin-top: ${spaces.small};
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    `;
  }};
`;

export const FooterLeft = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      flex-grow: 1;
      margin-top: ${spaces.extraSmall};
    `;
  }};
`;
export const FooterRight = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      display: flex;
      flex-grow: 1;
      justify-content: end;
      align-items: center;
      text-align: center;
      margin-top: ${spaces.extraSmall};
    `;
  }};
`;

export const ButtonGotIt = styled(Button)`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      margin-left: ${spaces.small};
    `;
  }};
`;

export const LastUpdateText = styled.div`
  ${({ theme }) => {
    const { colors, fonts } = theme;

    return css`
      font-size: ${fonts.size.small};
      font-weight: ${fonts.weight.thin};
      color: ${colors.grayDark};
    `;
  }};
`;

export const StyledParagraph = styled.p`
  margin-bottom: 0;
`;
