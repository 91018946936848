import { datadogLogs } from '@datadog/browser-logs';

import { TCustomError } from '@/services/types';

export const logNoPlansWarning = (assetId: string, featureCode: TFeatureAndVariantCodeEnum) =>
  datadogLogs.logger.warn(`No subscription plans found (${featureCode} - ${assetId})`);

export const logAssetMissingWarning = (assetId: string, featureCode: TFeatureAndVariantCodeEnum | string) =>
  datadogLogs.logger.warn(`Subscription found (${featureCode}) for a vehicle not found (${assetId}) in RIO Assets`);

export const logOrderPDFDownloadError = (orderID: string, error: TCustomError) =>
  datadogLogs.logger.error('Could not download order PDF', { orderID, error });

export const logRequestError = (message: string, errorData: unknown, result: unknown) => {
  datadogLogs.logger.error(message, { errorData, result });
};
