import { Suspense, lazy, useEffect, useState } from 'react';

const LazyReactQueryDevtools = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

const ReactQueryDevtools = () => {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    window.showReactQueryDevtools = () => setShowDevtools((oldValue) => !oldValue);
  }, []);

  return showDevtools ? (
    <Suspense fallback={<></>}>
      <LazyReactQueryDevtools />
    </Suspense>
  ) : null;
};

export default ReactQueryDevtools;
