export const enum StorageEnum {
  STORAGE_KEY_DARK_MODE = 'dev-user-menu-dark-mode',
  STORAGE_KEY_DEMO_FLEET = 'dev-user-menu-demo-fleet',
  STORAGE_KEY_GA_DEBUGGER = 'dev-user-menu-ga-debugger-mode',
  STORAGE_KEY_LANGUAGE = 'dev-user-menu-language',
  STORAGE_KEY_SENTRY = 'dev-user-menu-sentry',
  STORAGE_KEY_USER_FROM_TEST_FLEET = 'app-user-from-test-fleet',
  STORAGE_KEY_USER_HAS_ACCESS = 'app-user-has-access',
  STORAGE_KEY_USER_ID = 'app-user-id',
  STORAGE_KEY_USER_LOCALE = 'app-user-locale',
  STORAGE_KEY_USER_SESSION_EXPIRED = 'app-user-session-expired',
  STORAGE_KEY_USER_SHOW_MOT_SETUP_INSTRUCTIONS_EXPANDER = 'app-user-show-mot-setup-instructions-expander',
  STORAGE_KEY_USER_TOKEN = 'app-user-token',
  STORAGE_KEY_USER_WHATS_NEW_RELEASES = 'app-user-whats-new-releases',
}
