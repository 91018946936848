import { FeatureEligibilityEnum } from '@/enums/FeatureEligibilityEnum';

import useFeaturesDetailsQuery from '@/hooks/queries/cms/useFeaturesDetailsQuery';
import useVehicleFeaturesEligibilityQuery from '@/hooks/queries/vehicleManager/useVehicleFeaturesEligibilityQuery';

import { groupFeatureWithVariants } from '@/helpers/feature';

const useInstalledFeatures = (assetId: string) => {
  const { data: vehicleFeatures, isPending: isLoadingVehicleFeatures } = useVehicleFeaturesEligibilityQuery(
    assetId,
    FeatureEligibilityEnum.INSTALLED
  );

  const { data: featuresDetails = [], isPending: isLoadingFeaturesDetails } = useFeaturesDetailsQuery();

  return {
    data: groupFeatureWithVariants(vehicleFeatures || [], featuresDetails!),
    isPending: isLoadingVehicleFeatures || isLoadingFeaturesDetails,
  };
};

export default useInstalledFeatures;
