import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';

import ErrorState from '@rio-cloud/rio-uikit/ErrorState';

import { ORDERS_ROUTE } from '@/routes';

const NotFoundError = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const historyLocationFrom = location.state;

  const defaultGoToRoute = {
    forwardTo: '/',
    goToText: <FormattedMessage id="mannow.general.homepage" />,
  };

  const goToRouteMap = {
    [ORDERS_ROUTE as string]: {
      forwardTo: ORDERS_ROUTE,
      goToText: <FormattedMessage id="mannow.general.orders" />,
    },
  };

  const goToRoute = goToRouteMap[historyLocationFrom] || defaultGoToRoute;

  return (
    <ErrorState
      headline={<FormattedMessage id="mannow.pageNotFound.headline" />}
      message={<FormattedMessage id="mannow.pageNotFound.message" />}
      buttons={[
        {
          text: (
            <FormattedMessage
              id="mannow.phrase.goToPage"
              values={{
                page: goToRoute.goToText,
              }}
            />
          ),
          onClick: () => {
            navigate(goToRoute.forwardTo);
          },
        },
      ]}
    />
  );
};

export default NotFoundError;
