import { NavLink } from 'react-router-dom';

import useShoppingCartCountQuery from '@/hooks/queries/featureManager/useShoppingCartCountQuery';

import Icon from '@/components/ui-kit/Icon';

import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';

import { SHOPPING_CART_ROUTE } from '@/routes';

import { ShoppingCartBadge } from './styled';

const ShoppingCartWidget = () => {
  const { data: shoppingCartItemsCount } = useShoppingCartCountQuery();

  return (
    <ActionBarItem>
      <ActionBarItem.Icon>
        <NavLink to={SHOPPING_CART_ROUTE}>
          <Icon iconName="shopping-cart" />
          {shoppingCartItemsCount != null && shoppingCartItemsCount > 0 && (
            <ShoppingCartBadge>{shoppingCartItemsCount}</ShoppingCartBadge>
          )}
        </NavLink>
      </ActionBarItem.Icon>
    </ActionBarItem>
  );
};

export default ShoppingCartWidget;
