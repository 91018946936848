import { TSubscriptionPlan } from '@/hooks/types';

import { FeatureCodeEnum, FeaturePlanEnum } from '@/enums/FeatureCodeEnum';

import { freeTrialPlanCodes } from '@/data/featureDefinitions';

import { getFeatureCode } from '../feature';

export const isPrepaidAvailable = (plans: TSubscriptionPlan[] = [], featureCode: TFeatureCodeEnum) =>
  featureCode === FeatureCodeEnum.LANGUAGE_RECOGNITION
    ? plans?.length > 0 && plans?.every(({ code }) => code.includes(FeaturePlanEnum.LANGUAGE_RECOGNITION_PRE_PAID))
    : false;

export const isFreeTrialAvailable = (planCode: string) =>
  freeTrialPlanCodes.some((code) => planCode?.startsWith(code!));

export const isEfficientCruiseWithRoll = (planCode?: string) =>
  planCode === FeaturePlanEnum.EFFICIENT_CRUISE_WITH_EFFICIENT_ROLL;

export const filterPrepaidPlans = (plans: TSubscriptionPlan[]) =>
  plans.filter((plan) => !isPrepaidAvailable([plan], getFeatureCode(plan.featureCode)));

/** Returns the lowest plan (One Time Payment: lowest price - Subscription: lowest duration or lowest price) */
export const getLowestPlan = (plans: TSubscriptionPlan[], isOneTimePayment = false, filterPrepaid?: boolean) => {
  // Remove prepaid plans since the user already paid for these features
  let filteredPlans = plans;

  if (filterPrepaid) {
    filteredPlans = filterPrepaidPlans(plans);
  }

  return filteredPlans.reduce((previous, current) => {
    if (isOneTimePayment || !previous.duration || !current.duration) {
      return previous.price?.amount < current.price?.amount ? previous : current;
    }

    return current.price?.amount < previous.price?.amount ? current : previous;
  }, {} as TSubscriptionPlan);
};
