import { TStorageUtil } from '@/configuration/types';

const configureStorage = () => {
  let storedAccessToken: string | null = null;
  return {
    discardAccessToken: () => {
      storedAccessToken = null;
    },
    getAccessToken: () => storedAccessToken,
    hasAccessToken: () => Boolean(storedAccessToken),
    saveAccessToken: (token: string) => {
      storedAccessToken = token;
    },
  };
};

const extractAccessTokenFromWindowLocation = (window?: Window) => {
  if (!window || !window.location || !window.location.href || typeof window.location.href !== 'string') {
    return;
  }

  let token;

  const replacer = (substring: string, arg: string): string => {
    token = arg;
    return substring;
  };

  window.location.href.replace(/access_token=([^&]+)/u, replacer);

  return token;
};

const configureAccessToken = (window: Window | undefined, storage: TStorageUtil) => {
  const urlToken = extractAccessTokenFromWindowLocation(window);

  if (urlToken) {
    storage.saveAccessToken(urlToken);
  }

  return storage;
};

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch {
    return null;
  }
};

export const accessToken = configureAccessToken(typeof window !== 'undefined' ? window : undefined, configureStorage());
