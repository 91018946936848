import { useRef } from 'react';

import { CAROUSEL_IMAGES_PATH, ImageFileNameEnum } from '@/enums/ImageFileEnum';

import useIsSmallDevice from './useIsSmallDevice';
import useRouterUtils from './useRouterUtils';

const addLinkToHeader = (image: string) => {
  const link = document.createElement('link');
  link.setAttribute('href', `${CAROUSEL_IMAGES_PATH}/${image}`);
  link.setAttribute('id', image);
  link.setAttribute('rel', 'preload');
  link.setAttribute('as', 'image');
  const head = document.querySelector('head');
  const isLinkAdded = document.getElementById(image);

  if (head && !isLinkAdded) {
    head.appendChild(link);
  }
};

// This hook will preload the Largest Contentful Paint (LCP) image
const useAddPreloadedLinks = () => {
  const isMobile = useIsSmallDevice();
  const { isAvailableFeatures } = useRouterUtils();

  const linkAddedRef = useRef(false);

  if (!linkAddedRef.current) {
    if (isAvailableFeatures) {
      addLinkToHeader(isMobile ? ImageFileNameEnum.MANNOW_INSTALLATION_MOBILE : ImageFileNameEnum.MANNOW_INSTALLATION);
    }

    linkAddedRef.current = true;
  }

  return linkAddedRef.current;
};

export default useAddPreloadedLinks;
