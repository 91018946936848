import { FormattedMessage as BaseFormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { TFormattedHTMLMessageProps } from '@/components/types';

import { useUserDataContext } from '@/providers/hooks';

import { GaEvents } from '@/helpers/googleAnalytics/events';
import { getMOTSetupInstructionsLink } from '@/helpers/onlineTraffic';

import Icon from '@/components/ui-kit/Icon';
import TextBold from '@/components/ui-kit/TextBold';

import { MY_FLEET_ROUTE, ORDERS_ROUTE } from '@/routes';

import { AccentText } from './styled';

const MAILTO_VALUE = 'mailto:digitalservices@man.eu?subject=Failed Installations';
const FLEET_ADMIN_URL = 'https://administration.assets.rio.cloud/#/assets';

const FormattedHTMLMessage = ({ id, values, tagName }: TFormattedHTMLMessageProps) => {
  const { locale } = useUserDataContext();

  return (
    <BaseFormattedMessage
      id={id}
      tagName={tagName}
      values={{
        ...values,
        br: <br />,
        TextBold: (content) => <TextBold>{content}</TextBold>,
        TextBoldMedium: (content) => <TextBold fontWeight="medium">{content}</TextBold>,
        LinkMail: (content) => (
          <a onClick={() => GaEvents.linkInteractions('linkMail')} href={MAILTO_VALUE}>
            {content}
          </a>
        ),
        LinkOrdersPage: (content) => <NavLink to={ORDERS_ROUTE}>{content}</NavLink>,
        LinkMyFleetPage: (content) => (
          <NavLink to={MY_FLEET_ROUTE} onClick={() => GaEvents.linkInteractions('linkMyFleetPage')}>
            {content}
          </NavLink>
        ),
        LinkToMOTSetupInstructions: (content) => (
          <a target="_blank" rel="noreferrer" href={getMOTSetupInstructionsLink(locale)}>
            {content}
          </a>
        ),
        LinkFleetAdmin: (content) => (
          <a target="_blank" rel="noreferrer" href={FLEET_ADMIN_URL}>
            <Icon iconName="link" />
            {content}
          </a>
        ),
        List: (content) => <ul>{content}</ul>,
        ListItem: (content) => <li>{content}</li>,
        AccentText: (content) => <AccentText>{content}</AccentText>,
      }}
    />
  );
};

export default FormattedHTMLMessage;
