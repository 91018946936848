import styled, { css } from 'styled-components';

export const SidebarWrapper = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      padding-left: ${spaces.mediumSmall};
      padding-right: ${spaces.mediumSmall};
    `;
  }}
`;

export const SidebarTitle = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      padding-left: ${spaces.extraSmall};
    `;
  }}
`;
