import { useQuery } from '@tanstack/react-query';

import { TVehicleItem } from '@/hooks/types';
import { TVehicleListItemResponse } from '@/services/types';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchVehicleList } from '@/services/vehicleManager';

import { convertSnakeToCamelCase } from '@/helpers/dataTransformation';

/** This hook will return the vehicles stored on our database */
const useVehicleListQuery = () =>
  useQuery({
    queryKey: [QueryIdentifierEnum.VEHICLE_LIST],
    queryFn: async () => {
      const { content } = await fetchVehicleList();

      return convertSnakeToCamelCase<TVehicleListItemResponse[], TVehicleItem[]>(content);
    },
  });

export default useVehicleListQuery;
