import { useQuery } from '@tanstack/react-query';
import groupBy from 'lodash/groupBy';

import { TSubscription } from '@/hooks/types';
import { TFeatureSubscriptionPlansResponse } from '@/services/types';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchFeatureSubscriptionPlans } from '@/services/subscriptionManager';

import useFeaturesDetailsQuery from '@/hooks/queries/cms/useFeaturesDetailsQuery';
import useSubscriptionsQuery from '@/hooks/queries/subscriptionManager/useSubscriptionsQuery';

import { convertSnakeToCamelCase } from '@/helpers/dataTransformation';
import { enrichSubscriptions } from '@/helpers/subscriptionManager';

const useSubscriptionsByFeatureQuery = (
  featureCodes: readonly TFeatureAndVariantCodeEnum[],
  assetId: string | null,
  disableQueryFn?: boolean
) => {
  const { data: featuresInfo = [], isPending: isLoadingFeatures } = useFeaturesDetailsQuery();
  const { data: subscriptions = [], isPending: isLoadingSubscriptions } = useSubscriptionsQuery();

  const isStillLoading = isLoadingSubscriptions || isLoadingFeatures;

  return useQuery({
    queryKey: [QueryIdentifierEnum.SUBSCRIPTION_PLANS_BY_FEATURE, { featureCodes, assetId }],
    queryFn: async () => {
      if (disableQueryFn) {
        return [];
      }

      const subscriptionsFiltered = subscriptions.filter(
        (subscription) =>
          (!assetId || assetId === subscription.assetId) &&
          featureCodes.includes(subscription.subscriptionPlan.featureCode)
      );

      const subscriptionsResponse = await Promise.all(
        subscriptionsFiltered.map((subscription) =>
          fetchFeatureSubscriptionPlans(subscription.subscriptionPlan.featureCode, subscription.assetId)
        )
      );

      const subscriptionPlansTransform = subscriptionsResponse.map((subscriptionResponse) =>
        subscriptionResponse.map((subscription) => ({
          assetId: subscription.asset_id,
          subscriptionPlan: subscription.plan,
        }))
      );

      const subscriptionsPlans = convertSnakeToCamelCase<
        Omit<TFeatureSubscriptionPlansResponse, 'asset_id' | 'plan'>[][],
        TSubscription[][]
      >(subscriptionPlansTransform);

      const enrichedSubscriptions = enrichSubscriptions(subscriptionsFiltered, featuresInfo, {
        ...subscriptionsPlans,
      });

      const subscriptionData = groupBy(enrichedSubscriptions, ({ subscriptionPlan }) => subscriptionPlan.featureCode);

      const subscriptionList = [];

      for (const key in subscriptionData) {
        if (Object.hasOwn(subscriptionData, key)) {
          subscriptionList.push(subscriptionData[key]);
        }
      }

      return subscriptionList;
    },
    enabled: featureCodes.length > 0 && !isStillLoading,
  });
};

export default useSubscriptionsByFeatureQuery;
