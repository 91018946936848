import get from 'lodash/get';
import { IntlShape } from 'react-intl';

import { TGroupedFeaturesVariants, TSortedVariants } from '../types';
import { TConflictingShoppingCartFeatures, TFeatureVariants } from '@/data/types';
import {
  TFeatureDetails,
  TFeatureShoppingCart,
  TShoppingCartAdditionalProperties,
  TSubscriptionEnriched,
  TSubscriptionPlan,
  TVariantDetails,
  TVariantStatus,
  TVehicleFeaturesEligibility,
} from '@/hooks/types';

import { CustomerConfirmationEnum } from '@/enums/CustomerConfirmationEnum';
import { EfficientCruiseEnum, EfficientCruiseOptionEnum } from '@/enums/EfficientCruiseEnum';
import { FeatureCodeEnum } from '@/enums/FeatureCodeEnum';
import { PaymentTypeEnum } from '@/enums/PaymentTypeEnum';
import { InstallationStatusEnum } from '@/enums/StatusEnum';
import { MapUpdatePackageEnum, SubscriptionCodeMUEnum, SubscriptionPeriodEnum } from '@/enums/SubscriptionCodesEnum';

import {
  TIP_MATIC_VARIANTS_ORDER,
  allFeatureCodes,
  conflictingShoppingCartFeatures,
  featureGroups,
  featureWithOptions,
  oneTimePaymentFeatures,
  subscriptionFeatures,
} from '@/data/featureDefinitions';

export const isOneTimePayment = (featureCode: TFeatureAndVariantCodeEnum) =>
  oneTimePaymentFeatures.includes(featureCode);

export const isSubscription = (featureCode: TFeatureAndVariantCodeEnum) => subscriptionFeatures.includes(featureCode);

/** Get the Feature Code from Variants or the Feature Code itself */
export const getFeatureCode = (featureCode: TFeatureAndVariantCodeEnum) =>
  (featureGroups[featureCode as keyof TFeatureVariants] || featureCode) as TFeatureCodeEnum;

/** Get the correct subscription code based on the provided region and period */
export const getSubscriptionCodeForMapUpdate = (
  regionPackage?: TMapUpdatePackageEnum,
  subscriptionPeriod?: SubscriptionPeriodEnum | number
) => {
  const config = {
    [MapUpdatePackageEnum.PACKAGE_SINGLE]: {
      [SubscriptionPeriodEnum.TWELVE_MONTHS]: SubscriptionCodeMUEnum.SINGLE_1_YEAR,
      [SubscriptionPeriodEnum.THIRTY_SIX_MONTHS]: SubscriptionCodeMUEnum.SINGLE_3_YEARS,
    },
    [MapUpdatePackageEnum.PACKAGE_REGIONS]: {
      [SubscriptionPeriodEnum.TWELVE_MONTHS]: SubscriptionCodeMUEnum.REGION_1_YEAR,
      [SubscriptionPeriodEnum.THIRTY_SIX_MONTHS]: SubscriptionCodeMUEnum.REGION_3_YEARS,
    },
    [MapUpdatePackageEnum.PACKAGE_PRO]: {
      [SubscriptionPeriodEnum.TWELVE_MONTHS]: SubscriptionCodeMUEnum.FULL_1_YEAR,
      [SubscriptionPeriodEnum.THIRTY_SIX_MONTHS]: SubscriptionCodeMUEnum.FULL_3_YEARS,
    },
  };

  const selectedPackage = regionPackage ? config[regionPackage as MapUpdatePackageEnum] : {};

  return selectedPackage[subscriptionPeriod as keyof typeof selectedPackage] as SubscriptionCodeMUEnum;
};

/** BO decided that we should not show the contract period for these features in the Available Features */
export const showContractPeriod = (featureCode: TFeatureAndVariantCodeEnum) =>
  featureCode !== FeatureCodeEnum.ONLINE_NEWS;

export const mapFeatureContent = (
  featureCode: string,
  features: TFeatureDetails[],
  additionalProperties: TShoppingCartAdditionalProperties
) => {
  const featureGroupCode = featureGroups[featureCode as TVariantCodeEnum];
  const featureContent = features.find((f) => f.code === (featureGroupCode || featureCode))!;

  const featureWithContent: TFeatureShoppingCart = {
    ...featureContent,
    subscriptionPeriod: additionalProperties.subscriptionPeriod,
    regionPackage: additionalProperties.regionPackage,
    region: additionalProperties.region,
    paymentType: additionalProperties.paymentType as TPaymentTypeEnum,
    subscriptionPlanCode: additionalProperties.subscriptionPlanCode,
  };

  if (featureGroupCode) {
    const featureGroupContent = featureContent.variants?.find((f) => f.code === featureCode)!;

    featureWithContent.group = {
      code: featureCode as TVariantCodeEnum,
      title: featureGroupContent.title,
      description: featureGroupContent.description,
      category: featureContent?.category,
      tag: featureContent?.tag,
      type: featureContent?.type,
    };
  }

  return featureWithContent;
};

/** This helper will return a list with the variants codes of a feature or only the feature code itself */
export const getFeatureOrVariantCodes = (featureCode: TFeatureAndVariantCodeEnum) => {
  const featureRelatedCodes = Object.keys(featureGroups).filter(
    (groupCode) => featureGroups[groupCode as keyof TFeatureVariants] === featureCode
  ) as unknown as TFeatureAndVariantCodeEnum[];

  if (featureRelatedCodes.length === 0) {
    return [featureCode];
  }

  return featureRelatedCodes;
};

export const mapSortLanguagePackageVariants = (
  installedVariants: TVariantStatus[],
  intl: IntlShape
): TSortedVariants[] => {
  const sortedVariantsCodes = installedVariants.map((variant) => ({
    variantCode: variant.code,
    installationStatus: variant.installationStatus,
    title: intl.formatMessage({ id: `mannow:common.languagePackage.${variant.code}` }),
  }));

  sortedVariantsCodes.sort((item1, item2) => {
    const curr = item1.title.toLowerCase();
    const next = item2.title.toLowerCase();

    return curr.localeCompare(next, intl.locale);
  });

  return sortedVariantsCodes;
};

export const mapSortTipMaticVariants = (featureVariants: TVariantDetails[], installedVariants: TVariantStatus[]) => {
  const sortedVariantsCodes = installedVariants.map((variant) => ({
    variantCode: variant.code,
    installationStatus: variant.installationStatus,
    title: featureVariants.find(({ code }) => code === variant.code)?.title,
  })) as TSortedVariants[];

  return sortTipMaticVariants(sortedVariantsCodes, 'variantCode');
};

export const groupFeatureWithVariants = (
  list: TVehicleFeaturesEligibility[],
  featuresDetails: TFeatureDetails[] = []
) => {
  const newList: TGroupedFeaturesVariants[] = [];
  const tipMaticVariants = [];

  for (const item of list) {
    const featureParent = featureGroups[item.feature.code as unknown as TVariantCodeEnum];

    if (featureParent) {
      tipMaticVariants.push(item);
    } else {
      newList.push({
        feature: featuresDetails.find((f) => f.code === item.feature.code)!,
        variants: item.variants.map((variant) => ({
          code: variant.variantCode,
          installationStatus: variant.installationStatus,
        })),
        installationStatus: item.installationStatus,
      });
    }
  }

  if (tipMaticVariants.length > 0) {
    const tipMatic = {
      feature: featuresDetails.find((f) => f.code === FeatureCodeEnum.TIP_MATIC)!,
      variants: tipMaticVariants.map((item) => ({
        code: item.feature.code as unknown as TVariantCodeEnum,
        installationStatus: item.installationStatus,
      })),
      installationStatus: null,
    };

    newList.push(tipMatic);
  }

  return newList;
};

export const getTotalUninstalledVariants = (variants: TVariantStatus[] = []) =>
  variants.filter((variant) => variant.installationStatus === InstallationStatusEnum.UNINSTALLED).length;

const hasAnyVariantByStatus = (variants: TVariantStatus[] = [], statusList: TInstallationStatusEnum[]) =>
  variants.some((variant) => statusList.includes(variant.installationStatus));

export const getFeatureInstallationStatus = (
  variants: TVariantStatus[],
  installationStatus: TInstallationStatusEnum | null
) => {
  if (variants?.length > 0) {
    if (hasAnyVariantByStatus(variants, [InstallationStatusEnum.ERROR])) {
      return InstallationStatusEnum.ERROR;
    } else if (hasAnyVariantByStatus(variants, [InstallationStatusEnum.PENDING])) {
      return InstallationStatusEnum.PENDING;
    }

    return InstallationStatusEnum.INSTALLED;
  }

  if (installationStatus) {
    return installationStatus;
  }
};

export const getFeaturePriceTypeFromFeatureCode = (featureCode: TFeatureAndVariantCodeEnum) => {
  if (isOneTimePayment(featureCode)) {
    return PaymentTypeEnum.ONE_TIME_PAYMENT;
  }

  return PaymentTypeEnum.DAY;
};

export const isFeatureOneTimePayment = (featureCode: TFeatureAndVariantCodeEnum) =>
  getFeaturePriceTypeFromFeatureCode(featureGroups[featureCode as TVariantCodeEnum] || featureCode) ===
  PaymentTypeEnum.ONE_TIME_PAYMENT;

export const isFeatureDailyType = (featureCode: TFeatureCodeEnum) =>
  getFeaturePriceTypeFromFeatureCode(featureCode) === PaymentTypeEnum.DAY;

export const getFeaturePrice = (
  featureCode: TFeatureAndVariantCodeEnum,
  price: number = 0,
  plans?: TSubscriptionPlan[],
  subscriptionPlanCode?: string
) => {
  if (!allFeatureCodes.includes(featureCode) || featureGroups[featureCode as TVariantCodeEnum]) {
    return price;
  }

  return (plans || []).find(({ code }) => code === subscriptionPlanCode)?.price.amount!;
};

export const sortTipMaticVariants = <T>(variants: T[], fieldToSort?: string) =>
  variants.sort(
    (f1, f2) =>
      TIP_MATIC_VARIANTS_ORDER.indexOf(fieldToSort ? get(f1, fieldToSort) : (f1 as TVariantCodeEnum)) -
      TIP_MATIC_VARIANTS_ORDER.indexOf(fieldToSort ? get(f2, fieldToSort) : (f2 as TVariantCodeEnum))
  );

export const getSortedFeaturesByFeatureTitle = (list: TGroupedFeaturesVariants[]) =>
  list.sort((f1, f2) => f1.feature.title.localeCompare(f2.feature.title));

export const getSortedSubscriptionsByFeatureTitle = (list: TSubscriptionEnriched[][]) =>
  list.sort((f1, f2) => f1[0].feature.title.localeCompare(f2[0].feature.title));

export const getConflictingShoppingCartFeature = (featureCode: TFeatureAndVariantCodeEnum) =>
  conflictingShoppingCartFeatures[featureCode as keyof TConflictingShoppingCartFeatures] || null;

export const getRecommendationOrigin = (
  featureCode: TFeatureCodeEnum,
  origin: TRecommendationOriginEnum,
  recommendedFeatureCode?: TFeatureAndVariantCodeEnum
) => (recommendedFeatureCode && featureCode === getFeatureCode(recommendedFeatureCode) ? origin : null);

export const getCustomerConfirmations = (selectedOption: TCustomerConfirmationEnum) => ({
  notPto: selectedOption === CustomerConfirmationEnum.ONE,
  acknowledgeRisksPto: selectedOption === CustomerConfirmationEnum.TWO,
});

export const getEfficientCruiseVariants = (variants: string[]) =>
  variants.reduce((acc, curr) => {
    const key = curr.includes(EfficientCruiseEnum.ADR)
      ? EfficientCruiseOptionEnum.IS_ADR
      : EfficientCruiseOptionEnum.NOT_ADR;

    return {
      ...acc,
      [key]: curr,
    };
  }, {}) as Record<TEfficientCruiseOptionEnum, string>;

export const hasPrepaidVariant = (variants: string[]) => variants?.includes('LANGUAGE_RECOGNITION_PRE_PAID');

export const hasOptionToSelect = (featureCode: TFeatureCodeEnum) =>
  featureWithOptions.some((code) => code === getFeatureCode(featureCode));

export const getFailedInstallationList = (installedFeatures: TGroupedFeaturesVariants[], intl: IntlShape) => {
  const list = [];

  for (const item of installedFeatures) {
    const variantsWithError = item.variants.filter(
      ({ installationStatus }) => installationStatus === InstallationStatusEnum.ERROR
    );

    if (item.installationStatus === InstallationStatusEnum.ERROR) {
      list.push({ featureCode: item.feature?.code, featureTitle: item.feature?.title });
    } else if (variantsWithError.length > 0) {
      const isLanguagePackage = item.feature?.code === FeatureCodeEnum.LANGUAGE_PACKAGE;
      const isTipMatic = item.feature?.code === FeatureCodeEnum.TIP_MATIC;

      list.push({
        featureCode: item.feature?.code,
        featureTitle: item.feature?.title,
        variants: isTipMatic
          ? mapSortTipMaticVariants(item.feature.variants, variantsWithError)
          : isLanguagePackage
          ? mapSortLanguagePackageVariants(variantsWithError, intl)
          : [],
      });
    }
  }

  return list;
};

export const filterFeaturesWithError = (installedFeatures: TGroupedFeaturesVariants[]) => {
  const list = installedFeatures.filter(
    ({ installationStatus }) => installationStatus && installationStatus !== InstallationStatusEnum.ERROR
  );

  for (const item of installedFeatures) {
    if (item.variants.length > 0) {
      const variants = item.variants.filter(
        ({ installationStatus }) => installationStatus !== InstallationStatusEnum.ERROR
      );

      if (variants.length > 0) {
        list.push({ ...item, variants });
      }
    }
  }

  return list;
};
