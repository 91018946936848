import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TFeatureInMaintenance } from '@/hooks/types';

import useServiceOutageMutation from '@/hooks/mutations/userManager/useServiceOutageMutation';
import useFeaturesDetailsQuery from '@/hooks/queries/cms/useFeaturesDetailsQuery';
import useIsSmallDevice from '@/hooks/utils/useIsSmallDevice';

import { DATE_TIME_FORMAT, dateToString } from '@/helpers/date';
import { getFeatureCode } from '@/helpers/feature';
import { GaEvents } from '@/helpers/googleAnalytics/events';

import BottomSheetBase from '@rio-cloud/rio-uikit/BottomSheet';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

import {
  ButtonGotIt,
  Content,
  Footer,
  FooterLeft,
  FooterRight,
  Icon,
  LastUpdateText,
  StyledParagraph,
  Warning,
  WarningText,
} from './styled';

const BottomSheet = ({ featuresInMaintenance }: { featuresInMaintenance: TFeatureInMaintenance[] }) => {
  const { data: featuresDetails, isPending: isLoadingFeatureDetails } = useFeaturesDetailsQuery();
  const { mutate: updateServiceOutage } = useServiceOutageMutation();

  const isMobile = useIsSmallDevice();

  const latestDate = () => {
    const dates = featuresInMaintenance.map((feature) => new Date(feature.lastUpdatedDate));
    let currentLatestDate = dates[0];

    for (let i = 1; i < dates.length; i++) {
      if (currentLatestDate.getTime() < dates[i].getTime()) {
        currentLatestDate = dates[i];
      }
    }

    return dateToString(currentLatestDate, DATE_TIME_FORMAT);
  };

  const closeSheet = () => updateServiceOutage();

  return (
    <BottomSheetBase
      show
      detach={!isMobile}
      onClose={() => closeSheet()}
      title={<FormattedMessage id="mannow.serviceOutageSheet.title" />}
      width={isMobile ? 'unset' : '400px'}
    >
      <Content>
        <Warning>
          <Icon iconName="warning-sign" />
          <WarningText>
            <FormattedMessage id="mannow.serviceOutageSheet.warning" />
          </WarningText>
        </Warning>
        <>
          <ul>
            <li>
              <FormattedMessage id="mannow.serviceOutageSheet.listPoint1" />
            </li>
            <li>
              <FormattedMessage id="mannow.serviceOutageSheet.listPoint2" />
            </li>
          </ul>
          <p>
            <FormattedMessage id="mannow.serviceOutageSheet.text1" />
          </p>
          <>
            <StyledParagraph>
              <FormattedMessage id="mannow.serviceOutageSheet.affectedFeatures" />
            </StyledParagraph>
            <ul>
              {featuresInMaintenance.map((feature) =>
                !isLoadingFeatureDetails ? (
                  <li key={feature.code}>
                    {featuresDetails?.find(({ code }) => code === getFeatureCode(feature.code))?.title}
                  </li>
                ) : (
                  <React.Fragment key={feature.code}>
                    <ContentLoader height={20} width={100} />
                    <br />
                  </React.Fragment>
                )
              )}
            </ul>
          </>
          <p>
            <FormattedMessage id="mannow.serviceOutageSheet.text2" />
          </p>
        </>
        <Footer>
          <FooterLeft>
            <LastUpdateText>
              <FormattedMessage id="mannow.phrase.lastUpdate" />
            </LastUpdateText>
            <span>{latestDate()}</span>
          </FooterLeft>
          <FooterRight>
            <a onClick={() => GaEvents.linkInteractions('serviceOutage')} href="mailto:digitalservices@man.eu">
              <FormattedMessage id="mannow.general.contactSupport" />
            </a>
            <ButtonGotIt onClick={() => closeSheet()}>
              <FormattedMessage id="mannow.general.gotIt" />
            </ButtonGotIt>
          </FooterRight>
        </Footer>
      </Content>
    </BottomSheetBase>
  );
};

export default BottomSheet;
