export const getKeyFromEnumValue = <T>(object: { [s: string]: string }, item: T) => {
  const index = Object.values(object).findIndex((i) => i === item);

  return (Object.keys(object)?.[index] || '').toLocaleLowerCase();
};

export const classNames = (options: Record<string, boolean | undefined>) => {
  let classes = '';

  for (const key in options) {
    if (options[key]) {
      classes = `${classes} ${key}`;
    }
  }

  return classes;
};
