import styled, { css } from 'styled-components';

import { FeatureInfoContainer } from '../FeatureInfo/styled';

type TPanelProps = {
  type?: TUIKitStyleTypes;
};

export const Panel = styled.div.attrs(({ type }: TPanelProps) => ({
  className: `panel panel-${type || 'default'}`,
  role: 'panel',
}))<TPanelProps>``;

export const PanelBody = styled.div.attrs({
  className: 'panel-body',
})``;

export const PanelContainer = styled.div`
  ${({ theme }) => {
    const { breakpoints, fonts, spaces } = theme;

    return css`
      align-self: center;
      justify-content: space-between;
      gap: ${spaces.extraSmall};

      ${FeatureInfoContainer} a {
        line-height: ${fonts.lineHeight.wide};
      }

      @media only screen and (max-width: ${breakpoints.small}) {
        display: flex;
        flex-flow: wrap;
      }
    `;
  }}
`;

export const PanelTitle = styled.div.attrs({
  className: 'panel-title',
})`
  ${({ theme }) => {
    const { breakpoints } = theme;

    return css`
      @media only screen and (max-width: ${breakpoints.small}) {
        align-self: flex-start;
        max-width: 50%;
        width: 50%;
        order: 1;
      }
    `;
  }}
`;

export const PanelStatusBarContainer = styled.div`
  ${({ theme }) => {
    const { breakpoints, spaces } = theme;

    return css`
      max-width: 70%;

      @media only screen and (max-width: ${breakpoints.small}) {
        max-width: 100%;
        padding-top: ${spaces.extraSmall};
      }
    `;
  }}
`;
