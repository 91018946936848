import {
  TAddShoppingCartItemRequestVariables,
  TAddShoppingCartItemResponse,
  TDeleteShoppingCartItemsRequestVariables,
  TDeleteShoppingCartItemsResponse,
  TFeatureStatusResponse,
  TOrderItemResponse,
  TOrderSubmitVehicles,
  TOrdersResponse,
  TPlaceOrderResponse,
  TShoppingCartCountResponse,
  TShoppingCartResponse,
} from './types';

import { customException } from './config/errorHandling';
import { config } from '@/config';
import { deleteData, downloadFile, fetchData, postData } from '@/services/config/common';

import { buildQueryString } from '@/helpers/url';

export const fetchFeaturesStatus = (): Promise<TFeatureStatusResponse[]> =>
  fetchData(`${config.backend.MANNOW_FM}/features`);

export const fetchShoppingCartCount = (): Promise<TShoppingCartCountResponse> =>
  fetchData(`${config.backend.MANNOW_FM}/shoppingcart/summary`);

// api works with pagination, for now we want to show all items (size=1000)
export const fetchShoppingCart = (): Promise<TShoppingCartResponse> =>
  fetchData(`${config.backend.MANNOW_FM}/shoppingcart/items?size=1000`);

export const deleteShoppingCartItems = (
  items: TDeleteShoppingCartItemsRequestVariables
): Promise<TDeleteShoppingCartItemsResponse> =>
  deleteData(`${config.backend.MANNOW_FM}/shoppingcart/items${buildQueryString(items)}`);

export const addShoppingCartItem = (
  item: TAddShoppingCartItemRequestVariables
): Promise<TAddShoppingCartItemResponse> =>
  postData(`${config.backend.MANNOW_FM}/shoppingcart/items`, item, {
    errorCallback: (e) => {
      throw customException(e);
    },
  });

export const fetchOrderItems = (orderId: number): Promise<TOrderItemResponse> =>
  fetchData(`${config.backend.MANNOW_FM}/orders/${orderId}`);

export const fetchOrders = (): Promise<TOrdersResponse[]> => fetchData(`${config.backend.MANNOW_FM}/orders`);

export const placeOrder = (items: TOrderSubmitVehicles, isPrepaid: boolean): Promise<TPlaceOrderResponse> =>
  postData(`${config.backend.MANNOW_FM}/orders?isPrepaid=${isPrepaid}`, items);

export const downloadOrderPDF = (orderId: string, locale: string) =>
  downloadFile(
    `${config.backend.MANNOW_FM}/orders/${orderId}/pdf/${locale}`,
    'mannow.orders.couldNotDownloadOrderFile'
  );
