import { config } from '@/config';

import { LanguagesCodes } from '@/enums/LanguagesCodesEnum';
import { StorageEnum } from '@/enums/StorageEnum';

const getFlagEmoji = (countryCode: string): string =>
  countryCode.replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)));

const languageInterval = 1;
let currentIndex = 0;
const languages = LanguagesCodes;

const stopTestAllLanguages = (): void => {
  clearInterval(languageInterval);
  currentIndex = 0;
};

export const formattedLanguageName = (locale: string): string => {
  const countryCode = locale.slice(3);

  return `${getFlagEmoji(countryCode)} ${new Intl.DisplayNames(['en'], {
    type: 'region',
  }).of(countryCode)} (${locale})`;
};

export const nextLanguage = (): string => {
  const currentLanguage = languages[currentIndex];

  currentIndex++;
  const next = languages[currentIndex];

  if (!next) {
    stopTestAllLanguages();
  }

  return currentLanguage;
};

export const developmentLocale = (): string | null | void => {
  if (config.serviceEnvironment !== 'production') {
    const developerLanguageLocale = localStorage.getItem(StorageEnum.STORAGE_KEY_LANGUAGE) || null;

    return developerLanguageLocale;
  }
};
