import { FormattedMessage } from 'react-intl';

import { TNewsProps } from '@/features/types';

import FormattedHTMLMessage from '@/components/FormattedHTMLMessage';
import HeaderImage from '@/features/dialogs/WhatsNew/HeaderImage';

const AvailableFeaturesNews = ({ articleRef }: TNewsProps) => (
  <>
    <HeaderImage fileName="available-features-news.png" />
    <article ref={articleRef}>
      <FormattedMessage id="mannow.phrase.availableFeatures" tagName="h3" />

      <FormattedMessage id="mannow.whatIsNew.availableFeatures.introduction" />

      <ol>
        <FormattedHTMLMessage id="mannow.whatIsNew.availableFeatures.info1" tagName="li" />
        <FormattedHTMLMessage id="mannow.whatIsNew.availableFeatures.info1Complement" />
        <br />
        <br />

        <FormattedHTMLMessage id="mannow.whatIsNew.availableFeatures.info2" tagName="li" />
        <>
          <FormattedMessage id="mannow.whatIsNew.availableFeatures.info2Complement" />
          <ul>
            <FormattedMessage id="mannow.whatIsNew.availableFeatures.info2.firstPoint" tagName="li" />
            <FormattedMessage id="mannow.whatIsNew.availableFeatures.info2.secondPoint" tagName="li" />
            <FormattedMessage id="mannow.whatIsNew.availableFeatures.info2.thirdPoint" tagName="li" />
          </ul>
        </>
        <br />

        <FormattedHTMLMessage id="mannow.whatIsNew.availableFeatures.info3" tagName="li" />
        <FormattedMessage id="mannow.whatIsNew.availableFeatures.info3Complement" />
      </ol>
    </article>
  </>
);

export default AvailableFeaturesNews;
