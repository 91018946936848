export enum LanguagesCodesEnum {
  BG_BG = 'bg-BG',
  DA_DK = 'da-DK',
  EL_GR = 'el-GR',
  ES_ES = 'es-ES',
  FI_FI = 'fi-FI',
  HR_HR = 'hr-HR',
  IT_IT = 'it-IT',
  LV_LV = 'lv-LV',
  NL_NL = 'nl-NL',
  PT_PT = 'pt-PT',
  RO_RO = 'ro-RO',
  SL_SI = 'sl-SI',
  CS_CZ = 'cs-CZ',
  DE_DE = 'de-DE',
  EN_GB = 'en-GB',
  ET_EE = 'et-EE',
  FR_FR = 'fr-FR',
  HU_HU = 'hu-HU',
  LT_LT = 'lt-LT',
  NB_NO = 'nb-NO',
  PL_PL = 'pl-PL',
  SK_SK = 'sk-SK',
  SV_SE = 'sv-SE',
}

export const LanguagesCodes = Object.values(LanguagesCodesEnum).filter((v) => isNaN(Number(v))) as string[];
