import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { TRelease, TWhatsNewProps } from '@/features/types';

import { DIALOG_QUERY_PARAM } from '@/features/dialogs/constants';
import { RECOMMENDATION_ORIGIN_QUERY_PARAM } from '@/features/myFleet/constants';

import { DialogTypeEnum } from '@/enums/DialogTypeEnum';
import { StorageEnum } from '@/enums/StorageEnum';

import useDialogNavigate from '@/hooks/utils/useDialogNavigate';

import { getReleaseNotes } from './helper';
import { dateDiffInDays } from '@/helpers/date';
import { getAllUnreadReleasesFromLS, isReleaseReadOnLS, setReleaseAsReadOnLS } from '@/helpers/dialogs';

import Button from '@rio-cloud/rio-uikit/Button';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import ReleaseNotes from '@rio-cloud/rio-uikit/ReleaseNotes';

import { AVAILABLE_FEATURES_ROUTE, MY_FLEET_ROUTE } from '@/routes';

import AvailableFeaturesNews from './AvailableFeaturesNews';
import BulkBookingNews from './BulkBookingNews';
import LanguageRecognitionNews from './LanguageRecognitionNews';
import RecommendedFeaturesNews from './RecommendedFeaturesNews';
import { ReleaseNotesWrapper } from './styled';

const releaseList: TRelease[] = [
  /** Add the most recent news at the top - if there is no news, the array should be left empty */
  {
    release: '4.0.0',
    date: new Date('2024-05-14T00:00:00.000Z'),
    content: BulkBookingNews,
  },
  {
    release: '3.0.0',
    date: new Date('2024-04-08T00:00:00.000Z'),
    content: LanguageRecognitionNews,
  },
  {
    release: '2.0.0',
    date: new Date('2024-02-19T00:00:00.000Z'),
    content: RecommendedFeaturesNews,
  },
  {
    release: '1.0.0',
    date: new Date('2023-04-13T00:00:00.000Z'),
    content: AvailableFeaturesNews,
  },
];

const WhatsNew = ({ notifyTotalUnread }: TWhatsNewProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dialogNavigate = useDialogNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const hasQueryParam = searchParams.get(DIALOG_QUERY_PARAM) === DialogTypeEnum.WHATS_NEW;
  const recommendationOrigin = searchParams.get(RECOMMENDATION_ORIGIN_QUERY_PARAM);

  useEffect(() => {
    if (hasQueryParam || releaseList.length === 0) {
      return;
    }

    const unreadReleases = getAllUnreadReleasesFromLS(releaseList.map(({ release }) => release));

    if (notifyTotalUnread) {
      notifyTotalUnread(unreadReleases.length);
    }

    const routeMatched = [MY_FLEET_ROUTE, AVAILABLE_FEATURES_ROUTE].some((route) => location.pathname === route);

    if (!routeMatched || window.localStorage.getItem(StorageEnum.STORAGE_KEY_USER_WHATS_NEW_RELEASES) === 'null') {
      return;
    }

    const newestRelease = releaseList?.[0];
    const isNewestReleaseRead = isReleaseReadOnLS(newestRelease?.release);
    const isNewestReleaseSixMonthOld = dateDiffInDays(newestRelease?.date, new Date()) > 180;

    if (isNewestReleaseRead || isNewestReleaseSixMonthOld) {
      return;
    }

    setReleaseAsReadOnLS(newestRelease?.release);

    dialogNavigate(DialogTypeEnum.WHATS_NEW);
  }, [hasQueryParam, navigate, location.pathname, dialogNavigate, notifyTotalUnread]);

  const onHide = () => setSearchParams(recommendationOrigin ? { recommendationOrigin } : {}, { replace: true });

  return (
    <Dialog
      useOverflow
      showCloseButton
      show={hasQueryParam}
      onHide={onHide}
      bsSize={Dialog.SIZE_MD}
      title={<FormattedMessage id="mannow.whatIsNew.title" />}
      body={
        <ReleaseNotesWrapper>
          <ReleaseNotes releaseNotes={getReleaseNotes(releaseList)} />
        </ReleaseNotesWrapper>
      }
      footer={
        <Button onClick={onHide}>
          <FormattedMessage id="mannow.general.close" />
        </Button>
      }
    />
  );
};

export default WhatsNew;
