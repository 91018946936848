import isEmpty from 'lodash/isEmpty';

import { TPriceTemplate } from '../types';
import { TFeatureDetails, TFeatureShoppingCart, TSubscription, TSubscriptionPlan } from '@/hooks/types';

import { FeatureCodeEnum } from '@/enums/FeatureCodeEnum';
import { PaymentTypeEnum } from '@/enums/PaymentTypeEnum';
import { MapUpdatePackageEnum, SubscriptionPeriodEnum } from '@/enums/SubscriptionCodesEnum';

import { featureGroups } from '@/data/featureDefinitions';

import { getFeatureCode, getSubscriptionCodeForMapUpdate, isOneTimePayment, isSubscription } from '../feature';
import { getLowestPlan, isFreeTrialAvailable } from '../featurePlans';

const DEFAULT_PRICE = 0;

const getPriceLowestPlan = ({ code }: TFeatureDetails, subscriptionsPlans: TSubscriptionPlan[]) => {
  const subscriptionsPlansFiltered = subscriptionsPlans.filter(
    ({ featureCode }) => featureCode === code || featureGroups[featureCode as TVariantCodeEnum] === code
  );

  if (code === FeatureCodeEnum.MAP_UPDATE) {
    const lowestPlan = getLowestPlan(subscriptionsPlans);

    return {
      price: lowestPlan?.price.amount,
      paymentType: lowestPlan?.price.type,
      subscriptionPeriod: lowestPlan?.duration?.value,
    };
  }

  return getSubscriptionsLowestPrice(subscriptionsPlansFiltered);
};

const getMarketplacePricesEnhanced = (subscriptionPlans: TSubscriptionPlan[]) => {
  if (subscriptionPlans.length === 0) {
    return undefined;
  }

  const lowestPrice = getLowestPlan(subscriptionPlans, true);

  return {
    price: lowestPrice.price.amount,
  };
};

const getMuPriceEnhanced = (
  feature: TFeatureShoppingCart,
  subscriptionPlans: TSubscriptionPlan[],
  isCheckout: boolean
) => {
  const mapUpdateSingleOneYearCode = getSubscriptionCodeForMapUpdate(
    MapUpdatePackageEnum.PACKAGE_SINGLE,
    SubscriptionPeriodEnum.TWELVE_MONTHS
  );

  const mapUpdateLowestPriceRegion = subscriptionPlans.filter((mktPlan) => mktPlan.code === mapUpdateSingleOneYearCode);

  const { price } = mapUpdateLowestPriceRegion[0] || {};

  const mapUpdateLowestPriceRegionWithDefaultPrice = [
    {
      ...mapUpdateLowestPriceRegion[0],
      price: {
        ...price,
        amount: price ? price.amount : DEFAULT_PRICE,
        paymentType: feature.paymentType || null,
        subscriptionPeriod: feature.subscriptionPeriod || null,
      },
    },
  ];

  const mktPrice =
    isCheckout && subscriptionPlans.length > 0
      ? subscriptionPlans.filter(
          (mktPlan) =>
            mktPlan.code === getSubscriptionCodeForMapUpdate(feature.regionPackage, feature.subscriptionPeriod)
        )
      : null;

  const mapUpdateCheckoutPriceTemplate = [
    {
      price: {
        amount: mktPrice ? mktPrice[0].price.amount : DEFAULT_PRICE,
        paymentType: feature.paymentType || null,
        subscriptionPeriod: feature.subscriptionPeriod || null,
      },
    },
  ];

  const mapUpdatePriceItem = isCheckout ? mapUpdateCheckoutPriceTemplate : mapUpdateLowestPriceRegionWithDefaultPrice;

  const hasMapUpdatePriceItem = mapUpdatePriceItem.length > 0;
  const muPrice = hasMapUpdatePriceItem && !!mapUpdatePriceItem[0].price && mapUpdatePriceItem[0].price;

  return {
    price: hasMapUpdatePriceItem && muPrice ? muPrice.amount : DEFAULT_PRICE,
    paymentType: hasMapUpdatePriceItem && muPrice ? muPrice.paymentType : null,
    subscriptionPeriod: hasMapUpdatePriceItem && muPrice ? muPrice.subscriptionPeriod : null,
  };
};

const getPriceTemplate = (
  feature: TFeatureShoppingCart,
  subscriptionPlans: TSubscriptionPlan[],
  isCheckout: boolean
): TPriceTemplate => {
  const featurePrice = {
    price: 0,
    paymentType: null,
    subscriptionPeriod: null,
  };

  const subscriptionPlansForFeature = subscriptionPlans.filter(
    (plan) => getFeatureCode(plan.featureCode) === feature.code
  );

  const getPrice = {
    [FeatureCodeEnum.MAP_UPDATE]: () => getMuPriceEnhanced(feature, subscriptionPlansForFeature, isCheckout),
    [PaymentTypeEnum.SUBSCRIPTION]: () => getSubscriptionsLowestPrice(subscriptionPlansForFeature),
    [PaymentTypeEnum.ONE_TIME_PAYMENT]: () => getMarketplacePricesEnhanced(subscriptionPlansForFeature) || featurePrice,
    default: () => featurePrice,
  };

  return getPrice[getFeaturePaymentType(feature.code)]();
};

const getSubscriptionsLowestPrice = (subscriptionsPlans: TSubscriptionPlan[]) => {
  const hasFreeTrialAvailable = subscriptionsPlans.some(({ code }) => isFreeTrialAvailable(code));
  const lowestPlan = getLowestPlan(subscriptionsPlans);
  const hasLowestPricePlan = !isEmpty(lowestPlan);

  return {
    price: hasFreeTrialAvailable || !hasLowestPricePlan ? 0 : lowestPlan.price.amount,
    paymentType: hasFreeTrialAvailable || !hasLowestPricePlan ? null : lowestPlan.price.type,
    subscriptionPeriod: !hasLowestPricePlan ? null : lowestPlan.duration?.value,
  };
};

export const mapFeaturePrice = (feature: TFeatureDetails, subscriptions: TSubscription[] = []) => {
  const subscriptionPlans = subscriptions.map(({ subscriptionPlan }) => subscriptionPlan);

  const priceTemplate = getPriceLowestPlan(feature, subscriptionPlans) as TPriceTemplate;

  return {
    ...feature,
    price: priceTemplate.price,
    paymentType: priceTemplate.paymentType,
    subscriptionPeriod: priceTemplate.subscriptionPeriod,
    plans: subscriptionPlans,
  };
};

export const getFeaturePaymentType = (featureCode: TFeatureAndVariantCodeEnum) => {
  if (isSubscription(featureCode)) {
    if (featureCode === FeatureCodeEnum.MAP_UPDATE) {
      return featureCode;
    }

    return PaymentTypeEnum.SUBSCRIPTION;
  }

  if (isOneTimePayment(featureCode)) {
    return PaymentTypeEnum.ONE_TIME_PAYMENT;
  }

  return 'default';
};

export const mapFeaturePriceByPlanCode = (
  planCode: string,
  feature: TFeatureShoppingCart,
  marketplacePrices: TSubscriptionPlan[]
) => {
  const subscriptionPlans = marketplacePrices.filter(({ code }) => code === planCode)!;

  const priceTemplate = getPriceTemplate(feature, subscriptionPlans, true);

  return {
    ...feature,
    price: priceTemplate.price,
    paymentType: priceTemplate.paymentType,
    subscriptionPeriod: priceTemplate.subscriptionPeriod,
    plans: subscriptionPlans,
  };
};

export const isFeaturePricePerOption = (featureCode: TFeatureCodeEnum) =>
  [FeatureCodeEnum.LANGUAGE_PACKAGE, FeatureCodeEnum.TIP_MATIC].includes(featureCode);
