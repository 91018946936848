export enum FeatureCodeEnum {
  MAP_UPDATE = 'MU',
  MAN_ONLINE_TRAFFIC = 'MOT',
  LANGUAGE_PACKAGE = 'LP',
  TIP_MATIC = 'TM',
  ONLINE_NEWS = 'ONLINE_NEWS',
  EFFICIENT_ROUTE = 'EFFICIENT_ROUTE',
  LANGUAGE_RECOGNITION = 'LANGUAGE_RECOGNITION',
  TIME_INFO = 'TI',
  TIME_CONTROL = 'TC',
  IDLE_SHUTDOWN = 'ISD',
  EFFICIENT_ROLL = 'EFFICIENT_ROLL',
  EFFICIENT_CRUISE = 'EFFICIENT_CRUISE',
  KICKDOWN_DEACTIVATION = 'KICKDOWN_DEACTIVATION',
  SECOND_PHONE = 'SECOND_PHONE',
}

export enum VariantCodeEnum {
  TM_EFFICIENCY = 'TM_EFFICIENCY',
  TM_EFFICIENCY_PLUS = 'TM_EFFICIENCY_PLUS',
  TM_MANOEUVRE = 'TM_MANOEUVRE',
  TM_OFFROAD = 'TM_OFFROAD',
  TM_TANKER = 'TM_TANKER',
  TM_PERFORMANCE = 'TM_PERFORMANCE',
}

export const enum FeaturePlanEnum {
  MOT_FREE = 'MOT-FREE',
  MOT_PAID = 'MOT-PAID',
  ONLINE_NEWS_FREE = 'ONLINE-NEWS-FREE',
  ONLINE_NEWS_PRESET_FREE = 'ONLINE-NEWS-PRESET-FREE',
  LANGUAGE_RECOGNITION_PRE_PAID = 'LANGUAGE-RECOGNITION-PRE-PAID',
  LANGUAGE_RECOGNITION_PAID = 'LANGUAGE-RECOGNITION',
  EFFICIENT_ROUTE_FREE = 'EFFICIENT-ROUTE-FREE',
  EFFICIENT_ROUTE_PAID = 'EFFICIENT-ROUTE-PAID',
  EFFICIENT_CRUISE_WITH_EFFICIENT_ROLL = 'EFFICIENT-CRUISE-WITH-EFFICIENT-ROLL',
  EFFICIENT_CRUISE_ONLY = 'EFFICIENT-CRUISE-ONLY',
}

export enum LanguagePackageVariantsEnum {
  LP_AR = 'Arabic',
  LP_BG = 'Bulgarian',
  LP_CN = 'Chinese',
  LP_CS = 'Serbian',
  LP_CZ = 'Czech',
  LP_DE = 'Germany',
  LP_DK = 'Danish',
  LP_EE = 'Estonian',
  LP_EN = 'English',
  LP_ES = 'Spanish',
  LP_FI = 'Finnish',
  LP_FR = 'French',
  LP_GR = 'Greek',
  LP_HE = 'Hebrew',
  LP_HR = 'Croatian',
  LP_HU = 'Hungarian',
  LP_IT = 'Italian',
  LP_KR = 'Korean',
  LP_LT = 'Lithuanian',
  LP_LV = 'Latvian',
  LP_NL = 'Dutch',
  LP_NO = 'Norwegian',
  LP_PL = 'Polish',
  LP_PT = 'Portuguese',
  LP_RO = 'Romanian',
  LP_RU = 'Russian',
  LP_SE = 'Swedish',
  LP_SI = 'Slovenian',
  LP_SK = 'Slovak',
  LP_TR = 'Turkish',
  LP_UA = 'Ukrainian',
}
