import { useEffect, useRef } from 'react';

const useSendGAEventOnce = (condition: boolean, callback: () => void, forceSendAgain?: boolean) => {
  const sentGaEvent = useRef(false);

  useEffect(() => {
    if ((!sentGaEvent.current && condition) || forceSendAgain) {
      sentGaEvent.current = true;

      callback();
    }
  }, [condition, callback, forceSendAgain]);
};

export default useSendGAEventOnce;
