import styled, { css } from 'styled-components';

import BaseIcon from '@/components/ui-kit/Icon';

import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';

export const ServiceOutageBannerStyled = styled(ApplicationLayoutBodyBanner)`
  ${({ theme }) => {
    const { colors, spaces } = theme;

    return css`
      align-items: center;
      background: ${colors.brandWarning} !important;
      display: flex;
      justify-content: center;
      padding: ${spaces.extraSmall} !important;
    `;
  }};
`;

export const WarningText = styled.span`
  ${({ theme }) => {
    const { fonts } = theme;

    return css`
      font-weight: ${fonts.weight.medium};
      white-space: nowrap;
    `;
  }};
`;

export const Icon = styled(BaseIcon)`
  ${({ theme }) => {
    const { fonts, spaces } = theme;

    return css`
      font-size: ${fonts.size.h3};
      margin-top: -2px;
      margin-bottom: -1px;
      padding-right: ${spaces.extraSmall};
    `;
  }}
`;

export const TextInfo = styled.span`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      padding: 0 ${spaces.tiny} 0 ${spaces.tiny};
      margin-left: ${spaces.tiny};
      border-left: 1px solid white;
    `;
  }};
`;

export const OpenServiceOutageSheetText = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
