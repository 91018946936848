import * as Sentry from '@sentry/react';

import { config } from '@/config';
import { parseJwt } from '@/configuration/tokenHandling/accessToken';

import { StorageEnum } from '@/enums/StorageEnum';

const UNDEFINED_TOKEN = '<YOUR SENTRY DSN>';

export const initSentry = (isFromTestFleet: boolean) => {
  const isSentryEnabledInDev = sessionStorage.getItem(StorageEnum.STORAGE_KEY_SENTRY) === 'true';
  const isSentryAlreadyInitialized = Sentry.getCurrentHub().getClient();

  if ((import.meta.env.PROD || isSentryEnabledInDev) && !isSentryAlreadyInitialized) {
    const release = config.serviceVersion;
    const isProduction = import.meta.env.PROD && import.meta.env.MODE === 'production';
    const environment = isFromTestFleet && isProduction ? 'production-test' : import.meta.env.MODE;
    const dsn = config.sentryToken;

    const hasToken = !dsn?.startsWith(UNDEFINED_TOKEN);

    if (hasToken) {
      Sentry.init({
        dsn,
        environment,
        release,
        integrations: [Sentry.linkedErrorsIntegration({ key: 'cause', limit: 25 }), Sentry.replayIntegration()],
        normalizeDepth: 6,
        replaysOnErrorSampleRate: isProduction && !isFromTestFleet ? 0.5 : 0,
        profilesSampleRate: isProduction && !isFromTestFleet ? 0.5 : 0,
      });
    }
  }
};

export const reportErrorToSentry = (
  error: unknown,
  level?: Sentry.SeverityLevel,
  transaction?: string,
  extraData?: object
): void => {
  if (level) {
    Sentry.getCurrentScope().setLevel(level);
  }

  if (transaction) {
    Sentry.getCurrentScope().setTag('transaction', transaction);
  }

  if (extraData) {
    Sentry.getCurrentScope().setExtra('extraData', extraData);
  }

  const token = sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_TOKEN);
  const decodedToken = parseJwt(token!);

  Sentry.getCurrentScope().setUser({ id: decodedToken?.sub, segment: decodedToken?.tenant });
  Sentry.getCurrentScope().setTag('fleet_id', decodedToken?.account);
  Sentry.getCurrentScope().captureException(error);
};
