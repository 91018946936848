import styled, { css } from 'styled-components';

type TFontWeight = 'thin' | 'light' | 'normal' | 'medium' | 'bold';

const TextBold = styled.span`
  ${({ theme, fontWeight }: { fontWeight?: TFontWeight } & TStyledTheme) => {
    const { fonts } = theme;

    const newFontWeight = fontWeight ? fonts.weight[fontWeight] : fonts.weight.bold;

    return css`
      font-weight: ${newFontWeight};
    `;
  }}
`;

export default TextBold;
