import { useEffect, useRef, useState } from 'react';

import { TBaseNews } from '@/features/types';

import { isReleaseReadOnLS, setReleaseAsReadOnLS } from '@/helpers/dialogs';

import { Badge } from '@/components/ui-kit/Badge';

import useOnScreen from '@rio-cloud/rio-uikit/useOnScreen';

const BaseNews = ({ release, content: Content }: TBaseNews) => {
  const [isReleaseRead, setIsReleaseRead] = useState(false);

  const articleRef = useRef(null);
  const isArticleVisible = useOnScreen(articleRef);

  useEffect(() => {
    if (isArticleVisible) {
      setReleaseAsReadOnLS(release);
      setIsReleaseRead(true);
    }

    if (isReleaseReadOnLS(release)) {
      setIsReleaseRead(true);
    }
  }, [release, isArticleVisible]);

  return (
    <>
      {!isReleaseRead && <Badge $isWarning />}

      <Content articleRef={articleRef} />
    </>
  );
};

export default BaseNews;
