import { useQuery } from '@tanstack/react-query';

import { TRecommendationsResponse } from '@/services/types';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchRecommendations } from '@/services/recommendationEngine';

import { logRequestError } from '@/helpers/logging';

const useRecommendedFeatureQuery = (assetId?: string) =>
  useQuery({
    queryKey: [QueryIdentifierEnum.RECOMMENDED_FEATURE],
    queryFn: async () => {
      let response: TRecommendationsResponse[] = [];

      try {
        response = await fetchRecommendations();
      } catch (error) {
        logRequestError('Failed: Unable to fetch recommendation-engine API', null, error);
      }

      return response;
    },
    gcTime: Infinity,
    select: (data: TRecommendationsResponse[]) => {
      const recommendation = data?.find(({ asset_id }) => asset_id === assetId);

      return recommendation?.feature_codes[0] as TFeatureAndVariantCodeEnum;
    },
  });

export default useRecommendedFeatureQuery;
