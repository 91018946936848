import styled from 'styled-components';

export const ReleaseNotesWrapper = styled.div`
  .panel {
    position: relative;

    .panel-body > ul {
      list-style: none;
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
  }

  ul {
    list-style: disc;
  }

  .badge.badge-warning {
    left: 55px;
    min-height: 9px;
    min-width: 9px;
    position: absolute;
    top: 17px;
  }
`;
