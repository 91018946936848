import { createGlobalStyle, css } from 'styled-components';

import theme from '@/theme';

import FONT_MANNOW_EOT from './font/mannow.eot';
import FONT_MANNOW_SVG from './font/mannow.svg';
import FONT_MANNOW_TTF from './font/mannow.ttf';
import FONT_MANNOW_WOFF from './font/mannow.woff';
import FONT_MANNOW_WOFF2 from './font/mannow.woff2';

const fontFaces = css`
  @font-face {
    font-family: 'mannow';
    src: url(${FONT_MANNOW_EOT});
    src: url(${FONT_MANNOW_WOFF2}) format('woff2'), url(${FONT_MANNOW_WOFF}) format('woff'),
      url(${FONT_MANNOW_TTF}) format('truetype'), url(${FONT_MANNOW_SVG}) format('svg');
    font-weight: normal;
    font-style: normal;
  }
`;

const iconStyles = css`
  [class^='icon-']:before,
  [class*=' icon-']:before {
    font-family: 'mannow';
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;

    // For safety - reset parent styles, that can break glyph codes
    font-variant: normal;
    text-transform: none;

    // Fix buttons height, for twitter bootstrap
    line-height: 1em;

    // Font smoothing. That was taken from TWBS
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-efficiency:before {
    content: '\\e800';
  }

  .icon-safety:before {
    content: '\\e801';
  }

  .icon-comfort:before {
    content: '\\e802';
  }
`;

export const AppGlobalStyles = createGlobalStyle`
  // #region Override Rio UIKit rules
  @media screen and (max-width: ${theme.breakpoints.ultrasmall}) {
    html:not(.window-iframe).ua-mobile:not(.ua-mobile-ipad),
    html:not(.window-iframe):not([class='ua-']) {
      font-size: 62.5%;
    }
  }

  .spinnerInfoBox.fullscreen,
  .spinnerInfoBox.fullsized {
    background: transparent;
  }

  .popover {
    z-index: 1051;
  }

  li.dropdown-header:before, li.dropdown-header:after {
    content: '';
    background: ${theme.colors.grayLighter};
    height: 1px;
    flex: 1;
    margin: 0 -10px;
  }

  .dropdown-menu>.dropdown-header {
    gap: 15px;
  }


  // Hide the popover/tooltip when its reference is hidden
  .popover, .tooltip {
    &[data-popper-reference-hidden='true'] {
      visibility: hidden;
      pointer-events: none;
    }
  }

  // Force step tab to fill width
  .stepped-progress-bar.variant-rectangle .step-tab {
    flex: 1;
  }

  // Fix weird alignment for small tags
  .tag.tag-small {
    align-items: center;
    height: 24px;
    line-height: unset;
    padding-bottom: 0px;
    padding-top: 0px;
}

  // Make sure 'Go up' button is not hidden under our content
  .scroll-to-top.in {
    z-index: 1;
  }

  // Fix Badge position in dropdown menu
  .MobileHeaderModal .badge,
  .dropdown-menu:not(.ModuleNavigation-dropdown-menu) li>a .badge {
    position: unset;
    right: unset;
    top: unset;
  }

  .MobileSubmoduleNavigation .badge {
    display: none;
  }
  // #endregion

  ${fontFaces}
  ${iconStyles}
`;

export const ScrollToTopStyles = createGlobalStyle`
  ${({ $offsetBottom }: { $offsetBottom?: number | null }) => {
    return css`
      @media screen and (max-width: ${theme.breakpoints.medium}) {
        .ApplicationLayout .ApplicationLayoutBody .scroll-to-top {
          bottom: ${`${$offsetBottom || 82}px`};
        }
      }
    `;
  }}
`;
