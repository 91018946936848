import {
  TFeatureVehicleItemsResponse,
  TVehicleFeatureStatusCountResponse,
  TVehicleFeaturesByEligibilityResponse,
  TVehicleListResponse,
} from './types';

import { config } from '@/config';
import { fetchData } from '@/services/config/common';

export const fetchVehicleFeaturesByEligibility = (
  assetId: string,
  eligibility: TFeatureEligibilityEnum
): Promise<TVehicleFeaturesByEligibilityResponse> => {
  const url = `${config.backend.MANNOW_VM}/vehicles/${assetId}/details/${eligibility}`;
  return fetchData(encodeURI(url));
};

export const fetchFeatureVehiclesByEligibility = (
  featureCode: string,
  eligibility: TFeatureEligibilityEnum
): Promise<TFeatureVehicleItemsResponse> => {
  const url = `${config.backend.MANNOW_VM}/vehicles/features/${featureCode}/${eligibility}`;
  return fetchData(url);
};

export const fetchVehicle = (assetId: string): Promise<TVehicleListResponse> =>
  fetchData(encodeURI(`${config.backend.MANNOW_VM}/vehicles?asset_id=${assetId}`));

export const fetchVehicleList = (): Promise<TVehicleListResponse> =>
  fetchData(encodeURI(`${config.backend.MANNOW_VM}/vehicles?size=1000&sort=installableTotal,desc`));

export const fetchVehicleFeatureStatusCount = (): Promise<TVehicleFeatureStatusCountResponse[]> =>
  fetchData(`${config.backend.MANNOW_VM}/vehicles/features/status/count`);
