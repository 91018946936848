import { User, useFeatureFlag } from 'configcat-react';

import { TToggles } from '@/hooks/types';

import { useUserDataContext } from '@/providers/hooks';

export const TOGGLES_REF = {
  newBookingApi: 'new_booking_api',
  availableFeaturesDefaultPageToggle: 'available_features_default_page_toggle',
  isDemoFleet: 'isDemoFleet',
  installationMonitor: 'installationMonitorToggle',
  isTestFleet: 'isTestFleet',
};

const useToggles = (): TToggles => {
  const { fleetId } = useUserDataContext();

  const userObject = new User(fleetId);

  const { value: newBookingAPIToggle, loading: newBookingAPILoading } = useFeatureFlag(
    TOGGLES_REF.newBookingApi,
    false,
    userObject
  );

  const { value: availableFeaturesDefaultPageToggle, loading: availableFeaturesDefaultPageToggleLoading } =
    useFeatureFlag(TOGGLES_REF.availableFeaturesDefaultPageToggle, false, userObject);

  const { value: isDemoFleetToggle, loading: isDemoFleetLoading } = useFeatureFlag(
    TOGGLES_REF.isDemoFleet,
    false,
    userObject
  );

  const { value: installationMonitorToggle } = useFeatureFlag(TOGGLES_REF.installationMonitor, false, userObject);

  const { value: isTestFleetToggle, loading: isTestFleetLoading } = useFeatureFlag(
    TOGGLES_REF.isTestFleet,
    false,
    userObject
  );

  return {
    newBookingAPIToggle,
    availableFeaturesDefaultPageToggle,
    isDemoFleetToggle,
    installationMonitorToggle,
    isTestFleetToggle,
    isPending:
      newBookingAPILoading || availableFeaturesDefaultPageToggleLoading || isDemoFleetLoading || isTestFleetLoading,
  };
};

export default useToggles;
