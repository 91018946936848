import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useDefaultLandingPage from '@/hooks/utils/useDefaultLandingPage';

import { GaEvents } from '@/helpers/googleAnalytics/events';

import { pageTitles, routes } from '.';

const isUnknownRoute = (pathname: string): boolean => !routes.some((route) => pathname.startsWith(route));

const DefaultRedirect = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { assetId, orderId } = useParams();

  const defaultLandingPage = useDefaultLandingPage();

  useEffect(() => {
    if (isUnknownRoute(pathname) && defaultLandingPage) {
      navigate(defaultLandingPage);
    }

    if (pathname !== '/') {
      const title = pageTitles.find(({ page }) => pathname.includes(page))?.title;
      GaEvents.pageView(pathname.replace(assetId!, '*').replace(orderId!, '*'), title!);
    }
  }, [pathname, navigate, defaultLandingPage, assetId, orderId]);

  return null;
};

export default DefaultRedirect;
