export enum MapUpdatePackageEnum {
  PACKAGE_SINGLE = 'single',
  PACKAGE_REGIONS = 'regions',
  PACKAGE_PRO = 'pro',
}

export const enum SubscriptionCodeMUEnum {
  SINGLE_1_YEAR = 'MU-SINGLE-1',
  SINGLE_3_YEARS = 'MU-SINGLE-3',
  REGION_1_YEAR = 'MU-REGION-1',
  REGION_3_YEARS = 'MU-REGION-3',
  FULL_1_YEAR = 'MU-FULL-1',
  FULL_3_YEARS = 'MU-FULL-3',
}

export const enum SubscriptionCodeMOTEnum {
  PAID_1_YEAR = 'MOT-PAID-1',
  PAID_3_YEARS = 'MOT-PAID-3',
  FREE_1_YEAR = 'MOT-FREE-1',
  FREE_2_YEARS = 'MOT-FREE-2',
}

export const enum SubscriptionCodeLPEnum {
  LP_SINGLE = 'LP-SINGLE',
  LP_PACKAGE_3 = 'LP-PACKAGE-3',
}

export const enum SubscriptionCodeEREnum {
  PAID_1_YEAR = 'EFFICIENT-ROUTE-PAID-1',
  FREE_2_YEARS = 'EFFICIENT-ROUTE-FREE-2',
  PAID_3_YEARS = 'EFFICIENT-ROUTE-PAID-3',
}

export const enum SubscriptionCodeONEnum {
  FREE_PRESET_1_YEAR = 'ONLINE-NEWS-PRESET-FREE-2',
  FREE_2_YEARS = 'ONLINE-NEWS-FREE-2',
}

export const enum SubscriptionPeriodEnum {
  TWELVE_MONTHS = '12',
  TWENTY_FOUR_MONTHS = '24',
  THIRTY_SIX_MONTHS = '36',
}

export const enum SubscriptionOrderTypeEnum {
  RENEWAL = 'RENEWAL',
  NEW = 'NEW',
}

export const enum MarketplaceOrderTypeEnum {
  SUBSCRIPTION_RENEWAL = 'SUBSCRIPTION_RENEWAL',
  SUBSCRIPTION_NEW = 'SUBSCRIPTION_NEW',
  ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT',
}
