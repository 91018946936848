import isEmpty from 'lodash/isEmpty';
import { PropsWithChildren, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { subscriptionFeatures } from '@/data/featureDefinitions';

import useInstalledFeatures from '@/hooks/data/useInstalledFeatures';
import useInstallableFeaturesQuery from '@/hooks/queries/subscriptionManager/useInstallableFeaturesQuery';
import useSubscriptionsByFeatureQuery from '@/hooks/queries/subscriptionManager/useSubscriptionsByFeatureQuery';
import useVehicleQuery from '@/hooks/queries/vehicleManager/useVehicleQuery';

import { filterFeaturesWithError, getFailedInstallationList } from '@/helpers/feature';

import AsyncContentWrapper from '@/components/AsyncContentWrapper';

import { VehicleDetailContext } from './contexts';

const VehicleDetailProvider = ({ children }: PropsWithChildren) => {
  const params = useParams();
  const intl = useIntl();

  const [activeFilters, setActiveFilters] = useState<TFilterCode[]>([]);

  const assetId = params.assetId!;

  const { data: vehicleDetails, isPending: isLoadingVehicleDetails } = useVehicleQuery(assetId);

  const { data: installableFeatures, isPending: isLoadingInstallable } = useInstallableFeaturesQuery(assetId);
  const { data: installedFeaturesData, isPending: isLoadingInstalled } = useInstalledFeatures(assetId);

  const { data: subscriptions = [], isPending: isLoadingSubscriptions } = useSubscriptionsByFeatureQuery(
    subscriptionFeatures,
    assetId
  );

  const installedFeatures = filterFeaturesWithError(installedFeaturesData);
  const failedInstallations = getFailedInstallationList(installedFeaturesData, intl);

  const isStillLoading =
    isLoadingVehicleDetails || isLoadingInstallable || isLoadingInstalled || isLoadingSubscriptions;

  return (
    <VehicleDetailContext.Provider
      value={{
        vehicleDetails: vehicleDetails!,
        isLoadingVehicleDetails,
        installableFeatures: installableFeatures!,
        isLoadingInstallable,
        installedFeatures,
        isLoadingInstalled,
        subscriptions,
        isLoadingSubscriptions,
        activeFilters,
        setActiveFilters,
        failedInstallations,
      }}
    >
      <AsyncContentWrapper
        showNotFound={isEmpty(vehicleDetails) && !isLoadingVehicleDetails}
        isLoading={isStillLoading}
      >
        {children}
      </AsyncContentWrapper>
    </VehicleDetailContext.Provider>
  );
};

export default VehicleDetailProvider;
