import { FormattedMessage } from 'react-intl';

import { TNewsProps } from '@/features/types';

import FormattedHTMLMessage from '@/components/FormattedHTMLMessage';
import HeaderImage from '@/features/dialogs/WhatsNew/HeaderImage';

const BulkBookingNews = ({ articleRef }: TNewsProps) => (
  <>
    <HeaderImage fileName="bulk-booking-news.png" />
    <article ref={articleRef}>
      <FormattedMessage id="mannow.whatIsNew.bulkBooking.title" tagName="h4" />

      <FormattedHTMLMessage id="mannow.whatIsNew.bulkBooking.body" />
    </article>
  </>
);

export default BulkBookingNews;
