import isEmpty from 'lodash/isEmpty';
import { Suspense, lazy } from 'react';
import { IntlProvider } from 'react-intl';
import { Outlet, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { TBannerProps, TRenderAppProps } from './types';

import { config } from '@/config';
import { DEFAULT_LOCALE } from '@/configuration/lang/lang';
import { loadGoogleTagManager } from '@/configuration/setup/googleAnalytics';

import { StorageEnum } from '@/enums/StorageEnum';

import useFeaturesMaintenance from '@/hooks/data/useFeaturesMaintenance';
import useRecommendedFeatureQuery from '@/hooks/queries/recommendationEngine/useRecommendedFeatureQuery';
import useServiceOutageQuery from '@/hooks/queries/userManager/useServiceOutageQuery';
import useTermsAndConditionsStatusQuery from '@/hooks/queries/userManager/useTermsAndConditionsStatusQuery';
import useAddPreloadedLinks from '@/hooks/utils/useAddPreloadedLinks';
import useReactQueryDefaultOptions from '@/hooks/utils/useReactQueryDefaultOptions';
import useRouterUtils from '@/hooks/utils/useRouterUtils';
import useSendGAEventOnce from '@/hooks/utils/useSendGAEventOnce';
import useTranslation from '@/hooks/utils/useTranslation';
import { useUserDataContext } from '@/providers/hooks';

import DefaultRedirect from '@/routes/DefaultRedirect';

import BackSoon from '@/components/errors/BackSoonError';
import ForbiddenError from '@/components/errors/ForbiddenError';
import { AppGlobalStyles } from '@/components/ui-kit/AppGlobalStyles';
import AppHeader from '@/features/layout/AppHeader';
import MaintenanceBanner from '@/features/layout/MaintenanceBanner';
import CustomSidebar from '@/features/layout/Sidebar';
import UserTermsAndConditions from '@/features/layout/UserTermsAndConditions';
import ServiceOutageBanner from '@/features/serviceOutage/Banner';
import ServiceOutageSheet from '@/features/serviceOutage/BottomSheet';

// @TODO: Investigate whether we still need this
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

import theme from '@/theme';

import { AVAILABLE_FEATURES_ROUTE, MY_FLEET_ROUTE } from '@/routes';

import { FluidContainer } from './styled';

const DialogsContainer = lazy(() => import('@/features/dialogs/DialogsContainer'));

const RenderApp = ({ hasAccess, showTCs, isBackSoonEnabled }: TRenderAppProps) => {
  if (hasAccess) {
    if (isBackSoonEnabled) {
      return <BackSoon />;
    }

    if (showTCs) {
      return <UserTermsAndConditions />;
    }

    return (
      <FluidContainer>
        <Outlet />
        <DefaultRedirect />
      </FluidContainer>
    );
  }

  return <ForbiddenError />;
};

const Banner = ({ featuresInMaintenance }: TBannerProps) => {
  const { pathname } = useLocation();

  if (config.showWarningMessage) {
    return <MaintenanceBanner />;
  }

  if (
    featuresInMaintenance.length > 0 &&
    (pathname.startsWith(MY_FLEET_ROUTE) || pathname.startsWith(AVAILABLE_FEATURES_ROUTE))
  ) {
    return <ServiceOutageBanner />;
  }

  return null;
};

const AppLayout = () => {
  useReactQueryDefaultOptions();
  useRecommendedFeatureQuery();
  useAddPreloadedLinks();

  const { pathname } = useLocation();
  const { shouldShowSidebar } = useRouterUtils();

  const { locale, messages } = useTranslation();
  const { isSessionExpired, isSessionExpiredAcknowledged, setSessionExpiredAcknowledged, hasAccess } =
    useUserDataContext();

  const { data: hasAcceptedTCs, isPending: isLoadingHasAccepted } = useTermsAndConditionsStatusQuery();
  const { data: featuresInMaintenance, isPending: isLoadingFeaturesMaintenance } = useFeaturesMaintenance();
  const { data: serviceOutage, isPending: isLoadingServiceOutage } = useServiceOutageQuery();

  useSendGAEventOnce(pathname !== '/', () => {
    const isTestUser = JSON.parse(sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_FROM_TEST_FLEET) || 'false');
    const isGADebuggerEnabled = JSON.parse(localStorage.getItem(StorageEnum.STORAGE_KEY_GA_DEBUGGER) || 'false');

    if (!isTestUser || isGADebuggerEnabled) {
      // We are initializing GA here to make sure the lading page is not '/'
      loadGoogleTagManager();
    }
  });

  if (!locale || isEmpty(messages)) {
    return null;
  }

  const handleSessionExpiredDialogClose = () => setSessionExpiredAcknowledged(true);

  const isStillLoading = isLoadingHasAccepted || isLoadingFeaturesMaintenance;

  const showSessionExpired = isSessionExpired && !isSessionExpiredAcknowledged;
  const showTermsConditions =
    hasAcceptedTCs?.accepted !== undefined && !hasAcceptedTCs?.accepted && !isLoadingHasAccepted;
  const hideMenus = isStillLoading || !hasAccess || !hasAcceptedTCs?.accepted || config.isBackSoonEnabled;

  const showServiceOutage =
    (featuresInMaintenance.length > 0 && !serviceOutage && !isLoadingServiceOutage) ||
    (serviceOutage?.lastUpdatedDate &&
      featuresInMaintenance.some((feature) => feature?.lastUpdatedDate > serviceOutage?.lastUpdatedDate) &&
      (pathname.startsWith(MY_FLEET_ROUTE) || pathname.startsWith(AVAILABLE_FEATURES_ROUTE)));

  return (
    <ThemeProvider theme={theme}>
      <AppGlobalStyles />
      <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={messages}>
        <ApplicationLayout data-testid="app-layout">
          <ApplicationLayout.Header>
            <AppHeader hideMenu={hideMenus} />
          </ApplicationLayout.Header>

          {shouldShowSidebar && <CustomSidebar />}

          <ApplicationLayout.Body banner={<Banner featuresInMaintenance={featuresInMaintenance} />}>
            <NotificationsContainer />
            <SessionExpiredDialog locale={locale} onClose={handleSessionExpiredDialogClose} show={showSessionExpired} />

            <Suspense fallback={<></>}>
              <DialogsContainer />
            </Suspense>

            {showServiceOutage && <ServiceOutageSheet featuresInMaintenance={featuresInMaintenance} />}

            {isStillLoading ? (
              <Spinner />
            ) : (
              <RenderApp
                hasAccess={hasAccess}
                showTCs={showTermsConditions}
                isBackSoonEnabled={config.isBackSoonEnabled}
              />
            )}
          </ApplicationLayout.Body>
        </ApplicationLayout>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default AppLayout;
