export const enum RegionEnum {
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  E4 = 'E4',
  E5 = 'E5',
  E6 = 'E6',
  E7 = 'E7',
  E8 = 'E8',
  E9 = 'E9',
  EA = 'EA',
  EB = 'EB',
  EC = 'EC',
  ED = 'ED',
  EE = 'EE',
  EF = 'EF',
  FULL_UPDATE = 'FU',
}
