/** For extra information, see: https://collaboration.msi.audi.com/confluence/display/MANOD/Google+Analytics */
import { TGAEventsFunctions } from '@/configuration/types';
import { TOrderSubmitItem } from '@/services/types';

import { TRACKING_EVENTS, gaPushEvent, gaPushPageView } from '@/configuration/setup/googleAnalytics';

import { GAActionEnum } from '@/enums/GoogleAnalyticsEnum';

export const GaEvents: TGAEventsFunctions = {
  pageView: (page, title) => gaPushPageView(page, title),
  bulkBookingInteractions: (label, action) =>
    gaPushEvent({
      ...TRACKING_EVENTS.BULK_BOOKING_INTERACTIONS,
      action: action || TRACKING_EVENTS.BULK_BOOKING_INTERACTIONS.action,
      label,
    }),
  buttonInteractions: (label) => gaPushEvent({ ...TRACKING_EVENTS.BUTTON_INTERACTIONS, label }),
  carouselInteractions: (label) => gaPushEvent({ ...TRACKING_EVENTS.CAROUSEL_INTERACTIONS, label }),
  dialogInteractions: (label) => gaPushEvent({ ...TRACKING_EVENTS.DIALOG_INTERACTIONS, label }),
  expanderInteractions: (label, isOpen) =>
    gaPushEvent({
      ...TRACKING_EVENTS.EXPANDER_INTERACTIONS,
      action: isOpen ? GAActionEnum.OPEN : GAActionEnum.CLOSE,
      label,
    }),
  filterInteractions: (context, field, value) =>
    gaPushEvent({
      ...TRACKING_EVENTS.FILTER_INTERACTIONS,
      action: field === 'search' ? GAActionEnum.TYPE : GAActionEnum.CLICK,
      label: `${context}::${field}::${value}`,
    }),
  linkInteractions: (label) => gaPushEvent({ ...TRACKING_EVENTS.LINK_INTERACTIONS, label }),
  notificationInteractions: (label) => gaPushEvent({ ...TRACKING_EVENTS.NOTIFICATION_INTERACTIONS, label }),
  prepaidInstallationInteractions: (context, label) =>
    gaPushEvent({ ...TRACKING_EVENTS.PREPAID_INSTALLATION_INTERACTIONS, label: `${context}::${label}` }),
  recommendedFeatureInteractions: (label, action) =>
    gaPushEvent({
      ...TRACKING_EVENTS.RECOMMENDED_FEATURE_INTERACTIONS,
      action: action || TRACKING_EVENTS.RECOMMENDED_FEATURE_INTERACTIONS.action,
      label,
    }),
  specificationsDownload: (featureCode) =>
    gaPushEvent({ ...TRACKING_EVENTS.SERVICE_SPECIFICATIONS_DOWNLOAD, label: featureCode }),
  videoInteractions: (label, action) => gaPushEvent({ ...TRACKING_EVENTS.VIDEO_INTERACTIONS, action, label }),
  vehiclesCOREInteractions: (label, action) =>
    gaPushEvent({
      ...TRACKING_EVENTS.VEHICLES_CORE_INTERACTIONS,
      action: action || TRACKING_EVENTS.VEHICLES_CORE_INTERACTIONS.action,
      label,
    }),
};

export const sendRecommendationOrderInfoToGA = (ordersToCreate: TOrderSubmitItem[]) =>
  ordersToCreate.forEach(({ order_features }) => {
    order_features.forEach(({ additional_properties, feature_code }) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { recommendation_origin, recommendation_at_order, recommendation_at_shopping_cart } = additional_properties;

      if (recommendation_origin) {
        let context = `${feature_code}::${recommendation_origin}`;

        if (recommendation_at_shopping_cart) {
          context += '::atShoppingCart';
        }

        if (recommendation_at_order) {
          context += '::atOrder';
        }

        GaEvents.recommendedFeatureInteractions(context, GAActionEnum.ORDER);
      }
    });
  });

export const sendOrderWasStartedOnBulkBookingToGA = (ordersToCreate: TOrderSubmitItem[]) =>
  ordersToCreate.forEach(({ order_features }) => {
    order_features.forEach(({ additional_properties, feature_code }) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { is_from_bulk_booking } = additional_properties;

      if (is_from_bulk_booking) {
        GaEvents.bulkBookingInteractions(feature_code, GAActionEnum.ORDER);
      }
    });
  });
