import * as Sentry from '@sentry/react';
import { lazy } from 'react';
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import useDefaultLandingPage from '@/hooks/utils/useDefaultLandingPage';
import useToggles from '@/hooks/utils/useToggles';

import AppLayout from '@/layout/AppLayout';

import { RouteErrorBoundary } from '@/components/ErrorBoundary';
import SuspendedWithSpinner from '@/components/SuspendedWithSpinner';
import ForbiddenError from '@/components/errors/ForbiddenError';
import GenericError from '@/components/errors/GenericError';
import NotFoundError from '@/components/errors/NotFoundError';
import PageLoader from '@/components/ui-kit/PageLoader';

import {
  AVAILABLE_FEATURES_ROUTE,
  CHECKOUT_ROUTE,
  FEATURE_DETAILS_ROUTE,
  FEATURE_INSTALLABLE_ROUTE,
  FEATURE_INSTALLED_ROUTE,
  FORBIDDEN_ERROR_ROUTE,
  GENERIC_ERROR_ROUTE,
  INSTALLATION_MONITOR_DETAILS_ROUTE,
  INSTALLATION_MONITOR_ROUTE,
  MY_FLEET_ROUTE,
  NOT_FOUND_ROUTE,
  ORDERS_ROUTE,
  ORDER_DETAILS_ROUTE,
  SHOPPING_CART_ROUTE,
  VEHICLE_FEATURE_DETAILS_ROUTE,
  VEHICLE_FEATURE_INSTALLABLE_ROUTE,
  VEHICLE_FEATURE_INSTALLED_ROUTE,
  VEHICLE_INSTALLABLE_FEATURES_ROUTE,
  VEHICLE_INSTALLED_FEATURES_ROUTE,
  VEHICLE_MY_CURRENT_MAPS_ROUTE,
} from '.';

const MyFleet = lazy(() => import('@/pages/MyFleet'));
const AvailableFeatures = lazy(() => import('@/pages/AvailableFeatures'));
const InstallationMonitor = lazy(() => import('@/pages/InstallationMonitor'));
const FeatureDetails = lazy(() => import('@/pages/FeatureDetails'));
const Orders = lazy(() => import('@/pages/Orders'));
const ShoppingCart = lazy(() => import('@/pages/ShoppingCart'));
const Checkout = lazy(() => import('@/pages/Checkout'));

const MY_FLEET_ROUTES = [
  MY_FLEET_ROUTE,
  VEHICLE_INSTALLABLE_FEATURES_ROUTE,
  VEHICLE_INSTALLED_FEATURES_ROUTE,
  VEHICLE_FEATURE_DETAILS_ROUTE,
  VEHICLE_FEATURE_INSTALLABLE_ROUTE,
  VEHICLE_FEATURE_INSTALLED_ROUTE,
  VEHICLE_MY_CURRENT_MAPS_ROUTE,
];

const FEATURE_DETAILS_ROUTES = [FEATURE_DETAILS_ROUTE, FEATURE_INSTALLABLE_ROUTE, FEATURE_INSTALLED_ROUTE];

const ORDERS_ROUTES = [ORDERS_ROUTE, ORDER_DETAILS_ROUTE];

const INSTALLATION_MONITOR_ROUTES = [INSTALLATION_MONITOR_ROUTE, INSTALLATION_MONITOR_DETAILS_ROUTE];

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = (defaultLandingPage: string, toggleInstallationMonitor: boolean) =>
  sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<AppLayout />}>
        <Route path="*" element={<Navigate to={defaultLandingPage} />} />
        {MY_FLEET_ROUTES.map((route, index) => (
          <Route
            key={index}
            path={route}
            errorElement={<RouteErrorBoundary />}
            element={
              <SuspendedWithSpinner>
                <MyFleet />
              </SuspendedWithSpinner>
            }
          />
        ))}
        <Route
          path={AVAILABLE_FEATURES_ROUTE}
          errorElement={<RouteErrorBoundary />}
          element={
            <SuspendedWithSpinner>
              <AvailableFeatures />
            </SuspendedWithSpinner>
          }
        />
        {toggleInstallationMonitor &&
          INSTALLATION_MONITOR_ROUTES.map((route, index) => (
            <Route
              key={index}
              path={route}
              errorElement={<RouteErrorBoundary />}
              element={
                <SuspendedWithSpinner>
                  <InstallationMonitor />
                </SuspendedWithSpinner>
              }
            />
          ))}
        {FEATURE_DETAILS_ROUTES.map((route, index) => (
          <Route
            key={index}
            path={route}
            errorElement={<RouteErrorBoundary />}
            element={
              <SuspendedWithSpinner>
                <FeatureDetails />
              </SuspendedWithSpinner>
            }
          />
        ))}
        {ORDERS_ROUTES.map((route, index) => (
          <Route
            key={index}
            path={route}
            errorElement={<RouteErrorBoundary />}
            element={
              <SuspendedWithSpinner>
                <Orders />
              </SuspendedWithSpinner>
            }
          />
        ))}
        <Route
          path={SHOPPING_CART_ROUTE}
          errorElement={<RouteErrorBoundary />}
          element={
            <SuspendedWithSpinner>
              <ShoppingCart />
            </SuspendedWithSpinner>
          }
        />
        <Route
          path={CHECKOUT_ROUTE}
          errorElement={<RouteErrorBoundary />}
          element={
            <SuspendedWithSpinner>
              <Checkout />
            </SuspendedWithSpinner>
          }
        />
        <Route
          path={GENERIC_ERROR_ROUTE}
          errorElement={<RouteErrorBoundary />}
          element={
            <SuspendedWithSpinner>
              <GenericError />
            </SuspendedWithSpinner>
          }
        />
        <Route
          path={FORBIDDEN_ERROR_ROUTE}
          errorElement={<RouteErrorBoundary />}
          element={
            <SuspendedWithSpinner>
              <ForbiddenError />
            </SuspendedWithSpinner>
          }
        />
        <Route
          path={NOT_FOUND_ROUTE}
          errorElement={<RouteErrorBoundary />}
          element={
            <SuspendedWithSpinner>
              <NotFoundError />
            </SuspendedWithSpinner>
          }
        />
      </Route>
    )
  );

const AppRouterProvider = () => {
  const toggles = useToggles();
  const defaultLandingPage = useDefaultLandingPage();

  return toggles.isPending ? (
    <PageLoader />
  ) : (
    <RouterProvider router={router(defaultLandingPage!, toggles.installationMonitorToggle)} />
  );
};

export default AppRouterProvider;
