import {
  TFeatureNotificationRequest,
  TFeedbackRequest,
  TServiceOutageResponse,
  TTermsAndConditionsStatus,
} from './types';

import { config } from '@/config';
import { fetchData, postData, putData } from '@/services/config/common';

export const fetchTermsAndConditionStatus = (): Promise<TTermsAndConditionsStatus> =>
  fetchData(`${config.backend.MANNOW_UM}/tc/status`);

export const updateTermsAndConditionStatus = () => putData(`${config.backend.MANNOW_UM}/tc/status`);

export const fetchServiceOutageStatus = (): Promise<TServiceOutageResponse> =>
  fetchData(`${config.backend.MANNOW_UM}/service-outage/status`, {
    isAllowed404Response: true,
  });

export const updateServiceOutageStatus = () => putData(`${config.backend.MANNOW_UM}/service-outage/status`);

export const submitFeedback = (feedback: TFeedbackRequest) =>
  postData(`${config.backend.MANNOW_UM}/feedbacks`, feedback, { expectedResponseBody: false });

export const fetchFeatureNotificationStatus = (featureCode: TFeatureCodeEnum): Promise<boolean> =>
  fetchData(`${config.backend.MANNOW_UM}/feature-notifications/${featureCode}`);

export const submitFeatureNotification = (featureNotification: TFeatureNotificationRequest) =>
  postData(`${config.backend.MANNOW_UM}/feature-notifications`, featureNotification, { expectedResponseBody: false });
