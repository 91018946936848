import styled from 'styled-components';

import { TBadgeProps } from '@/components/types';

import { classNames } from '@/helpers/utils';

export const Badge = styled.span.attrs(
  ({ $isInfo, $isSuccess, $isWarning, $isDanger, $isIndicator, $isPulsing, $isMuted }: TBadgeProps) => ({
    className: classNames({
      badge: true,
      'badge-info': $isInfo,
      'badge-success': $isSuccess,
      'badge-warning': $isWarning,
      'badge-danger': $isDanger,
      'badge-indicator': $isIndicator,
      'badge-indicator-pinging': $isPulsing,
      'badge-muted': $isMuted,
    }),
  })
)<TBadgeProps>``;
