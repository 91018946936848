import { FormattedMessage } from 'react-intl';

import { TAssetVehicle, TFeatureDetails } from '@/hooks/types';
import { TCustomError } from '@/services/types';

import { config, queryClient } from '@/config';

import { HTTPStatusEnum } from '@/enums/HTTPStatusEnum';
import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import Notification from '@rio-cloud/rio-uikit/Notification';

class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message || '401 Unauthorized response');
    this.name = UnauthorizedError.name;
  }
}

class ForbiddenError extends Error {
  constructor(message: string) {
    super(message || '403 Forbidden response');
    this.name = ForbiddenError.name;
  }
}

export class FetchError extends Error {
  constructor(message: string) {
    super(message);
    this.name = FetchError.name;
  }
}

export const getErrorInstance = (error: TCustomError) => {
  switch (+error.status) {
    case HTTPStatusEnum.UNAUTHORIZED:
      return new UnauthorizedError(error.statusText);
    case HTTPStatusEnum.FORBIDDEN:
      return new ForbiddenError(error.statusText);
    default:
      return new FetchError(error.error || error.message);
  }
};

export const showErrorNotification = (err: TCustomError) => {
  if (config.serviceEnvironment === 'production') {
    console.error(err);
  }

  if (Object.keys(err).length === 0) {
    Notification.error(<FormattedMessage id="mannow.errorHandling.networkError" />);
    return;
  }

  if (err.status) {
    if (err.status === HTTPStatusEnum.CONFLICT) {
      const assetVehiclesList: TAssetVehicle[] = queryClient.getQueryData([QueryIdentifierEnum.ASSET_VEHICLES]) || [];
      const featureList: TFeatureDetails[] = queryClient.getQueryData([QueryIdentifierEnum.FEATURES_DETAILS]) || [];

      const featureListTitle = featureList
        .filter(({ code }) => err.resources.includes(code))
        ?.map(({ title }) => title);

      const vehicleName = assetVehiclesList.find((v) => v.id === err.identifier)?.name || err.identifier;
      const featuresNames = featureListTitle.length > 0 ? featureListTitle : err.resources;

      Notification.warning(
        <FormattedMessage
          id={err.error}
          values={{
            identifier: <b>{vehicleName}</b>,
            resources: <b>{featuresNames.join(',')}</b>,
          }}
        />,
        '',
        9999999
      );
    } else if (err.message && err.message !== '') {
      Notification.error(<FormattedMessage id={err.message} />);
    } else {
      Notification.error(<FormattedMessage id="mannow.errorHandling.httpError" values={{ errorCode: err.status }} />);
    }

    return;
  }

  Notification.error(<FormattedMessage id="mannow.errorHandling.unknownError" />);
};

export const customException = (err: TCustomError) => ({
  status: err.status ? err.status : '',
  resources: err.resources ? err.resources : [],
  error: err.error ? err.error : 'mannow.errorHandling.unknownError',
  message: err.message ? err.message : '',
  context: err.context,
  identifier: err.identifier,
  // @ts-expect-error Only Marketplace Bookings/Contracts returns this info
  title: err.title,
  // @ts-expect-error Only Marketplace Bookings/Contracts returns this info
  detail: err.detail,
});

export const processError = (err: TCustomError) => {
  if (!err || err.status !== HTTPStatusEnum.FORBIDDEN) {
    showErrorNotification(err);
  }

  throw customException(err);
};
