import cloneDeep from 'lodash/cloneDeep';
import { IntlShape } from 'react-intl';

import { TColumn } from '../types';
import { TBundleCode } from '@/data/types';
import { TEnrichedMapUpdateRegion, TMapUpdateStatusInfo, TRegion } from '@/features/types';
import { TSubscription, TSubscriptionPlan } from '@/hooks/types';

import { CountryEnum } from '@/enums/CountryEnum';
import { FeatureCodeEnum } from '@/enums/FeatureCodeEnum';
import { SortFieldEnum } from '@/enums/SortFieldEnum';
import { RegionStatusEnum } from '@/enums/StatusEnum';
import { MapUpdatePackageEnum } from '@/enums/SubscriptionCodesEnum';

import { codesPerRegionBundle, mapUpdateRegions } from '@/data/mapUpdateRegions';

export const getRegionByCode = (code: string) => mapUpdateRegions.find((item) => item.region === code);

export const handleNotActiveStatus = (status: TRegionsStatusEnum) =>
  status === RegionStatusEnum.DEPRECATED
    ? 'mannow.myCurrentMaps.notAvailableViaMannow'
    : 'mannow.myCurrentMaps.notSubscribed';

export const handleTableConfig = (
  maps: TMapUpdateStatusInfo | undefined,
  subscriptions: TSubscription[],
  intl: IntlShape
) => {
  const mapsColumns: TColumn[] = [
    {
      sortField: SortFieldEnum.REGION,
      labelKey: 'mannow.myCurrentMaps.regions',
    },
    {
      sortField: SortFieldEnum.INSTALLATION_DATE,
      labelKey: 'mannow.myCurrentMaps.version',
    },
    {
      sortField: SortFieldEnum.SUBSCRIPTION_STATUS,
      labelKey: 'mannow.myCurrentMaps.subscriptionStatus',
    },
  ];

  const subscription = subscriptions.find(
    (subs) => maps?.assetId === subs.assetId && subs.subscriptionPlan.featureCode === FeatureCodeEnum.MAP_UPDATE
  );

  const mapsRows = maps?.regions?.map((region) => {
    const phraseKey = getRegionByCode(region.region)?.phraseKey || '';

    return {
      ...region,
      endDate: new Date(subscription?.endDate || ''),
      region: intl.formatMessage({ id: phraseKey }),
      subscriptionStatus: region.active ? 'mannow.myCurrentMaps.active' : handleNotActiveStatus(region.status),
    };
  });

  return { mapsColumns, mapsRows };
};

export const enrichRegions = (regionsData: TRegion[]): TEnrichedMapUpdateRegion[] =>
  cloneDeep(mapUpdateRegions).map((mapUpdateRegion) => {
    const matchedRegion = regionsData.find((regionData) => regionData.region === mapUpdateRegion.region);
    return {
      ...mapUpdateRegion,
      ...matchedRegion,
    };
  });

export const isAnyRegionDeprecated = (regionsData: TEnrichedMapUpdateRegion[] | TRegion[]): boolean =>
  regionsData?.some(({ status }) => status === RegionStatusEnum.DEPRECATED);

export const isRegionDeprecated = (regionsData: TRegion[], regionCode: string): boolean =>
  regionsData?.some(({ status, region }) => region === regionCode && status === RegionStatusEnum.DEPRECATED);

export const getRegionByCountryCode = (countryIsoCode: CountryEnum) => {
  const currRegion = mapUpdateRegions.find((item) => item.codes.includes(countryIsoCode));
  return currRegion ? currRegion.region : '';
};

export const getPackageByCountryCode = (countryIsoCode: CountryEnum) => {
  const region = getRegionByCountryCode(countryIsoCode);
  return Object.keys(codesPerRegionBundle).find((item) =>
    codesPerRegionBundle[item as TBundleCode].codes.includes(region)
  );
};

export const calculatePrices = (plans: TSubscriptionPlan[], regionCode: string) => {
  const selectionRegionPrice = plans.find(({ code }) => code === regionCode);
  return selectionRegionPrice ? selectionRegionPrice.price.amount : 0;
};

export const getPackageByRegion = (region: string) =>
  Object.keys(codesPerRegionBundle).find((item) => codesPerRegionBundle[item as TBundleCode].codes.includes(region));

export const getRegionBundleCodeFromPackageAndRegion = (pkg: string, region: string) =>
  region || (pkg ? 'PRO' : undefined);

export const getCountryCodesByRegionBundle = (selectedRegion: string) =>
  codesPerRegionBundle[selectedRegion as TBundleCode]?.codes || [];

export const getSelectedRegionPackage = (value: string) => {
  let regionPackage = MapUpdatePackageEnum.PACKAGE_PRO;

  if (getRegionByCode(value)) {
    regionPackage = MapUpdatePackageEnum.PACKAGE_SINGLE;
  } else if (codesPerRegionBundle[value as TBundleCode]) {
    regionPackage = MapUpdatePackageEnum.PACKAGE_REGIONS;
  }

  return regionPackage;
};
