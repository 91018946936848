import styled, { css } from 'styled-components';

export const FeatureInfoWrapper = styled.div`
  ${({ theme }) => {
    const { spaces, fonts } = theme;
    return css`
      display: flex;
      flex-direction: row;
      gap: ${spaces.extraSmall};
      font-size: ${fonts.size.normal};
    `;
  }}
`;

export const FeatureInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    font-weight: initial;
  }

  .tag {
    width: fit-content;
  }
`;

export const FeatureLabel = styled.span.attrs({
  'data-testid': 'feature-name',
})`
  ${({ theme }) => {
    const { colors, fonts } = theme;

    return css`
      color: ${colors.grayDarkest};
      font-size: ${fonts.size.normal};
    `;
  }}
`;

export const FeatureAdditionalInfo = styled.span`
  ${({ theme }) => {
    const { colors, fonts, breakpoints } = theme;
    return css`
      color: ${colors.gray};
      font-size: ${fonts.size.small};
      line-height: ${fonts.lineHeight.normalToWide};

      @media screen and (max-width: ${breakpoints.xtrasmall}) {
        display: none;
      }
    `;
  }}
`;
