import { datadogLogs } from '@datadog/browser-logs';

import { config } from '@/config';

export const initDatadog = (isFromTestFleet: boolean) => {
  if (import.meta.env.PROD && config.datadogClientToken && !isFromTestFleet) {
    datadogLogs.init({
      clientToken: config.datadogClientToken,
      site: 'datadoghq.eu',
      env: 'prod',
      service: config.serviceName,
    });
    datadogLogs.logger.setContext({
      account_id: config.datadogTagAwsAccountId,
      team_identifier: config.datadogTagTeamIdentifier,
      account_name: config.datadogTagAwsAccountName,
    });
  }
};
