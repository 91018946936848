import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { StorageEnum } from '@/enums/StorageEnum';

const useReactQueryDefaultOptions = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Setting this globally so we can redirect the user when React Query triggers onError
  window.routerNavigate = (route: string) => navigate(route);

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        gcTime: 0,
        staleTime: Infinity,
        retryDelay: 600,
        refetchOnWindowFocus: sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_HAS_ACCESS) === 'true',
      },
    });
  }, [queryClient]);

  return queryClient;
};

export default useReactQueryDefaultOptions;
