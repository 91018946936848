import dayjs from 'dayjs';

import { TVehicleEligiblePlans } from '@/hooks/types';

import { TagEnum } from '@/enums/TagEnum';

const reverseFeatureOrderByTag = [
  TagEnum.MAINTENANCE,
  TagEnum.DEFAULT,
  TagEnum.DISCOUNT,
  TagEnum.NEW,
  TagEnum.COMING_SOON,
  TagEnum.PREPAID,
];

/** This helper only consider the feature tag */
export const isFeatureAvailable = (tag: TTagEnum) => tag !== TagEnum.COMING_SOON;

/** This helper only consider the feature tag */
export const isFeatureInMaintenance = (tag: TTagEnum) => tag === TagEnum.MAINTENANCE;

export const sortFeaturesByTag = <T extends TFeature | TVehicleEligiblePlans>(features: T[] = []) => {
  const isVehicleEligiblePlans = (item: TFeature | TVehicleEligiblePlans): item is TVehicleEligiblePlans =>
    (item as TVehicleEligiblePlans).feature !== undefined;

  return features.sort((f1, f2) => {
    const tag1 = isVehicleEligiblePlans(f1) ? f1.feature.tag : f1.tag;
    const tag2 = isVehicleEligiblePlans(f2) ? f2.feature.tag : f2.tag;
    const title1 = isVehicleEligiblePlans(f1) ? f1.feature.title : f1.title;
    const title2 = isVehicleEligiblePlans(f2) ? f2.feature.title : f2.title;

    return (
      reverseFeatureOrderByTag.indexOf(tag2) - reverseFeatureOrderByTag.indexOf(tag1) || title1.localeCompare(title2)
    );
  });
};

export const getFeatureTag = (date: Date): TTagEnum => {
  const currentDate = dayjs();
  const releaseDate = dayjs(date);
  const dayDifference = currentDate.diff(releaseDate, 'day');
  const tagNewDurationDays = 30;

  if (currentDate.isBefore(releaseDate)) {
    return TagEnum.COMING_SOON;
  } else if (dayDifference >= tagNewDurationDays) {
    return TagEnum.DEFAULT;
  } else {
    return TagEnum.NEW;
  }
};
