import { useQuery } from '@tanstack/react-query';

import { TSubscriptionPlan, TVehicleEligiblePlans } from '@/hooks/types';
import { TFeatureSubscriptionPlansResponse, TSubscriptionPlanResponse } from '@/services/types';

import { FeatureEligibilityEnum } from '@/enums/FeatureEligibilityEnum';
import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchFeatureSubscriptionPlans } from '@/services/subscriptionManager';

import useFeaturesDetailsQuery from '@/hooks/queries/cms/useFeaturesDetailsQuery';
import useVehicleFeaturesEligibilityQuery from '@/hooks/queries/vehicleManager/useVehicleFeaturesEligibilityQuery';

import { convertSnakeToCamelCase } from '@/helpers/dataTransformation';
import { getFeatureCode, groupFeatureWithVariants } from '@/helpers/feature';
import { getLowestPlan } from '@/helpers/featurePlans';
import { logNoPlansWarning } from '@/helpers/logging';

/** This helper will return an array with the available plans for a feature and also the lowest price */
const mapPlansAndLowestPrice = (
  featureCode: TFeatureAndVariantCodeEnum,
  plans: TFeatureSubscriptionPlansResponse[]
) => {
  const featurePlans = plans
    .filter(({ plan }) => getFeatureCode(plan.feature_code as TFeatureAndVariantCodeEnum) === featureCode)
    .map((marketplacePrice) =>
      convertSnakeToCamelCase<TSubscriptionPlanResponse, TSubscriptionPlan>(marketplacePrice.plan)
    );

  return {
    price: getLowestPlan(featurePlans)?.price?.amount,
    plans: featurePlans,
  };
};

const useInstallableFeaturesQuery = (assetId: string) => {
  const { data: vehicleFeatures, isSuccess: isVehicleFeaturesSuccess } = useVehicleFeaturesEligibilityQuery(
    assetId,
    FeatureEligibilityEnum.INSTALLABLE
  );
  const { data: featuresDetails, isSuccess: isFeaturesDetailsSuccess } = useFeaturesDetailsQuery();

  const vehicleFeaturesList = vehicleFeatures || [];

  return useQuery({
    queryKey: [QueryIdentifierEnum.INSTALLABLE_FEATURES, { assetId }],
    queryFn: () =>
      Promise.all(
        vehicleFeaturesList.map(({ feature }) => {
          return fetchFeatureSubscriptionPlans(feature.code, assetId);
        })
      ),
    enabled: isVehicleFeaturesSuccess && isFeaturesDetailsSuccess,
    select: (marketplacePrices) => {
      const featureList = groupFeatureWithVariants(vehicleFeaturesList, featuresDetails!);
      const response: TVehicleEligiblePlans[] = [];

      for (const { feature, variants } of featureList) {
        const item: TVehicleEligiblePlans = {
          feature: {
            ...feature,
            ...mapPlansAndLowestPrice(feature.code, marketplacePrices.flat()),
          },
          variants,
        };

        if (item?.feature?.plans?.length === 0) {
          logNoPlansWarning(assetId, feature.code);
        } else {
          response.push(item);
        }
      }

      return response;
    },
  });
};

export default useInstallableFeaturesQuery;
