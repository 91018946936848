export const enum CountryEnum {
  SPAIN = 'ES',
  FRANCE = 'FR',
  GREAT_BRITAIN = 'GB',
  IRELAND = 'IE',
  GERMANY = 'DE',
  DENMARK = 'DK',
  FINLAND = 'FI',
  ICELAND = 'IS',
  NORWAY = 'NO',
  SWEDEN = 'SE',
  AUSTRIA = 'AT',
  SWITZERLAND = 'CH',
  HUNGARY = 'HU',
  SLOVENIA = 'SI',
  CROATIA = 'HR',
  ESTONIA = 'EE',
  LITHUANIA = 'LT',
  LATVIA = 'LV',
  RUSSIA = 'RU',
  CZECH_REPUBLIC = 'CZ',
  SLOVAKIA = 'SK',
  POLAND = 'PL',
  CYPRUS = 'CY',
  ALBANIA = 'AL',
  BOSNIA_HERZEGOVINA = 'BA',
  BULGARIA = 'BG',
  GREECE = 'GR',
  MONTENEGRO = 'ME',
  MACEDONIA = 'MK',
  SERBIA = 'RS',
  KOSOVO = 'XK',
  ITALY = 'IT',
  PORTUGAL = 'PT',
  BELGIUM = 'BE',
  LUXEMBOURG = 'LU',
  NETHERLANDS = 'NL',
  BELARUS = 'BY',
  MOLDOVA = 'MD',
  ROMANIA = 'RO',
  UKRAINE = 'UA',
  ARMENIA = 'AM',
  AZERBAIJAN = 'AZ',
  GEORGIA = 'GE',
  MALTA = 'MT',
}
