import { Suspense, lazy, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import useRouterUtils from '@/hooks/utils/useRouterUtils';

import VehicleDetailProvider from '@/providers/VehicleDetailProvider';

import SuspendedWithSpinner from '@/components/SuspendedWithSpinner';

import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';

import { INSTALLATION_MONITOR_ROUTE, MY_FLEET_ROUTE, ORDERS_ROUTE } from '@/routes';

import { SidebarTitle, SidebarWrapper } from './styled';

const InstallationStatus = lazy(() => import('@/features/installationMonitor/InstallationStatus'));
const MyCurrentMaps = lazy(() => import('@/features/myCurrentMaps'));
const FeatureDetails = lazy(() => import('@/features/myFleet/FeatureDetails'));
const VehicleDetails = lazy(() => import('@/features/myFleet/VehicleDetails'));
const OrderDetail = lazy(() => import('@/features/orders/OrderDetail'));
const SidebarFooter = lazy(() => import('@/components/SidebarFooter'));

const SIDEBAR_DATA = {
  vehicleDetails: {
    title: <FormattedMessage id="mannow.phrase.vehicleDetail" />,
    body: (
      <VehicleDetailProvider>
        <VehicleDetails />
      </VehicleDetailProvider>
    ),
  },
  featureDetails: {
    title: <FormattedMessage id="mannow.phrase.featureDetail" />,
    body: <FeatureDetails />,
  },
  orderDetails: {
    title: <FormattedMessage id="mannow.phrase.orderDetail" />,
    body: <OrderDetail />,
  },
  myCurrentMaps: {
    title: <FormattedMessage id="mannow.phrase.myCurrentMaps" />,
    body: <MyCurrentMaps />,
  },
  installationMonitor: {
    title: <FormattedMessage id="mannow.phrase.installationStatus" />,
    body: <InstallationStatus />,
  },
};

const CustomSidebar = () => {
  const bodyRef = useRef<HTMLInputElement>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const { isVehicleFeatureDetails, isOrderDetails, isMyCurrentMaps, isInstallationMonitor } = useRouterUtils();

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
    }
  }, [location.pathname]);

  const closeDetailSidebar = () =>
    navigate(isInstallationMonitor ? INSTALLATION_MONITOR_ROUTE : isOrderDetails ? ORDERS_ROUTE : MY_FLEET_ROUTE);

  let sidebar = SIDEBAR_DATA.vehicleDetails;

  if (isVehicleFeatureDetails) {
    sidebar = SIDEBAR_DATA.featureDetails;
  } else if (isOrderDetails) {
    sidebar = SIDEBAR_DATA.orderDetails;
  } else if (isMyCurrentMaps) {
    sidebar = SIDEBAR_DATA.myCurrentMaps;
  } else if (isInstallationMonitor) {
    sidebar = SIDEBAR_DATA.installationMonitor;
  }

  return (
    <ApplicationLayout.Sidebar className="right">
      <Sidebar
        title={<SidebarTitle>{sidebar.title}</SidebarTitle>}
        onClose={closeDetailSidebar}
        footer={
          <Suspense fallback={<></>}>
            <SidebarFooter closeDetailSidebar={closeDetailSidebar} />
          </Suspense>
        }
        width={600}
        switchModeBreakpoint={isInstallationMonitor ? 1480 : 1280}
        bodyRef={bodyRef}
      >
        <SuspendedWithSpinner>
          <SidebarWrapper>{sidebar.body}</SidebarWrapper>
        </SuspendedWithSpinner>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};

export default CustomSidebar;
