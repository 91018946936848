import { createContext } from 'react';

import { TAvailableFeaturesContext, TBulkBookingContext, TUserDataContext, TVehicleDetailContext } from './types';

export const AvailableFeaturesContext = createContext<TAvailableFeaturesContext>({} as TAvailableFeaturesContext);
AvailableFeaturesContext.displayName = 'AvailableFeaturesContext';

export const UserDataContext = createContext<TUserDataContext>({} as TUserDataContext);
UserDataContext.displayName = 'UserDataContext';

export const VehicleDetailContext = createContext<TVehicleDetailContext>({} as TVehicleDetailContext);
VehicleDetailContext.displayName = 'VehicleDetailContext';

export const BulkBookingContext = createContext<TBulkBookingContext>({} as TBulkBookingContext);
BulkBookingContext.displayName = 'BulkBookingContext';
