export const enum FeatureStatusEnum {
  LIVE = 'LIVE',
  MAINTENANCE = 'MAINTENANCE',
}

export const enum SubscriptionStatusEnum {
  PENDING = 'PENDING_ACTIVATION',
  ACTIVE = 'ACTIVE',
}

export const enum InstallationStatusEnum {
  PENDING = 'pending',
  INSTALLED = 'installed',
  INSTALLABLE = 'installable',
  ERROR = 'error',
  CANCELLED = 'cancelled',
  UNINSTALLED = 'uninstalled',
  INSTALLING = 'installing',
  PENDING_ACTIVATION = 'pending_activation',
  PENDING_IN_ADVANCE_SUBSCRIPTION_INTENTION = 'pending_in_advance_subscription_intention',
}

export const enum OrderStatusEnum {
  ALL_CANCELLED = 'all_cancelled',
  ALL_INSTALLED = 'all_installed',
  ALL_PENDING = 'all_pending',
  ERROR = 'error',
  INSTALLED = 'installed',
  PENDING = 'pending',
}

export const enum OrderItemStatusEnum {
  ALL_FEATURES_CANCELLED = 'allFeaturesCancelled',
  ALL_FEATURES_INSTALLED = 'allFeaturesInstalled',
  ALL_FEATURES_PENDING = 'allFeaturesPending',
  AT_LEAST_ONE_FEATURE_ERRORED = 'atLeastOneFeatureErrored',
  AT_LEAST_ONE_FEATURE_INSTALLED = 'atLeastOneFeatureInstalled',
}

export const enum RegionStatusEnum {
  UPDATED = 'UPDATED',
  OUTDATED = 'OUTDATED',
  DEPRECATED = 'DEPRECATED',
}
