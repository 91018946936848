import { useQuery } from '@tanstack/react-query';

import { TFeatureStatus } from '@/hooks/types';
import { TFeatureStatusResponse } from '@/services/types';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchFeaturesStatus } from '@/services/featureManager';

import { convertSnakeToCamelCase } from '@/helpers/dataTransformation';

const useFeaturesStatusQuery = () =>
  useQuery({
    queryKey: [QueryIdentifierEnum.FEATURES_STATUS],
    queryFn: async () => {
      const fetchData = await fetchFeaturesStatus();

      return convertSnakeToCamelCase<TFeatureStatusResponse[], TFeatureStatus[]>(fetchData);
    },
    staleTime: 5000,
  });

export default useFeaturesStatusQuery;
