import { TFeatureVariants } from '@/data/types';
import { TFeatureInMaintenance } from '@/hooks/types';

import { FeatureStatusEnum } from '@/enums/StatusEnum';

import { featureGroups } from '@/data/featureDefinitions';

import useFeaturesStatusQuery from '@/hooks/queries/featureManager/useFeaturesStatusQuery';

const useFeaturesMaintenance = () => {
  const { data = [], isPending } = useFeaturesStatusQuery();

  const filteredFeatures = data.filter((feature) => feature.status === FeatureStatusEnum.MAINTENANCE);

  const featuresWithGroup: TFeatureInMaintenance[] = [];

  for (const feature of filteredFeatures) {
    featuresWithGroup.push({
      ...feature,
      featureGroupCode: featureGroups[feature.code as keyof TFeatureVariants],
    });
  }

  return { data: featuresWithGroup, isPending };
};

export default useFeaturesMaintenance;
