import useToggles from '@/hooks/utils/useToggles';

import { AVAILABLE_FEATURES_ROUTE, MY_FLEET_ROUTE } from '@/routes';

const useDefaultLandingPage = () => {
  const { availableFeaturesDefaultPageToggle, isPending } = useToggles();

  if (isPending) {
    return null;
  }

  return availableFeaturesDefaultPageToggle ? AVAILABLE_FEATURES_ROUTE : MY_FLEET_ROUTE;
};

export default useDefaultLandingPage;
