import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { updateServiceOutageStatus } from '@/services/userManager';

const useServiceOutageMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateServiceOutageStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryIdentifierEnum.FEATURES_STATUS] });
      queryClient.invalidateQueries({ queryKey: [QueryIdentifierEnum.SERVICE_OUTAGE] });
    },
  });
};

export default useServiceOutageMutation;
