import { config } from '@/config';

export const CAROUSEL_IMAGES_PATH = `${config.backend.MANNOW_CMS_CONTENT}/assets/dist/carousel`;

export const enum ImageFileNameEnum {
  MANNOW_INSTALLATION = 'MAN-Now-Installation_2.webp',
  MANNOW_WORKSHOP = 'MAN-Now-Workshop.webp',
  MANNOW_EFFICIENCY = 'MAN-Now-Efficiency_2.webp',
  MANNOW_INSTALLATION_MOBILE = 'MAN-Now-Installation-Mobile_2.webp',
  MANNOW_WORKSHOP_MOBILE = 'MAN-Now-Workshop-Mobile.webp',
  MANNOW_EFFICIENCY_MOBILE = 'MAN-Now-Efficiency-Mobile_2.webp',
}
