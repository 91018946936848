import { useQuery } from '@tanstack/react-query';

import { TVehicleFeaturesEligibility } from '@/hooks/types';
import { TVehicleDetailFeaturesContent } from '@/services/types';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchVehicleFeaturesByEligibility } from '@/services/vehicleManager';

import { convertSnakeToCamelCase } from '@/helpers/dataTransformation';

const useVehicleFeaturesEligibilityQuery = (assetId: string, featureType: TFeatureEligibilityEnum) => {
  return useQuery({
    queryKey: [QueryIdentifierEnum.VEHICLE_FEATURES, { assetId, featureType }],
    queryFn: async () => {
      if (assetId) {
        const response = await fetchVehicleFeaturesByEligibility(assetId, featureType);

        return convertSnakeToCamelCase<TVehicleDetailFeaturesContent[], TVehicleFeaturesEligibility[]>(
          response.content
        );
      }

      return null;
    },
  });
};

export default useVehicleFeaturesEligibilityQuery;
