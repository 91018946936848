import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { Icon, OpenServiceOutageSheetText, ServiceOutageBannerStyled, TextInfo, WarningText } from './styled';

const Banner = () => {
  const queryClient = useQueryClient();

  const openServiceOutageBottomSheet = (): void => {
    queryClient.setQueryData([QueryIdentifierEnum.SERVICE_OUTAGE], {
      last_updated_date: '1900-01-01T00:00:00.00000', // Set to a nullish date to display the bottom sheet again
    });
  };

  return (
    <ServiceOutageBannerStyled>
      <Icon iconName="warning-sign" />
      <WarningText>
        <FormattedMessage id="mannow.serviceOutageTopBanner.warningText" />
      </WarningText>
      <TextInfo>
        <FormattedMessage id="mannow.serviceOutageTopBanner.textInfo" />{' '}
        <OpenServiceOutageSheetText onClick={() => openServiceOutageBottomSheet()}>
          <FormattedMessage id="mannow.serviceOutageTopBanner.moreInfo" />
        </OpenServiceOutageSheetText>
      </TextInfo>
    </ServiceOutageBannerStyled>
  );
};

export default Banner;
