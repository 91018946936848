import { FormattedMessage } from 'react-intl';

import Notification from '@rio-cloud/rio-uikit/Notification';

export const sendNotification = (translationIdInfo: string, translationIdTitle: string, timeout?: 99999) =>
  Notification.warning(
    <FormattedMessage id={translationIdInfo} />,
    <FormattedMessage id={translationIdTitle} />,
    timeout
  );
