import styled from 'styled-components';

type TIconProps = {
  iconName: TIconName;
  classes?: string;
};

const Icon = styled.span.attrs(({ iconName, classes = '' }: TIconProps) => ({
  className: `icon rioglyph rioglyph-${iconName} ${classes}`,
  role: 'img',
}))<TIconProps>``;

export default Icon;
