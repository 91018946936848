import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { TCustomError, TTermsAndConditionsStatus } from '@/services/types';

import { HTTPStatusEnum } from '@/enums/HTTPStatusEnum';
import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';
import { StorageEnum } from '@/enums/StorageEnum';

import { fetchTermsAndConditionStatus } from '@/services/userManager';

import { useUserDataContext } from '@/providers/hooks';

const useTermsAndConditionsStatusQuery = () => {
  const { setHasAccess } = useUserDataContext();

  const data = useQuery<TTermsAndConditionsStatus, TCustomError>({
    queryKey: [QueryIdentifierEnum.TERMS_AND_CONDITIONS_STATUS],
    queryFn: fetchTermsAndConditionStatus,
  });

  useEffect(() => {
    if (data.isError) {
      // This is the first request we have, so we are setting hasAccess to false in case of a 403 error
      if (data.error?.status === HTTPStatusEnum.FORBIDDEN) {
        setHasAccess(false);
        sessionStorage.setItem(StorageEnum.STORAGE_KEY_USER_HAS_ACCESS, 'false');
      }
    }
  }, [data, data.error?.status, setHasAccess]);

  return data;
};

export default useTermsAndConditionsStatusQuery;
