import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import omitBy from 'lodash/omitBy';
import transform from 'lodash/transform';

export const convertSnakeToCamelCase = <
  TInput extends object = [Error, 'specify the original type'],
  TOutput = [Error, 'specify the returned type'],
  T extends TInput = TInput,
  Y extends TOutput = TOutput
>(
  obj: T
): Y =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(obj, (acc: any, value: any, key: string, target: any) => {
    const hasHyphen = isString(key) ? /(-)/.test(key) : false;

    const camelKey = isArray(target) || hasHyphen ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? convertSnakeToCamelCase<T, Y>(value as T) : value;
  });

export const removeFalsyProps = <T extends object>(obj: T) => omitBy(obj as T, isNil) as T;

export const removeEmptyProps = <T extends object>(obj: T) => omitBy(obj as T, isEmpty) as T;
