/**
 * For extra information, see:
 * https://collaboration.msi.audi.com/confluence/display/MANOD/Google+Analytics
 */
import { TGADataLayer, TGAECommercePayload, TGAEvent } from '@/configuration/types';

import { config } from '@/config';

import { GAActionEnum } from '@/enums/GoogleAnalyticsEnum';
import { StorageEnum } from '@/enums/StorageEnum';

export const loadGoogleTagManager = () => {
  const containerId = config.googleAnalytics.GTM_ID;
  const gtmAuth = config.googleAnalytics.GTM_AUTH;
  const gtmPreview = config.googleAnalytics.GTM_PREVIEW;

  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  (function (d, s, l: string, i) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer?.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const f = d.getElementsByTagName(s)[0];
    const j: HTMLElement & { async?: boolean; src?: string } = d.createElement(s);
    const dl = l != 'dataLayer' ? '&l=' + l : '';

    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`;

    f.parentNode?.insertBefore(j, f);
  })(document, 'script', 'dataLayer', containerId);
};

const dataLayerPush = (data: TGADataLayer) =>
  window.dataLayer &&
  window.dataLayer.push({ userId: sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_ID), ...data });

export const gaPushPageView = (page: string, title: string) =>
  dataLayerPush({ event: 'pageview', page_location: page, page_title: title });

export const gaPushEvent = (event: TGAEvent) =>
  dataLayerPush({
    event: 'ga_event',
    eventCategory: event.category,
    eventAction: event.action,
    eventLabel: event.label,
  });

export const gaPushECommerceEvent = (event: TGAECommerceEventEnum, payload: TGAECommercePayload) => {
  // Clear the previous ecommerce object.
  dataLayerPush({ ecommerce: null });
  dataLayerPush({ event, ecommerce: payload });
};

export const TRACKING_EVENTS = {
  BULK_BOOKING_INTERACTIONS: { action: GAActionEnum.CLICK, category: 'bulkBooking', label: '' },
  BUTTON_INTERACTIONS: { action: GAActionEnum.CLICK, category: 'button', label: '' },
  CAROUSEL_INTERACTIONS: { action: GAActionEnum.CLICK, category: 'carousel', label: '' },
  DIALOG_INTERACTIONS: { action: GAActionEnum.OPEN, category: 'dialog', label: '' },
  EXPANDER_INTERACTIONS: { action: GAActionEnum.OPEN, category: 'expander', label: '' },
  FILTER_INTERACTIONS: { action: GAActionEnum.CLICK, category: 'filterList', label: '' },
  LINK_INTERACTIONS: { action: GAActionEnum.CLICK, category: 'link', label: '' },
  NOTIFICATION_INTERACTIONS: { action: GAActionEnum.SHOW, category: 'notification', label: '' },
  PREPAID_INSTALLATION_INTERACTIONS: { action: GAActionEnum.CLICK, category: 'prepaidInstallation', label: '' },
  RECOMMENDED_FEATURE_INTERACTIONS: { action: GAActionEnum.CLICK, category: 'recommendedFeature', label: '' },
  SERVICE_SPECIFICATIONS_DOWNLOAD: { action: GAActionEnum.DOWNLOAD, category: 'serviceSpecifications', label: '' },
  VIDEO_INTERACTIONS: { action: GAActionEnum.PLAY, category: 'video', label: '' },
  // Using the prefix TEMP:: so we can filter this later from the reports
  VEHICLES_CORE_INTERACTIONS: { action: GAActionEnum.SHOW, category: 'TEMP::vehiclesCORE', label: '' },
};

export const TRACKING_CONTEXTS = {
  prepaidInstall: {
    SINGLE: 'installSingle',
    ALL: 'installAll',
    CONFIRM: 'confirmInstallation',
  },
};
