export const enum GAECommerceEventEnum {
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_TO_CART = 'add_to_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase',
  REMOVE_FROM_CART = 'remove_from_cart',
  SELECT_ITEM = 'select_item',
  VIEW_CART = 'view_cart',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
}

export const enum GAActionEnum {
  CLICK = 'click',
  CLOSE = 'close',
  DOWNLOAD = 'download',
  END = 'end',
  HOVER = 'hover',
  OPEN = 'open',
  ORDER = 'order',
  PLAY = 'play',
  SHOW = 'show',
  TYPE = 'type',
}

export enum GAItemListEnum {
  AVAILABLE_FEATURES = 'Available Features',
  AVAILABLE_FEATURES_ELIGIBLE_VEHICLES = 'Available Features - Eligible Vehicles',
  AVAILABLE_FEATURES_SUBSCRIPTIONS = 'Available Features - Subscriptions',
  AVAILABLE_FEATURES_BULK_BOOKING = 'Available Features - Bulk Booking',
  AVAILABLE_FEATURES_MY_CURRENT_MAPS = 'Available Features - My Current Maps',
  MY_FLEET_AVAILABLE_FEATURES = 'My Fleet - Available Features',
  MY_FLEET_INSTALLED_FEATURES = 'My Fleet - Installed Features',
  MY_FLEET_FEATURE_DETAILS = 'My Fleet - Feature Details',
  MY_FLEET_ELIGIBLE_VEHICLES = 'My Fleet - Eligible Vehicles',
  MY_FLEET_SUBSCRIPTIONS = 'My Fleet - Subscriptions',
  MY_FLEET_MY_CURRENT_MAPS = 'My Fleet - My Current Maps',
}
