import styled, { css } from 'styled-components';

import BaseImage from '@/components/Image';
import { Badge } from '@/components/ui-kit/Badge';
import BaseButton from '@/components/ui-kit/Button';
import { Panel } from '@/components/ui-kit/Panel';

// #region AppHeader
export const NavLinkContentWrapper = styled.div`
  ${({ theme }) => {
    const { spaces, breakpoints } = theme;

    return css`
      display: flex;
      white-space: nowrap;
      gap: ${spaces.extraSmall};
      align-items: center;

      @media screen and (max-width: ${breakpoints.small}) {
        justify-content: center;
      }
    `;
  }}
`;

export const CustomBadge = styled(Badge)`
  position: absolute;
  right: -10px;
  top: 15px;
  transform: none;
  min-height: 12px;
  min-width: 12px;
  height: 12px;
`;

// #endregion

// #region DevUserMenu
export const PopoverTitle = styled.div`
  ${({ theme }) => {
    const { fonts } = theme;

    return css`
      align-items: center;
      display: flex;
      justify-content: space-between;

      > div {
        font-size: ${fonts.size.small};
      }
    `;
  }}
`;

export const InstructionsDemoFleetDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Panel} {
    width: 100%;
  }
`;

export const VideoContainer = styled.div`
  ${({ theme }) => {
    const { spaces, breakpoints } = theme;

    return css`
      margin-bottom: ${spaces.mediumSmall};
      width: 744px;
      height: 424px;

      @media screen and (max-width: ${breakpoints.smaller}) {
        width: 100%;
      }
    `;
  }}
`;

export const ColumnGroup = styled.div`
  display: flex;
  gap: 20px;

  > div {
    flex: 1;
  }
`;

export const RowGroup = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    max-width: min-content;
  }
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      align-items: center;
      display: flex;
      gap: ${spaces.extraSmall};

      > .select {
        flex: 1;
        width: 22rem;
      }

      > label {
        margin: 0;
      }
    `;
  }}
`;

export const DelayInput = styled.input`
  text-align: center;
  width: 40px;
`;

export const Divider = styled.hr`
  && {
    margin: 10px 0;
  }
`;
// #endregion

// #region ShoppingCartWidget
export const ShoppingCartBadge = styled.span.attrs({
  'data-testid': 'shopping-cart-counter',
  className: 'badge badge-indicator',
})``;
// #endregion

// #region InstructionsDemoFleetButton
export const InstructionsButton = styled(BaseButton)`
  &&&& .icon {
    color: inherit;
  }
`;

export const InstructionImage = styled(BaseImage)`
  + svg {
    max-height: 320px;
  }
`;

export const ImageAltText = styled.div`
  ${({ theme }) => {
    const { colors, spaces } = theme;

    return css`
      color: ${colors.grayDark};
      margin: ${spaces.extraSmall} 0 ${spaces.slightlyMedium} 0;
    `;
  }}
`;

export const ListStepDetails = styled.ul`
  ${({ theme }) => {
    const { colors, spaces } = theme;

    return css`
      color: ${colors.grayDark};
      margin-left: ${spaces.extraSmall};

      ul {
        margin: 0;
      }
    `;
  }}
`;

export const StepNote = styled.div`
  ${({ theme }) => {
    const { colors, spaces } = theme;

    return css`
      color: ${colors.grayDark};
      margin-bottom: ${spaces.extraSmall};
    `;
  }}
`;

export const FooterNote = styled.div`
  ${({ theme }) => {
    const { fonts, spaces } = theme;

    return css`
      font-weight: ${fonts.weight.bold};
      margin-top: ${spaces.slightlyMedium};
    `;
  }}
`;

export const TableWrapper = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      table {
        margin-bottom: ${spaces.slightlyMedium};
        max-width: 530px;
      }
    `;
  }}
`;

export const CopiedText = styled.span`
  ${({ theme }) => {
    const { colors } = theme;

    return css`
      color: ${colors.grayDark};
    `;
  }}
`;
// #endregion

// #region InstructionsDemoFleetButton
export const ActionBarItemWrapper = styled.div`
  .ActionBarItemListItemButton {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
// #endregion
