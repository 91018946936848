import { UserManager } from 'oidc-client-ts';

import { config } from '@/config';
import * as storage from '@/configuration/login/storage';

import { reportErrorToSentry } from './sentry';
import { trace } from './trace';

const saveCurrentRoute = () => {
  const initialRoute = window.location.href;
  storage.routeStorage.saveRoute(initialRoute);
  trace('saving initial route', initialRoute);
};

const param = (window: Window, regex: RegExp, defaultValue = null) => {
  let result = defaultValue;
  decodeURI(window.location.href).replace(regex, (substring, it) => {
    result = it;
    return substring;
  });
  return result;
};

export const attemptInitialSignIn = async (userManager: UserManager) => {
  const isFreshRedirect = Boolean(param(window, /.*code=([^&]+)/u));

  try {
    const user = await userManager.signinSilent();
    const initialRoute = storage.routeStorage.getRoute();

    trace('initialRoute lookup', initialRoute);

    if (initialRoute && isFreshRedirect) {
      trace(`Go to location "/${initialRoute}"`);
      window.location.replace(`${initialRoute}`);
    }

    storage.routeStorage.discardRoute();
    return await Promise.resolve(user);
  } catch (error) {
    trace('oidc.signinSilent failed, trying page redirect...', error);

    if (config.login.preventRedirect) {
      console.warn('[feature/login] redirect prevented due to config. Error was', error);
    } else if (isFreshRedirect) {
      trace('oidc.signinSilent.error', 'redirect prevented due to suspicious signin error', error);
      storage.routeStorage.discardRoute();
      reportErrorToSentry(error, 'error', 'oauth#signinSilentError');
    } else {
      saveCurrentRoute();
      userManager.signinRedirect();
    }

    trace('auth problem?', error);

    // @TODO: Logic added to fix an issue when switching between accounts in PROD, we might need to revisit this
    if (error instanceof Error && error.message === 'login_required') {
      // Remove OIDC state from LS to allow renew the token
      Object.keys(localStorage)
        .filter((key) => key.startsWith('oidc.'))
        .forEach((key) => localStorage.removeItem(key));
    } else {
      throw new Error('Need to sign in');
    }
  }
};
