// 10px rio-uikit default font-size
const globalFontSizePX = 16 * 0.625;

export const pxToRem = (px: number, base = globalFontSizePX) => (1 / base) * px + 'rem';

const basePixelSizeList = Array.from(
  new Set([
    4,
    5,
    6,
    8,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    18,
    20,
    22,
    23,
    25,
    26,
    30,
    32,
    34,
    35,
    40,
    45,
    50,
    70,
    80,
    90,
    ...[globalFontSizePX],
  ])
);

const sizePXToREM: TThemeSizes = {};
basePixelSizeList.forEach((size) => (sizePXToREM[size] = pxToRem(size, globalFontSizePX)));

const colorBrandPrimary = 'var(--4e)'; // #30B4C0
const colorBrandSecondary = 'var(--1g)';
const colorBrandSuccess = 'var(--1w)';
const colorBrandWarning = 'var(--20)';
const colorBrandDanger = 'var(--24)';
const colorBrandInfo = 'var(--1s)';

const colorWhite = 'var(--0)';
const colorBlack = 'var(--4)';

const theme = {
  spaces: {
    tiny: '0.5rem',
    extraSmall: sizePXToREM[10],
    small: sizePXToREM[15],
    mediumSmall: sizePXToREM[20],
    slightlyMedium: sizePXToREM[25],
    medium: sizePXToREM[30],
    mediumToLarge: sizePXToREM[35],
    large: sizePXToREM[40],
    extraLarge: sizePXToREM[45],
    huge: sizePXToREM[50],
  },
  sizes: {
    tiny: '0.5rem',
    small: '1rem',
    medium: '1.25rem',
    large: '1.5rem',
    extraLarge: '2rem',
    huge: '3rem',
  },
  fonts: {
    weight: {
      thin: '200',
      light: '300',
      normal: '400',
      medium: '600',
      bold: '700',
    },
    size: {
      tiny: '1rem',
      extraSmall: sizePXToREM[11],
      small: sizePXToREM[12],
      normal: sizePXToREM[14],
      medium: sizePXToREM[16],
      large: sizePXToREM[18],
      extraLarge: sizePXToREM[20],

      h1: sizePXToREM[40],
      h2: sizePXToREM[30],
      h3: sizePXToREM[26],
      h4: sizePXToREM[22],
      h5: sizePXToREM[18],
      h6: sizePXToREM[14],
    },
    lineHeight: {
      half: 0.5,
      single: 1,
      normal: 1.125,
      normalToWide: 1.25,
      wide: 1.5,
      double: 2,
    },
    family: {
      _browserSansSerif: "'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
      primary: "'Source Sans Pro', Helvetica, Arial, sans-serif",
      ios: "'SF Text', 'Source Sans Pro', Helvetica, Arial, sans-serif",
      android: "'Roboto', 'Source Sans Pro', Helvetica, Arial, sans-serif",
    },
  },
  colors: {
    bg: colorWhite,
    fg: colorBlack,

    grayDarkest: 'var(--g)', // #2A3740
    grayDarker: 'var(--k)', // #4C5667
    grayDark: 'var(--o)', // #697A8B
    gray: 'var(--s)', // #A7AFBB
    grayLight: 'var(--w)', // #D0D8DE
    grayLighter: 'var(--10)', // #E5EBF0
    grayLightest: 'var(--14)', // #F6F8F9

    // Color - Brand Colors
    brandPrimary: colorBrandPrimary,
    brandSecondary: colorBrandSecondary,
    brandSuccess: colorBrandSuccess,
    brandWarning: colorBrandWarning,
    brandDanger: colorBrandDanger,
    brandInfo: colorBrandInfo,

    // Color - Rating Status
    red: 'var(--4y)', // #D90000
    orange: 'var(--4z)', // #FF8E3C
    orangeLight: 'var(--50)', // #F8C575
    green: 'var(--52)', // #4B924C
    greenLight: 'var(--51)', // #5CB85C

    // Color - Custom highlight colors
    highlight: 'var(--42)', // #154B56
    highlightDark: 'var(--4a)', // #268897;
    highlightDarker: 'var(--46)', // #206E7E
    highlightDarkest: 'var(--4k)', // #154B58
    highlightLightest: 'var(--4q)', // #EBF9FA
    highlightDecent: 'var(--4q)', // #CEF0F3

    // Color - Hardcoded to avoid changing when in dark mode
    noDarkMode: {
      white: '#FFFFFF',
      black: '#000000',
      grayDark: '#697A8B',
      highlightDarker: '#206E7E',
      highlightDarkest: '#154B56',
      highlightLight: '#8DDBE3',
      highlightLightest: '#EBF9FA',
    },
  },
  borderRadius: {
    normal: sizePXToREM[4],
  },
  zIndex: {
    base: 'auto',
    tooltip: 1060,
    modal: 1050,
    modalBackground: 1040,
    popover: 1010,
    navbar: 1000,
    dropdown: 1000,
    sidebar: 990,
    tooltipOnboarding: 990,
    checkoutStepper: 21,
    sidebarHeaderInfo: 3,
    alertList: 3,
    featureTag: 2,
    notifyMeCard: 2,
  },
  opacity: {
    light: 0.75,
    normal: 0.5,
    medium: 0.25,
    high: 0.15,
  },
  breakpoints: {
    hypersmall: '360px',
    ultrasmall: '480px',
    xtrasmall: '600px',
    smaller: '640px',
    small: '768px',
    medium: '992px',
    large: '1200px',
    xtralarge: '1440px',
    ultralarge: '1700px',
  },
};

export default theme;
