import { useState } from 'react';

import { TImageProps } from '@/components/types';

import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

const Image = ({ src, className, width, height, alt, loading = 'eager' }: TImageProps) => {
  const [isLoading, setIsLoading] = useState(loading === 'eager');

  const regex = /\.(?:jpg|jpeg|gif|png|svg|webp)$/i;
  const isSrcValid = regex.test(src.toLowerCase());

  const loaderHeight = height === 'unset' || height === null ? '100%' : height;
  const loaderWidth = width === 'unset' ? '100%' : width;

  return isSrcValid ? (
    <>
      <img
        src={src}
        alt={alt || `${src}-alt`}
        className={className}
        onError={({ target }) => {
          const eventTarget: Partial<HTMLImageElement> = target;

          eventTarget.onerror = null;
          eventTarget.src = 'https://cdn.rio.cloud/svg/common/ico_rio.svg';
        }}
        onLoad={() => setIsLoading(false)}
        style={{ width: width ?? 'unset', height: height ?? 'unset', display: isLoading ? 'none' : 'block' }}
        loading={loading}
      />
      {isLoading && <ContentLoader width={loaderWidth ?? undefined} height={loaderHeight ?? undefined} />}
    </>
  ) : null;
};

export default Image;
