import { UserManager } from 'oidc-client-ts';

import { TOAuthConfig, TSessionRenewedResult, TSessionToken } from './types';

import { config } from '@/config';

import { StorageEnum } from '@/enums/StorageEnum';

import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';

import { extractLanguage } from './lang/lang';
import { configureMockUserManager, configureUserManager, createUserManager } from './login/login';
import { initDatadog } from './setup/datadog';
import { attemptInitialSignIn } from './setup/oauth';
import { initSentry, reportErrorToSentry } from './setup/sentry';
import { trace } from './setup/trace';
import { accessToken, parseJwt } from './tokenHandling/accessToken';

export const main = async (renderApp: () => void) => {
  const oauthConfig = {
    onSessionExpired: () => {
      trace('index.onSessionExpired');

      accessToken.discardAccessToken();
      sessionStorage.setItem(StorageEnum.STORAGE_KEY_USER_SESSION_EXPIRED, 'true');
    },
    onSessionRenewed: (result: TSessionRenewedResult) => {
      trace('index.onTokenRenewed');

      accessToken.saveAccessToken(result.accessToken);
    },
    onUserLoaded: (user: TSessionRenewedResult) => {
      trace('index.onUserLoaded');

      const decodedToken: TSessionToken = parseJwt(user.accessToken);
      const isTestUser = (decodedToken?.tenant || '').endsWith('.test');

      sessionStorage.setItem(StorageEnum.STORAGE_KEY_USER_TOKEN, user.accessToken);
      sessionStorage.setItem(StorageEnum.STORAGE_KEY_USER_LOCALE, user.locale);
      sessionStorage.setItem(StorageEnum.STORAGE_KEY_USER_FROM_TEST_FLEET, isTestUser.toString());
      sessionStorage.setItem(StorageEnum.STORAGE_KEY_USER_HAS_ACCESS, 'true');
      sessionStorage.setItem(StorageEnum.STORAGE_KEY_USER_ID, decodedToken?.sub);

      // Remove from session storage
      sessionStorage.removeItem(StorageEnum.STORAGE_KEY_USER_SESSION_EXPIRED);

      const html = document.querySelector('html');
      const lang = extractLanguage(user.locale);

      // Set `<html lang>` attribute to be synced with the current lang
      if (html && lang && html.getAttribute('lang') !== lang) {
        html.setAttribute('lang', lang);
      }

      initSentry(isTestUser);
      initDatadog(isTestUser);
    },
  } as TOAuthConfig;

  const userManager: UserManager = config.login.mockAuthorization
    ? configureMockUserManager(oauthConfig)
    : configureUserManager(oauthConfig, createUserManager());

  const signinSilent = userManager.signinSilent.bind(userManager);

  document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, () => {
    window.location.reload();
    signinSilent();
  });

  document.addEventListener(EVENT_USER_PROFILE_CHANGED, () => {
    signinSilent();
  });

  try {
    await userManager.clearStaleState();
    await attemptInitialSignIn(userManager);

    sessionStorage.setItem(StorageEnum.STORAGE_KEY_USER_HAS_ACCESS, 'true');

    renderApp();
  } catch (error) {
    trace('could not start application', error);

    if (error instanceof Error && error.message !== 'Need to sign in') {
      reportErrorToSentry(error, 'error', 'config#errorStartingApp');
    }
  }
};
