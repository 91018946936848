import { Suspense, lazy, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { config } from '@/config';

import useTermsAndConditionsStatusMutation from '@/hooks/mutations/userManager/useTermsAndConditionsStatusMutation';
import useTranslation from '@/hooks/utils/useTranslation';

import Button from '@/components/ui-kit/Button';

import Checkbox from '@rio-cloud/rio-uikit/Checkbox';

import { ButtonGroup, ButtonLink, Container, Footer, FooterInner, Heading, PdfContainer } from './styled';

const PDFViewer = lazy(() => import('@/components/PDFViewer'));

const UserTermsAndConditions = () => {
  const [accepted, setAccepted] = useState(false);

  const { locale } = useTranslation(true);
  const { mutate: updateTermsConditions, isPending } = useTermsAndConditionsStatusMutation();

  const termsAndConditionsPdfEndpoint =
    config.backend.RIO_TCS && config.backend.RIO_TCS.replace('[localePlaceholder]', locale);

  return (
    <Container>
      <Heading>
        <FormattedMessage id="mannow.termsAndConditions.title" />
      </Heading>
      <PdfContainer>
        <Suspense fallback={<></>}>
          <PDFViewer file={termsAndConditionsPdfEndpoint} />
        </Suspense>
      </PdfContainer>
      <Footer>
        <FooterInner>
          <div>
            <Checkbox
              onClick={() => {
                setAccepted(!accepted);
              }}
            >
              <FormattedMessage id="mannow.termsAndConditions.consentMessage" />
            </Checkbox>
          </div>
          <ButtonGroup>
            <ButtonLink href={termsAndConditionsPdfEndpoint}>
              <FormattedMessage id="mannow.general.downloadPdf" />
            </ButtonLink>
            <Button
              bsStyle={Button.PRIMARY}
              disabled={!accepted}
              $isLoading={isPending}
              onClick={() => updateTermsConditions()}
            >
              <FormattedMessage id="mannow.general.confirm" />
            </Button>
          </ButtonGroup>
        </FooterInner>
      </Footer>
    </Container>
  );
};

export default UserTermsAndConditions;
