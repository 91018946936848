import { ASTNode, print } from 'graphql';

import { TCustomError, TMarketplaceError, TResponseOptions } from '@/services/types';

import { HTTPStatusEnum } from '@/enums/HTTPStatusEnum';
import { StorageEnum } from '@/enums/StorageEnum';

import { customException, processError } from './errorHandling';

const processApplicationResponse = (response: Response, options: TResponseOptions = {}) => {
  const { expectedResponseBody = true, isAllowed404Response = false, isMarketplaceResponse = false } = options;

  const allowedStatus = [...(isAllowed404Response ? [HTTPStatusEnum.NOT_FOUND] : [])];

  if (!response.ok && !allowedStatus.includes(response.status)) {
    if (response.status === HTTPStatusEnum.CONFLICT || isMarketplaceResponse) {
      return response.json().then((res) => {
        throw customException({
          status: HTTPStatusEnum.CONFLICT,
          resources: res.resources,
          error: res.error,
          message: res.message,
          context: res.context,
          identifier: res.identifier,
          title: res.title,
          detail: res.detail,
        } as TCustomError | TMarketplaceError);
      });
    }

    throw customException(response as unknown as TCustomError);
  }

  if (response.ok) {
    if (expectedResponseBody) {
      return response.json();
    }

    return new Promise((resolve) => resolve(response));
  }

  return Promise.resolve(null);
};

export const fetchData = (path: string, options: TResponseOptions = {}) =>
  fetch(path, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_TOKEN)}`,
    },
  }).then((response) =>
    processApplicationResponse(response, {
      expectedResponseBody: options?.expectedResponseBody ?? true,
      isAllowed404Response: options?.isAllowed404Response ?? false,
    })
  );

export const putData = (path: string, body?: object): Promise<object> =>
  fetch(path, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_TOKEN)}`,
    },
    method: 'PUT',
    body: JSON.stringify(body),
  })
    .then((response) => processApplicationResponse(response))
    .catch((e) => {
      processError(e);
    });

export const deleteData = (path: string) =>
  fetch(path, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_TOKEN)}`,
    },
    method: 'DELETE',
  })
    .then((response) => processApplicationResponse(response))
    .catch((e) => {
      processError(e);
    });

export const postData = (path: string, body: unknown, options: TResponseOptions = {}) =>
  fetch(path, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_TOKEN)}`,
    },
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then((response) =>
      processApplicationResponse(response, {
        expectedResponseBody: options?.expectedResponseBody ?? true,
        isAllowed404Response: options?.isAllowed404Response ?? false,
        isMarketplaceResponse: options?.isMarketplaceResponse ?? false,
      })
    )
    .catch((e) => {
      const errorCallback = options?.errorCallback || processError;
      errorCallback(e);
    });

export const fetchGraphqlData = <Variables, Response>(
  path: string,
  query: ASTNode,
  variables: Variables
): Promise<Response> =>
  fetch(`${path}/graphql`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_TOKEN)}`,
    },
    method: 'POST',
    body: JSON.stringify({ query: print(query), variables }),
  })
    .then((response) => processApplicationResponse(response))
    .catch((e) => {
      processError(e);
    });

export const downloadFile = (path: string, errorMessage: string) =>
  fetch(path, {
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${sessionStorage.getItem(StorageEnum.STORAGE_KEY_USER_TOKEN)}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw customException(response as unknown as TCustomError);
      } else {
        return response;
      }
    })
    .catch((e) => {
      processError(e.status ? { ...e, message: errorMessage } : e);
    });
