import styled, { css } from 'styled-components';

import BaseButton, { type ButtonProps } from '@rio-cloud/rio-uikit/Button';

type TButtonProps = { $isLoading?: boolean; $isFullWidth?: boolean } & ButtonProps;

/** Extended from UIKit to make it easier to use the isLoading state */
const Button = styled(BaseButton).attrs((props: TButtonProps) => ({
  className: props.$isLoading ? 'btn-loading' : '',
}))<TButtonProps>`
  ${({ $isFullWidth }: TButtonProps) => {
    const widthStyle = $isFullWidth
      ? css`
          width: 100%;
        `
      : css``;

    return css`
      ${widthStyle};
    `;
  }}
`;

export default Button;
