import IframeResizer from 'iframe-resizer-react';
import { ReactElement, lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { config } from '@/config';

import { StorageEnum } from '@/enums/StorageEnum';

import useDefaultLandingPage from '@/hooks/utils/useDefaultLandingPage';
import useToggles from '@/hooks/utils/useToggles';

import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';

import { AVAILABLE_FEATURES_ROUTE, INSTALLATION_MONITOR_ROUTE, MY_FLEET_ROUTE, ORDERS_ROUTE } from '@/routes';

import ServiceInfoWidget from './ServiceInfoWidget';
import ShoppingCartWidget from './ShoppingCartWidget';
import { CustomBadge, NavLinkContentWrapper } from './styled';

const InstructionsDemoFleetButton = lazy(() => import('./InstructionsDemoFleetButton'));
const DevUserMenu = lazy(() => import('./DevUserMenu'));

const AppHeader = ({ hideMenu }: { hideMenu: boolean }) => {
  const { isDemoFleetToggle, installationMonitorToggle } = useToggles();

  const defaultLandingPage = useDefaultLandingPage();

  const isDemoFleetEnabledInDev = sessionStorage.getItem(StorageEnum.STORAGE_KEY_DEMO_FLEET) === 'true';

  let navItems: {
    key: string;
    route: ReactElement;
  }[] = [];

  if (!hideMenu) {
    navItems = [
      {
        key: 'vehicles',
        route: (
          <NavLink to={MY_FLEET_ROUTE}>
            <FormattedMessage id="mannow.phrase.myFleet" />
          </NavLink>
        ),
      },
      {
        key: 'availableFeatures',
        route: (
          <NavLink to={AVAILABLE_FEATURES_ROUTE}>
            <FormattedMessage id="mannow.phrase.availableFeatures" />
          </NavLink>
        ),
      },
    ];

    if (defaultLandingPage === AVAILABLE_FEATURES_ROUTE) {
      navItems = navItems.reverse();
    }

    // Until we are certain about what endpoints to use we will maintain this logic hardcoded
    const showInstallationMonitorBadge = true;

    if (installationMonitorToggle) {
      navItems.push({
        key: 'installationMonitor',
        route: (
          <NavLink to={INSTALLATION_MONITOR_ROUTE}>
            <NavLinkContentWrapper>
              <FormattedMessage id="mannow.phrase.installationMonitor" />
              {showInstallationMonitorBadge && <CustomBadge $isWarning />}
            </NavLinkContentWrapper>
          </NavLink>
        ),
      });
    }

    navItems.push({
      key: 'orders',
      route: (
        <NavLink to={ORDERS_ROUTE}>
          <FormattedMessage id="mannow.general.orders" />
        </NavLink>
      ),
    });
  }

  const instructionsDemoFleet = isDemoFleetToggle || isDemoFleetEnabledInDev ? <InstructionsDemoFleetButton /> : <></>;

  const shoppingCart = hideMenu ? <></> : <ShoppingCartWidget />;
  const notifications = <RioNotifications />;
  const serviceInfo = hideMenu ? <></> : <ServiceInfoWidget />;
  const accountMenu =
    config.serviceEnvironment === 'production' ? (
      <DefaultUserMenu environment={config.serviceEnvironment} />
    ) : (
      <DevUserMenu />
    );

  return (
    <ApplicationHeader
      label={<FormattedMessage id="mannow.moduleName" />}
      appNavigator={<IframeResizer className="iFrameResizer" src={config.backend.MENU_SERVICE} />}
      homeRoute={<a href={config.homeRoute} />}
      navItems={navItems}
      actionBarItems={[instructionsDemoFleet, shoppingCart, serviceInfo, notifications, accountMenu]}
    />
  );
};

export default AppHeader;
