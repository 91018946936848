import { TRegion } from '@/features/types';
import { TFeatureDetails, TSubscription, TSubscriptionEnriched, TSubscriptionWithRegion } from '@/hooks/types';

import { InstallationStatusEnum, SubscriptionStatusEnum } from '@/enums/StatusEnum';
import {
  MapUpdatePackageEnum,
  SubscriptionCodeEREnum,
  SubscriptionCodeMOTEnum,
  SubscriptionCodeMUEnum,
  SubscriptionCodeONEnum,
  SubscriptionPeriodEnum,
} from '@/enums/SubscriptionCodesEnum';

import { autoRenewalFeatures, freePlanCodes } from '@/data/featureDefinitions';

import { dateDiffInDays } from '@/helpers/date';
import { isFreeTrialAvailable } from '@/helpers/featurePlans';
import { mapFeaturePrice } from '@/helpers/featurePrice';
import { getPackageByRegion } from '@/helpers/mapUpdate';

export const enrichSubscriptions = (
  subscriptionsFiltered: TSubscription[],
  featuresContent: TFeatureDetails[],
  subscriptionsPlans: TSubscription[][]
): TSubscriptionEnriched[] =>
  subscriptionsFiltered.map((subscription, index) => {
    const featureCode = subscription.subscriptionPlan.featureCode;

    const enrichedFeature = mapFeaturePrice(
      featuresContent.find(({ code }) => code === featureCode)!,
      subscriptionsPlans[index]
    );

    return {
      ...subscription,
      feature: enrichedFeature,
      installationStatus:
        subscription.status === SubscriptionStatusEnum.ACTIVE
          ? InstallationStatusEnum.INSTALLED
          : InstallationStatusEnum.PENDING,
    };
  });

export const enrichMUSubscriptionWithRegions = (
  subscription: TSubscriptionEnriched,
  mapUpdateManagerRegions: TRegion[] = []
): TSubscriptionEnriched | TSubscriptionWithRegion => {
  // Return the subscription if there are no regions
  if (mapUpdateManagerRegions.length === 0) {
    return subscription;
  }

  const activeRegions = mapUpdateManagerRegions && mapUpdateManagerRegions.filter((region) => region.active);

  // Return the subscription if there are no active regions
  if (!activeRegions || activeRegions.length === 0) {
    return subscription;
  }

  let enrichedSubscription: TSubscriptionWithRegion = subscription;
  let latest;

  if (activeRegions.length > 1) {
    latest = activeRegions.reduce((max, curr) => {
      if (dateDiffInDays(max.installationDate, curr.installationDate) > 0) {
        return curr;
      }
      return max;
    });
  } else {
    latest = { ...activeRegions[0] };
    enrichedSubscription = {
      ...enrichedSubscription,
      selection: activeRegions[0].region,
    };
  }

  switch (subscription.subscriptionPlan.code) {
    case SubscriptionCodeMUEnum.FULL_1_YEAR:
    case SubscriptionCodeMUEnum.FULL_3_YEARS:
      enrichedSubscription.selection = MapUpdatePackageEnum.PACKAGE_PRO;
      break;
    case SubscriptionCodeMUEnum.REGION_1_YEAR:
    case SubscriptionCodeMUEnum.REGION_3_YEARS:
      enrichedSubscription.selection = getPackageByRegion(activeRegions[0].region);
      break;
    case SubscriptionCodeMUEnum.SINGLE_1_YEAR:
    case SubscriptionCodeMUEnum.SINGLE_3_YEARS:
    default:
      enrichedSubscription.selection = activeRegions[0].region;
      break;
  }

  enrichedSubscription.version = {
    currentVersion: latest.currentVersion,
    installationDate: latest.installationDate,
  };

  return enrichedSubscription;
};

export const isTrialPeriod = (subscriptionPlanCode: TFeaturePlanEnum | string) =>
  isFreeTrialAvailable(subscriptionPlanCode);

export const isSubscriptionFree = (subscriptionPlanCode: string) =>
  freePlanCodes.some((code) => subscriptionPlanCode.startsWith(code!));

export const isAutoRenewalSubscription = (subscriptionPlanCode: string, featureCode: TFeatureCodeEnum) => {
  const feature = autoRenewalFeatures[featureCode];

  if (!feature) {
    return !isTrialPeriod(subscriptionPlanCode);
  }

  return feature.autoRenewalPlanCodes.some((planCode: string) => subscriptionPlanCode.startsWith(planCode));
};

export const getCurrentSubscription = (featureSubscriptions: TSubscriptionEnriched[]) => {
  return !Array.isArray(featureSubscriptions)
    ? featureSubscriptions
    : featureSubscriptions.find(({ status }) => status === SubscriptionStatusEnum.ACTIVE) || featureSubscriptions[0];
};

export const getUpcomingSubscription = (featureSubscriptions: TSubscriptionEnriched[]) => {
  const upcomingSubscription = Array.isArray(featureSubscriptions)
    ? featureSubscriptions.find(({ status }) => status === SubscriptionStatusEnum.PENDING)
    : false;

  if (!upcomingSubscription) {
    return;
  }

  const currentSubscription = getCurrentSubscription(featureSubscriptions);

  if (!currentSubscription?.endDate) {
    return;
  }

  return {
    ...upcomingSubscription,
    startDate: currentSubscription?.endDate,
  };
};

export const getSubscriptionPeriodByPlanCode = (code: string) => {
  switch (code) {
    case SubscriptionCodeMUEnum.SINGLE_1_YEAR:
    case SubscriptionCodeMUEnum.REGION_1_YEAR:
    case SubscriptionCodeMUEnum.FULL_1_YEAR:
    case SubscriptionCodeMOTEnum.FREE_1_YEAR:
    case SubscriptionCodeMOTEnum.PAID_1_YEAR:
    case SubscriptionCodeEREnum.PAID_1_YEAR:
    case SubscriptionCodeONEnum.FREE_PRESET_1_YEAR:
      return SubscriptionPeriodEnum.TWELVE_MONTHS;
    case SubscriptionCodeMUEnum.SINGLE_3_YEARS:
    case SubscriptionCodeMUEnum.REGION_3_YEARS:
    case SubscriptionCodeMUEnum.FULL_3_YEARS:
    case SubscriptionCodeMOTEnum.PAID_3_YEARS:
    case SubscriptionCodeEREnum.PAID_3_YEARS:
      return SubscriptionPeriodEnum.THIRTY_SIX_MONTHS;
    case SubscriptionCodeMOTEnum.FREE_2_YEARS:
    case SubscriptionCodeEREnum.FREE_2_YEARS:
    case SubscriptionCodeONEnum.FREE_2_YEARS:
      return SubscriptionPeriodEnum.TWENTY_FOUR_MONTHS;
    default:
      return undefined;
  }
};
