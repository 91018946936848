export const MY_FLEET_ROUTE = '/myFleet';
export const AVAILABLE_FEATURES_ROUTE = '/availableFeatures';
export const INSTALLATION_MONITOR_ROUTE = '/installationMonitor';
export const ORDERS_ROUTE = '/orders';
export const SHOPPING_CART_ROUTE = '/shoppingCart';
export const CHECKOUT_ROUTE = '/checkout';

// Sidebar Vehicle Details
export const VEHICLE_INSTALLABLE_FEATURES_ROUTE = `${MY_FLEET_ROUTE}/:assetId/installableFeatures`;
export const VEHICLE_INSTALLED_FEATURES_ROUTE = `${MY_FLEET_ROUTE}/:assetId/installedFeatures`;

// Sidebar Feature Details
export const VEHICLE_FEATURE_DETAILS_ROUTE = `${MY_FLEET_ROUTE}/:assetId/:featureCode/details`;
export const VEHICLE_FEATURE_INSTALLABLE_ROUTE = `${MY_FLEET_ROUTE}/:assetId/:featureCode/installableVehicles`;
export const VEHICLE_FEATURE_INSTALLED_ROUTE = `${MY_FLEET_ROUTE}/:assetId/:featureCode/installedVehicles`;

// Sidebar MapUpdate feature
export const VEHICLE_MY_CURRENT_MAPS_ROUTE = `${MY_FLEET_ROUTE}/:assetId/currentMaps`;

// Sidebar Installation Monitor Details
export const INSTALLATION_MONITOR_DETAILS_ROUTE = `${INSTALLATION_MONITOR_ROUTE}/:orderId/details`;

// Feature Details
export const FEATURE_DETAILS_ROUTE = `${AVAILABLE_FEATURES_ROUTE}/:featureCode/details`;
export const FEATURE_INSTALLABLE_ROUTE = `${AVAILABLE_FEATURES_ROUTE}/:featureCode/installableVehicles`;
export const FEATURE_INSTALLED_ROUTE = `${AVAILABLE_FEATURES_ROUTE}/:featureCode/installedVehicles`;

// Order Details
export const ORDER_DETAILS_ROUTE = `${ORDERS_ROUTE}/:orderId/details`;

export const GENERIC_ERROR_ROUTE = '/error';
export const FORBIDDEN_ERROR_ROUTE = '/forbiddenError';
export const NOT_FOUND_ROUTE = '/pageNotFound';

export const routes = [
  MY_FLEET_ROUTE,
  AVAILABLE_FEATURES_ROUTE,
  INSTALLATION_MONITOR_ROUTE,
  ORDERS_ROUTE,
  SHOPPING_CART_ROUTE,
  CHECKOUT_ROUTE,

  VEHICLE_INSTALLABLE_FEATURES_ROUTE,
  VEHICLE_INSTALLED_FEATURES_ROUTE,

  VEHICLE_FEATURE_DETAILS_ROUTE,
  VEHICLE_FEATURE_INSTALLABLE_ROUTE,
  VEHICLE_FEATURE_INSTALLED_ROUTE,

  VEHICLE_MY_CURRENT_MAPS_ROUTE,

  FEATURE_DETAILS_ROUTE,
  FEATURE_INSTALLABLE_ROUTE,
  FEATURE_INSTALLED_ROUTE,

  ORDER_DETAILS_ROUTE,

  GENERIC_ERROR_ROUTE,
  FORBIDDEN_ERROR_ROUTE,
  NOT_FOUND_ROUTE,
];

export const pageTitles = [
  { page: MY_FLEET_ROUTE, title: 'My Fleet' },
  { page: AVAILABLE_FEATURES_ROUTE, title: 'Available Features' },
  { page: INSTALLATION_MONITOR_ROUTE, title: 'Installation Monitor' },
  { page: ORDERS_ROUTE, title: 'Orders' },
  { page: SHOPPING_CART_ROUTE, title: 'Shopping Cart' },
  { page: CHECKOUT_ROUTE, title: 'Checkout' },
];
