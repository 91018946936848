import flow from 'lodash/flow';
import defaultTo from 'lodash/fp/defaultTo';
import split from 'lodash/fp/split';
import head from 'lodash/head';

const DEFAULT_LOCALE = 'en-GB';

const extractLanguage = flow(defaultTo(DEFAULT_LOCALE), split('-'), head);

export { DEFAULT_LOCALE, extractLanguage };
