import join from 'lodash/fp/join';
import { InMemoryWebStorage, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import { TOAuthConfig, TSessionRenewedResult } from '@/configuration/types';

import { config } from '@/config';
import { trace } from '@/configuration/setup/trace';

import { developmentLocale } from '@/helpers/development';

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;
const DEV_LOCALE = developmentLocale();

const retrySigninSilent = (oauthConfig: TOAuthConfig, userManager: UserManager) => {
  userManager.signinSilent().catch((error: Error) => {
    if (error.message === 'login_required') {
      oauthConfig.onSessionExpired();
    } else {
      setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
    }
  });
};

const adaptPublishedInfo = (result: User): TSessionRenewedResult => ({
  accessToken: result.access_token,
  idToken: result.profile,
  locale: (DEV_LOCALE || result.profile?.locale || 'en-GB') as string,
  profile: {},
});

export const createUserManager = () => {
  const { authority: loginAuthority, clientId, oauthScope, redirectUri } = config.login;

  const isProd = config.serviceEnvironment === 'production';

  const settings: UserManagerSettings = {
    authority: loginAuthority,
    client_id: clientId,
    client_secret: isProd ? clientId : '',
    loadUserInfo: false,
    redirect_uri: redirectUri,
    response_type: 'code',
    scope: join(' ', oauthScope),
    silent_redirect_uri: redirectUri,
    includeIdTokenInSilentRenew: false,
    automaticSilentRenew: true,
    monitorSession: true,
    staleStateAgeInSeconds: 600,
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
  };

  return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: TOAuthConfig, userManager: UserManager) => {
  userManager.events.addUserLoaded((user) => {
    trace('oidc.signinSilent success');

    oauthConfig.onUserLoaded(adaptPublishedInfo(user));
    oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
  });

  userManager.events.addUserUnloaded(() => {
    oauthConfig.onSessionExpired();
  });

  userManager.events.addSilentRenewError((error) => {
    trace('oidc.silentRenewError', error);

    retrySigninSilent(oauthConfig, userManager);
  });

  userManager.events.addUserSignedOut(() => {
    oauthConfig.onSessionExpired();
  });

  return userManager;
};

export const configureMockUserManager = (oauthConfig: TOAuthConfig): UserManager => {
  const signinSilent = () => {
    const userSettings = {
      access_token: 'valid-mocked-oauth-bogus-token',
      profile: {
        iss: 'Issuer Identifier',
        aud: 'Audience(s): client_id',
        exp: 10,
        iat: 5,
        account: 'mockaccount',
        azp: 'test-client',
        email: 'test@example.com',
        family_name: 'Client',
        given_name: 'Test',
        name: 'Test Client',
        sub: 'prod-rio-users:mock-user',
        locale: config.login.mockLocale,
      },
      id_token: 'id_token',
      session_state: 'session_state',
      refresh_token: 'refresh_token',
      token_type: 'token_type',
      scope: 'scope',
      expires_at: 100000,
      state: 'state',
    };

    const user = new User(userSettings);
    oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
    oauthConfig.onUserLoaded(adaptPublishedInfo(user));

    return Promise.resolve(user);
  };

  const clearStaleState = () => {
    console.info('[configuration/login/oidc-session] Stale state cleared');
    return Promise.resolve();
  };

  return { signinSilent, clearStaleState } as UserManager;
};
