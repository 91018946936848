import { Context, useContext } from 'react';

import { TAvailableFeaturesContext, TBulkBookingContext, TUserDataContext, TVehicleDetailContext } from './types';

import { AvailableFeaturesContext, BulkBookingContext, UserDataContext, VehicleDetailContext } from './contexts';

const useContextHook = <T>(context: Context<T>): T => {
  const contextValue = useContext(context);

  if (contextValue === undefined) {
    throw new Error(`context hook (${context.displayName}) must be used within a Provider!`);
  }

  return contextValue;
};

export const useAvailableFeaturesContext = () => useContextHook<TAvailableFeaturesContext>(AvailableFeaturesContext);

export const useUserDataContext = () => useContextHook<TUserDataContext>(UserDataContext);

export const useVehicleDetailContext = () => useContextHook<TVehicleDetailContext>(VehicleDetailContext);

export const useBulkBookingContext = () => useContextHook<TBulkBookingContext>(BulkBookingContext);
