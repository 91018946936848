import { LanguagesCodesEnum } from '@/enums/LanguagesCodesEnum';

const LINK_TO_MOT_SETUP_INSTRUCTIONS = {
  [LanguagesCodesEnum.EN_GB]:
    'https://rio.cloud/fileadmin/Marketplace/MAN/Products/MAN_Now/Localized/en-GB/MAN_Now_Installation_Process.pdf',
  [LanguagesCodesEnum.DE_DE]:
    'https://rio.cloud/fileadmin/Marketplace/MAN/Products/MAN_Now/Localized/de-DE/MAN_Now_Installationsprozess.pdf',
};

export const getMOTSetupInstructionsLink = (locale: string) => {
  switch (locale) {
    case LanguagesCodesEnum.EN_GB:
      return LINK_TO_MOT_SETUP_INSTRUCTIONS[LanguagesCodesEnum.EN_GB];
    case LanguagesCodesEnum.DE_DE:
      return LINK_TO_MOT_SETUP_INSTRUCTIONS[LanguagesCodesEnum.DE_DE];
    default:
      return LINK_TO_MOT_SETUP_INSTRUCTIONS[LanguagesCodesEnum.EN_GB];
  }
};
