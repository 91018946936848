import { useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';

import { TVehicleItem } from '@/hooks/types';
import { TVehicleListItemResponse } from '@/services/types';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchVehicle } from '@/services/vehicleManager';

import useAssetVehiclesQuery from '@/hooks/queries/rio/useAssetVehiclesQuery';

import { convertSnakeToCamelCase } from '@/helpers/dataTransformation';

const useVehicleQuery = (assetId: string) => {
  const { data: rioVehicles, isSuccess: isSuccessFetchVehicles } = useAssetVehiclesQuery();

  const shouldFetchData = rioVehicles && rioVehicles.length > 0 && assetId;
  const rioVehicle = rioVehicles?.find(({ id }) => id === assetId);

  return useQuery({
    queryKey: [QueryIdentifierEnum.VEHICLE, { assetId }],
    queryFn: async () => {
      if (shouldFetchData && !isEmpty(rioVehicle)) {
        const { content } = await fetchVehicle(assetId);

        const data = convertSnakeToCamelCase<TVehicleListItemResponse, TVehicleItem>(content?.[0]);

        return { ...rioVehicle, ...data } as TVehicle;
      }

      return null;
    },

    enabled: isSuccessFetchVehicles,
    gcTime: Infinity,
  });
};

export default useVehicleQuery;
