import {
  TAssetAssociationResponse,
  TAssetVehicleResponse,
  TBillingDataResponse,
  TMarketplaceBookingsRequest,
  TMarketplaceError,
  TMarketplaceRenewalSubscription,
  TPaymentMethodsResponse,
  TVehicleTagsResponse,
} from './types';

import { config } from '@/config';
import { fetchData, postData } from '@/services/config/common';

const fetchPaymentMethodsEndpoint = (account: string) =>
  config.backend.RIO_PAYMENT_METHODS!.replace('[accountPlaceholder]', account);

export const fetchPaymentMethods = (fleetId: string): Promise<TPaymentMethodsResponse> =>
  fetchData(fetchPaymentMethodsEndpoint(fleetId), { isAllowed404Response: true });

export const fetchAssetVehicles = (): Promise<TAssetVehicleResponse> =>
  fetchData(encodeURI(`${config.backend.RIO_ASSET}/assets?status=active&asset_type=truck&asset_type=van&embed=(tags)`));

export const fetchAssetAssociations = (): Promise<TAssetAssociationResponse> =>
  fetchData(encodeURI(`${config.backend.RIO_ASSET}/associations?embed=(device,asset)`));

export const fetchBillingData = (account: string): Promise<TBillingDataResponse> =>
  fetchData(config.backend.RIO_BILLING_DATA!.replace('[accountPlaceholder]', account));

export const fetchVehicleTags = (): Promise<TVehicleTagsResponse> => fetchData(`${config.backend.RIO_TAGS}/tags`);

export const submitMarketplaceRenewalSubscription = (
  subscription: TMarketplaceRenewalSubscription,
  sku: string,
  errorCallback: (error: TMarketplaceError) => void
): Promise<void> =>
  postData(`${config.backend.RIO_CONTRACTS}/${sku}/config`, subscription, {
    errorCallback,
    isMarketplaceResponse: true,
    expectedResponseBody: false,
  });

export const submitMarketplaceBooking = (
  subscription: TMarketplaceBookingsRequest,
  errorCallback: (error: TMarketplaceError) => void
): Promise<void> =>
  postData(`${config.backend.RIO_BOOKINGS}`, subscription, { errorCallback, isMarketplaceResponse: true });
