import { useQuery } from '@tanstack/react-query';

import { TFeatureDetails } from '@/hooks/types';
import { TFeatureDetailsContent, TServiceSpecificationContent } from '@/services/types';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';
import { TagEnum } from '@/enums/TagEnum';

import { allFeatureCodes, featuresPowertrains, featuresTags } from '@/data/featureDefinitions';

import { fetchFeatureDetails } from '@/services/cms';

import useFeaturesMaintenance from '@/hooks/data/useFeaturesMaintenance';
import useTranslation from '@/hooks/utils/useTranslation';

const findServiceSpecification = (serviceSpecifications: TServiceSpecificationContent[], code: string | undefined) =>
  serviceSpecifications.find((serviceSpecification) => serviceSpecification.code === code) || null;

const buildFeature = (
  feature: TFeatureDetailsContent,
  serviceSpecifications: TServiceSpecificationContent[],
  /** Force the tag to be NEW (e.g.: when we want to release a feature under a toggle) */
  showAsNew: boolean,
  /** Force the tag to be MAINTENANCE when there's a Service Outage for the feature */
  showAsMaintenance: boolean
) => {
  const featureCode = feature.code as TFeatureCodeEnum;

  let featureTag = featuresTags[featureCode];

  if (showAsNew) {
    featureTag = TagEnum.NEW;
  } else if (showAsMaintenance) {
    featureTag = TagEnum.MAINTENANCE;
  }

  const featureDetails = {
    ...(({ icon, ...rest }) => rest)(feature),
    code: featureCode,
    category: feature.category.slug as TCategoryEnum,
    type: feature.type.slug as TVehicleTypeEnum,
    powertrains: featuresPowertrains[featureCode],
    tag: featureTag,
  } as TFeatureDetails;

  if (featureDetails.variants.length > 0) {
    featureDetails.variants = featureDetails.variants.map((variant) => ({
      ...variant,
      specification: findServiceSpecification(serviceSpecifications, variant.code),
    }));
  } else {
    featureDetails.specification = findServiceSpecification(serviceSpecifications, featureCode);
  }

  return featureDetails;
};

const useFeaturesDetailsQuery = () => {
  const { locale } = useTranslation(true);
  /** We use the toggles for when we need to set a feature tag as NEW temporarily */
  // const { isPending: isLoadingToggles } = useToggles();
  const { data: featuresInMaintenance, isPending: isLoadingFeaturesInMaintenance } = useFeaturesMaintenance();

  return useQuery({
    queryKey: [QueryIdentifierEnum.FEATURES_DETAILS],
    queryFn: async () => {
      const { data } = await fetchFeatureDetails(allFeatureCodes, locale);

      return data.featuresByCodeAndLocale.map((feature) =>
        buildFeature(
          feature,
          data.serviceSpecificationsByCodeAndLocale,
          false,
          featuresInMaintenance.some((f) =>
            [f.code, f.featureGroupCode].includes(feature.code as TFeatureAndVariantCodeEnum)
          )
        )
      );
    },
    gcTime: Infinity,
    enabled: !isLoadingFeaturesInMaintenance /* && !isLoadingToggles*/,
  });
};

export default useFeaturesDetailsQuery;
