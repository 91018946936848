import IframeResizer from 'iframe-resizer-react';
import isEmpty from 'lodash/isEmpty';
import React, { ErrorInfo, ReactNode, useEffect } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { useNavigate, useRouteError } from 'react-router';

import { config } from '@/config';
import { DEFAULT_LOCALE } from '@/configuration/lang/lang';
import { reportErrorToSentry } from '@/configuration/setup/sentry';

import useTranslation from '@/hooks/utils/useTranslation';

import GenericError from '@/components/errors/GenericError';

import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';

import { GENERIC_ERROR_ROUTE } from '@/routes';

/**
 * This ErrorBoundary is used for errors that take place inside any route
 */
export const RouteErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    if (config.serviceEnvironment !== 'production') {
      console.info('RouteErrorBoundary::', { error });
    }

    reportErrorToSentry(error, 'error', 'errorBoundary#routeErrorBoundary');

    navigate(GENERIC_ERROR_ROUTE, { replace: true });
  }, [error, navigate]);

  return null;
};

/**
 * Before any route is rendered, this ErrorBoundary does the error handling
 */
export class ErrorBoundary extends React.Component<{ children?: ReactNode }, { error: boolean }> {
  constructor(props: never) {
    super(props);
    this.state = {
      error: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (config.serviceEnvironment !== 'production') {
      console.info('ErrorBoundary::', { error, errorInfo });
    }

    reportErrorToSentry(error, 'error', 'errorBoundary#componentDidCatch', {
      componentStack: errorInfo.componentStack,
    });

    this.setState({ error: true });
  }

  render() {
    return this.state.error ? <ApplicationError /> : this.props.children;
  }
}

/**
 * We need to display an error message to the user even before we have loaded our normal layout
 * This error can occur for example in the providers that we need to load before the layout
 */
const ApplicationError = () => {
  const { locale, messages } = useTranslation();

  if (!locale || isEmpty(messages)) {
    return null;
  }

  return (
    <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={messages}>
      <ApplicationLayout>
        <ApplicationLayout.Header>
          <ApplicationHeader
            label={<FormattedMessage id="mannow.moduleName" />}
            appNavigator={<IframeResizer className="iFrameResizer" src={config.backend.MENU_SERVICE} />}
            homeRoute={<a href={config.homeRoute} />}
            actionBarItems={[<DefaultUserMenu key="accountMenu" environment={config.serviceEnvironment} />]}
          />
        </ApplicationLayout.Header>
        <ApplicationLayout.Body>
          <GenericError />
        </ApplicationLayout.Body>
      </ApplicationLayout>
    </IntlProvider>
  );
};
