import { config } from '@/config';

import Image from '@/components/Image';

const IMAGE_PATH = `${config.backend.MANNOW_CMS_CONTENT}/assets/dist/whats-new/`;

const HeaderImage = ({ fileName }: { fileName: string }) => (
  <Image width="100%" height="100%" src={`${IMAGE_PATH}${fileName}`} />
);

export default HeaderImage;
