import { useQuery } from '@tanstack/react-query';

import { TServiceOutage } from '@/hooks/types';
import { TServiceOutageResponse } from '@/services/types';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchServiceOutageStatus } from '@/services/userManager';

import { convertSnakeToCamelCase } from '@/helpers/dataTransformation';

const useServiceOutageQuery = () =>
  useQuery({
    queryKey: [QueryIdentifierEnum.SERVICE_OUTAGE],
    queryFn: fetchServiceOutageStatus,
    select: convertSnakeToCamelCase<TServiceOutageResponse, TServiceOutage>,
  });

export default useServiceOutageQuery;
