import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigCatProvider, PollingMode } from 'configcat-react';
import { createRoot } from 'react-dom/client';

import { main } from './configuration';
import { handleLoginRedirect } from './configuration/login/redirect';
import { config, queryClient } from '@/config';

import UserDataProvider from '@/providers/UserDataProvider';
import RouterProvider from '@/routes/RouterProvider';

import { ErrorBoundary } from '@/components/ErrorBoundary';
import ReactQueryDevtools from '@/components/ReactQueryDevtools';

const renderApplication = () => {
  createRoot(document.getElementById('root') as HTMLElement).render(
    <ErrorBoundary>
      {/* Store was added so the old FE can work in the new structure */}
      <ConfigCatProvider
        sdkKey={config.configCatKey}
        options={{ cacheTimeToLiveSeconds: 600 }}
        pollingMode={PollingMode.LazyLoad}
      >
        <UserDataProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider />

            <ReactQueryDevtools />
          </QueryClientProvider>
        </UserDataProvider>
      </ConfigCatProvider>
    </ErrorBoundary>
  );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
  handleLoginRedirect();
} else {
  main(renderApplication);
}
