import { useQuery } from '@tanstack/react-query';

import { TSubscription } from '@/hooks/types';
import { TSubscriptionResponse } from '@/services/types';

import { QueryIdentifierEnum } from '@/enums/QueryIdentifierEnum';

import { fetchSubscriptions } from '@/services/subscriptionManager';

import useVehicleList from '@/hooks/data/useVehicleList';

import { convertSnakeToCamelCase } from '@/helpers/dataTransformation';
import { logAssetMissingWarning } from '@/helpers/logging';

const useSubscriptionsQuery = () => {
  const { list: vehicleList, isPending: isVehiclesLoading } = useVehicleList();

  return useQuery({
    queryKey: [QueryIdentifierEnum.SUBSCRIPTIONS],
    queryFn: fetchSubscriptions,
    enabled: !isVehiclesLoading,
    gcTime: Infinity,
    select: (subscriptions) => {
      let validSubscriptions = subscriptions;

      if (vehicleList?.length > 0) {
        validSubscriptions = subscriptions
          .map((subscription) => {
            const hasMatchedVehicle = vehicleList.some((vehicle) => subscription.asset_id === vehicle.assetId);

            if (hasMatchedVehicle) {
              return subscription;
            }

            logAssetMissingWarning(subscription.asset_id, subscription?.subscription_plan?.feature_code);

            return null;
          })
          .filter((s) => s !== null) as TSubscriptionResponse[];
      }

      return convertSnakeToCamelCase<TSubscriptionResponse[], TSubscription[]>(validSubscriptions);
    },
  });
};

export default useSubscriptionsQuery;
