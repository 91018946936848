import { matchPath, useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { DIALOG_QUERY_PARAM } from '@/features/dialogs/constants';

import { DialogTypeEnum } from '@/enums/DialogTypeEnum';

import {
  AVAILABLE_FEATURES_ROUTE,
  FEATURE_INSTALLABLE_ROUTE,
  FEATURE_INSTALLED_ROUTE,
  INSTALLATION_MONITOR_DETAILS_ROUTE,
  MY_FLEET_ROUTE,
  ORDER_DETAILS_ROUTE,
  VEHICLE_FEATURE_DETAILS_ROUTE,
  VEHICLE_FEATURE_INSTALLABLE_ROUTE,
  VEHICLE_FEATURE_INSTALLED_ROUTE,
  VEHICLE_INSTALLABLE_FEATURES_ROUTE,
  VEHICLE_INSTALLED_FEATURES_ROUTE,
  VEHICLE_MY_CURRENT_MAPS_ROUTE,
} from '@/routes';

const useRouterUtils = (): Record<string, boolean> => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();

  const isMyFleet = location.pathname.includes(MY_FLEET_ROUTE);
  const isAvailableFeatures = location.pathname.includes(AVAILABLE_FEATURES_ROUTE);

  const isAvailableFeaturesEligibleVehicles = !!matchPath({ path: FEATURE_INSTALLABLE_ROUTE }, pathname);
  const isAvailableFeaturesInstalledVehicles = !!matchPath({ path: FEATURE_INSTALLED_ROUTE }, pathname);

  const isMyFleetAvailableFeatures = !!matchPath({ path: VEHICLE_INSTALLABLE_FEATURES_ROUTE }, pathname);
  const isMyFleetInstalledFeatures = !!matchPath({ path: VEHICLE_INSTALLED_FEATURES_ROUTE }, pathname);
  const isMyFleetFeatureDetails = !!matchPath({ path: VEHICLE_FEATURE_DETAILS_ROUTE }, pathname);
  const isMyFleetEligibleVehicles = !!matchPath({ path: VEHICLE_FEATURE_INSTALLABLE_ROUTE }, pathname);
  const isMyFleetInstalledVehicles = !!matchPath({ path: VEHICLE_FEATURE_INSTALLED_ROUTE }, pathname);

  const isInstallationMonitor = !!matchPath({ path: INSTALLATION_MONITOR_DETAILS_ROUTE }, pathname);

  const isVehicleDetails = isMyFleetAvailableFeatures || isMyFleetInstalledFeatures;
  const isVehicleFeatureDetails = isMyFleetFeatureDetails || isMyFleetEligibleVehicles || isMyFleetInstalledVehicles;

  const isOrderDetails = !!matchPath({ path: ORDER_DETAILS_ROUTE }, pathname);
  const isMyCurrentMaps =
    !!matchPath({ path: VEHICLE_MY_CURRENT_MAPS_ROUTE }, pathname) ||
    !!search?.includes(DialogTypeEnum.MY_CURRENT_MAPS);

  const isBulkBookingDialogOpened = searchParams.get(DIALOG_QUERY_PARAM) === DialogTypeEnum.BULK_BOOKING;

  const shouldShowSidebar =
    isVehicleDetails || isVehicleFeatureDetails || isOrderDetails || isMyCurrentMaps || isInstallationMonitor;

  return {
    isVehicleDetails,
    isVehicleFeatureDetails,
    isOrderDetails,
    isMyCurrentMaps,
    isMyFleet, // Will return true if the page is inside the My Fleet routes
    isAvailableFeatures, // Will return true if the page is inside the Available Features routes
    isAvailableFeaturesEligibleVehicles,
    isAvailableFeaturesInstalledVehicles,
    isMyFleetAvailableFeatures,
    isMyFleetInstalledFeatures,
    isMyFleetFeatureDetails,
    isMyFleetEligibleVehicles,
    isMyFleetInstalledVehicles,
    isInstallationMonitor,
    isBulkBookingDialogOpened,
    shouldShowSidebar,
  };
};

export default useRouterUtils;
